import { BaseModel } from "../../../service/models/base";
import { AddressType } from "../../../utils/enums";
import { AttachmentModel } from "../../purchase_order/model/purchase_order_model";
export interface BankDetail {
  ac_no: string;
  ifsc?: string;
  swift_code?: string;
  bank_name?: string;
  branch?: string;
}

export interface AddressModel {
  id: string;
  type: string;
  line1: string;
  line2?: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}
export interface MailDetail {
  contact_name: string;
  contact_mobile: string;
  contact_email: string;
  alternative_mobile?: string;
  alternative_email?: string;
  address: AddressModel;
}
export interface TaxDetail {
  // type: string;
  pan: string;
  reg_type: "Unkown" | "Composition" | "Consumer" | "Regular" | "Unregistered";
  gst_no: string;
}
export const taxRegType = {
  unknown: "Unkown",
  composition: "Composition",
  consumer: "Consumer",
  regular: "Regular",
  unregistered: "Unregistered",
};

export const partyTypes = {
  buyer: "Buyer",
  seller: "Seller",
};

export const currencies = {
  rupee: {
    formal_name: "Rupee",
    short_name: "INR",
    symbol: "\u20b9",
  },
  usd: {
    formal_name: "US Dollar",
    short_name: "USD",
    symbol: "\u0024",
  },
};
export interface Currency {
  formal_name: string;
  short_name: string;
  symbol: string;
}
export interface PartyModel extends BaseModel {
  name: string;
  type: string;
  loc_type: string;
  category: string;
  sub_category: string;
  other_category?: string;
  currency?: Currency;
  remarks?: string;
  mail_detail: MailDetail;
  bank_detail: BankDetail;
  tax_detail: TaxDetail;
  credit_period_day: number;
  attachments: AttachmentModel[];
}

export const addressDefaultValue: AddressModel = {
  id: "",
  type: AddressType.OFFICE,
  line1: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
};

export const default_mail_detail: MailDetail = {
  contact_name: "",
  contact_mobile: "",
  contact_email: "",
  address: addressDefaultValue,
};

const bank_detail: BankDetail = {
  ac_no: "",
  ifsc: "",
  bank_name: "",
  branch: "",
};
const tax_detail: TaxDetail = {
  // type: "",
  pan: "",
  reg_type: "Unkown",
  gst_no: "",
};
export const defaulPartyValue: PartyModel = {
  name: "",
  type: "buyer",
  loc_type: "Domestic",
  mail_detail: default_mail_detail,
  bank_detail: bank_detail,
  tax_detail: tax_detail,
  credit_period_day: 0,
  category: "",
  sub_category: "",
  attachments: [],
};
