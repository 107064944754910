import {
  mdiCheck,
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiChevronUp,
  mdiChevronDown,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState, useEffect } from "react";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../../context_providers/modal/modal_context";
import ModalWidget from "../../../../../context_providers/modal/modal_widget";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import ItemSearchField from "../../../../inventory/items/item_search_field";
import { ItemModel, ItemType } from "../../../../inventory/models/item_model";
import WarehouseSearchField from "../../../../inventory/warehouse/warehouse_search_field";
import {
  purchaseItemDefaultValue,
  PurchaseVoucherItem,
  PurchaseVoucherModel,
} from "../../../models/purchase_model";
import ItemSplitWidget from "./item_split_widget";

interface ItemRowProps {
  max_qty?: number;
  price?: number;
  index: number;
  item?: PurchaseVoucherItem;
  onSubmit: (data: PurchaseVoucherItem) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: PurchaseVoucherItem) => void;
}

const ItemRow: FunctionComponent<ItemRowProps> = (props) => {
  const { showModal, onClose } = useModal();
  const [data, setData] = useState<PurchaseVoucherItem>({
    ...purchaseItemDefaultValue,
  });
  const { showToast } = useToast();
  const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit } = props;

  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!data.item || !data.bill_unit_no || !data.unit_no || !data.price) {
      showToast({
        type: ToastType.warning,
        text: "Please select Item, Qty and price",
      });
      return;
    }
    onSubmit(data);
  };
  const [selectedItem, setSelectedItem] = useState<ItemModel>();
  useEffect(() => {
    setData({ ...(props.item || purchaseItemDefaultValue) });
  }, [props.item]);
  return (
    <>
      {selectedItem && (
        <ModalWidget
          data={{
            title: "Item Allocation",
            id: 1,
            type: ModalType.modal,
            container: (
              <div className="" style={{ width: "760px" }}>
                <ItemSplitWidget
                  price={props.price}
                  max_qty={props.max_qty}
                  item={{
                    ...data,
                    item: selectedItem,
                    item_id: selectedItem.id,
                  }}
                  onSubmit={function (dat: PurchaseVoucherItem): void {
                    setData({ ...dat });
                    onSubmit(dat);
                    setSelectedItem(undefined);
                  }}
                />
              </div>
            ),
          }}
          onClose={function (): void {
            setSelectedItem(undefined);
          }}
        ></ModalWidget>
      )}
      <div
        // id={props.form_id}
        // onSubmit={(e) => {
        //   e.preventDefault();
        //   onDone();
        // }}
        className="    p-1  "
      >
        <div className="grid grid-cols-8 gap-x-1 text-sm px-1">
          <div className="col-span-4">
            <div className="grid grid-cols-12 items-center gap-2">
              <div className="text-right font-semibold">{props.index}</div>
              <div className="col-span-11">
                <ItemSearchField
                  disabled={!editable || props.disabled}
                  value={data?.item}
                  onSelect={(d) => {
                    if (d.type != ItemType.service) setSelectedItem(d);
                    else {
                      setData((o) => ({ ...o, item: d, item_id: d.id }));
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <input
              disabled={data.item?.type !== ItemType.service}
              value={data?.bill_unit_no}
              type="text"
              className="text-right  p-1 focus:outline-none  col-span-3 border rounded"
            />

            <div className="">{data.item?.unit?.symbol}</div>
          </div>
          {/* <div className="grid grid-cols-4 items-center gap-1">
            <input
              disabled={data.item?.type !== ItemType.service}
              value={data?.unit_no}
              type="text"
              className="text-right  p-1 focus:outline-none  col-span-3 border rounded"
            />
            <div className="">{data.item?.unit?.symbol}</div>
          </div> */}

          <input
            disabled={data.item?.type !== ItemType.service}
            value={data?.price}
            type="text"
            className="text-right  p-1 focus:outline-none    border rounded"
          />

          <input
            disabled
            value={(data?.bill_unit_no ?? 0) * (data?.price ?? 0)}
            type="text"
            className="text-right  p-1 focus:outline-none   border rounded"
          />

          <div className="flex gap-1 items-end justify-end">
            {props.item ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-1 items-end">
                    {editable ? (
                      <>
                        <button
                          onClick={() => {
                            if (data.item?.type != ItemType.service)
                              setSelectedItem(data.item);
                          }}
                          className="bg-blue-100 p-1 rounded text-blue-800   text-xs font-semibold"
                        >
                          Split
                        </button>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setData(props.item!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(data);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                onClick={onDone}
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.item && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>
        {/* {showMore && props.item && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.item.id}</p>
              <p>Extra : {rupeeFormat(props.item.addition_amt ?? 0)}</p>
              <p>Discount : {rupeeFormat(props.item.discount_amt ?? 0)}</p>
              <p>Paid : {rupeeFormat(props.item.paid ?? 0)}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.created_at
                      ? moment(props.item.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.updated_at
                      ? moment(props.item.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default ItemRow;
