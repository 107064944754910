import { FunctionComponent, useState } from "react";
import { OrderDevice } from "./order_device_row";
import moment from "moment";
import { InventoryStatus } from "../../../../../utils/enums";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import InventoryDropDown from "../../../new_ops/components/inventory_dropdown";
import InventorySearchField from "../../../new_ops/components/device_search_field";
import { OpsTaskDeviceIssueType } from "../../../../../utils/constant";

interface DeviceUpdateWidgetsProps {
  data: OrderDevice;
  onSubmit: (data: {
    device: OrderDevice;
    replaceDevice?: OrderDevice;
  }) => void;
  onCancel: () => void;
}

const DeviceUpdateWidgets: FunctionComponent<DeviceUpdateWidgetsProps> = (
  props
) => {
  const [edit, setEdit] = useState(false);
  const { showToast } = useToast();
  const [data, setData] = useState<OrderDevice>({ ...props.data });
  const [replaceDevice, setReplaceDevice] = useState<OrderDevice>({
    ...props.data,
    device_id: "",
    product_name: "",
    status: InventoryStatus.delivered,
    delivery_date: null,
    installation_date: null,
    return_back_date: null,
  });

  const [replace, setReplace] = useState(false);
  const [showReplace, setShowReplace] = useState(false);

  const onSubmit = async () => {
    if (data.status === InventoryStatus.delivered && !data.delivery_date)
      return showToast({
        type: ToastType.error,
        text: "Delivery date required ",
      });
    if (data.status === InventoryStatus.installed && !data.installation_date)
      return showToast({
        type: ToastType.error,
        text: "Inallation date required ",
      });
    if (
      data.status === InventoryStatus.returnedNStocked &&
      !data.return_back_date
    )
      return showToast({
        type: ToastType.error,
        text: "Returned date required ",
      });
    if (
      data.status === InventoryStatus.returnedNStocked &&
      !data.issue_category
    )
      return showToast({
        type: ToastType.error,
        text: "Issue Type required ",
      });

    if (
      data.status === InventoryStatus.returnedNStocked &&
      replace &&
      !showReplace
    ) {
      setShowReplace(true);
      return;
    }

    if (data.status === InventoryStatus.returnedNStocked && showReplace) {
      if (!replaceDevice.device_id)
        return showToast({
          type: ToastType.error,
          text: "Replacement device id required ",
        });
      if (
        replaceDevice.status === InventoryStatus.delivered &&
        !replaceDevice.delivery_date
      )
        return showToast({
          type: ToastType.error,
          text: "Delivery date required ",
        });
      if (
        replaceDevice.status === InventoryStatus.installed &&
        !replaceDevice.installation_date
      )
        return showToast({
          type: ToastType.error,
          text: "Inallation date required ",
        });
    }

    // submit

    props.onSubmit({
      device: data,
      replaceDevice:
        data.status === InventoryStatus.returnedNStocked && replace
          ? replaceDevice
          : undefined,
    });
  };

  return (
    <div className="p-5 text-sm">
      {!showReplace ? (
        <div className="  flex flex-col gap-5">
          <div className="grid grid-cols-2">
            <div className="">Device Id</div>
            <div className="col-span-1">{data.device_id}</div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Product</div>
            <div className="col-span-1">{data.product_name}</div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Org namespace</div>
            <div className="col-span-1">{data.org_namespace ?? "---"}</div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Delivery Date</div>
            <div className="col-span-1">
              {data.delivery_date
                ? moment(data.delivery_date).format("DD-MM-YYYY")
                : "---"}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Installation Date</div>
            <div className="col-span-1">
              {data.installation_date
                ? moment(data.installation_date).format("DD-MM-YYYY")
                : "---"}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Returned Date</div>
            <div className="col-span-1">
              {data.return_back_date
                ? moment(data.return_back_date).format("DD-MM-YYYY")
                : "---"}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Replaced By Device</div>
            <div className="col-span-1">{data.replaced_by ?? "---"}</div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Replaced To Device</div>
            <div className="col-span-1">{data.replaced_to ?? "---"}</div>
          </div>
          <div className="grid grid-cols-2 items-center">
            <div className="">status*</div>
            <div className="col-span-1">
              <select
                disabled={!edit}
                name=""
                id=""
                className="p-1 rounded   focus:outline-none border w-full"
                value={data.status}
                onChange={(e) => {
                  setData((o) => ({ ...o, status: e.target.value }));
                }}
              >
                <option value="" hidden>
                  select
                </option>
                {[
                  InventoryStatus.delivered,
                  InventoryStatus.installed,
                  InventoryStatus.returnedNStocked,
                ].map((s, i) => (
                  <option value={s} key={i}>
                    {s}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {data.status === InventoryStatus.delivered ? (
            <div className="grid grid-cols-2 items-center">
              <div className="delevery_date">Delivery date*</div>
              <div className="col-span-1">
                <input
                  disabled={!edit}
                  value={
                    data.delivery_date
                      ? moment(data.delivery_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    if (!e.target.value) {
                      setData((o) => ({ ...o, delivery_date: null }));
                      return;
                    }
                    const date = UTCToLocalDate(e.target.value);
                    date?.setHours(0, 0, 0, 0);
                    setData((o) => ({ ...o, delivery_date: date! }));
                  }}
                  type="date"
                  name=""
                  id="delevery_date"
                  className="p-1 rounded   focus:outline-none border  w-full"
                />
              </div>
            </div>
          ) : data.status === InventoryStatus.installed ? (
            <div className="grid grid-cols-2 items-center">
              <div className="installation_date">Installation date*</div>
              <div className="col-span-1">
                <input
                  disabled={!edit}
                  value={
                    data.installation_date
                      ? moment(data.installation_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    if (!e.target.value) {
                      setData((o) => ({ ...o, installation_date: null }));
                      return;
                    }
                    const date = UTCToLocalDate(e.target.value);
                    date?.setHours(0, 0, 0, 0);
                    setData((o) => ({ ...o, installation_date: date! }));
                  }}
                  type="date"
                  name=""
                  id="installation_date"
                  className="p-1 rounded   focus:outline-none border  w-full"
                />
              </div>
            </div>
          ) : data.status === InventoryStatus.returnedNStocked ? (
            <>
              <div className="grid grid-cols-2 items-center">
                <div className="returned_date">Return date*</div>
                <div className="col-span-1">
                  <input
                    disabled={!edit}
                    value={
                      data.return_back_date
                        ? moment(data.return_back_date).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      if (!e.target.value) {
                        setData((o) => ({ ...o, return_back_date: null }));
                        return;
                      }
                      const date = UTCToLocalDate(e.target.value);
                      date?.setHours(0, 0, 0, 0);
                      setData((o) => ({ ...o, return_back_date: date! }));
                    }}
                    type="date"
                    name=""
                    id="returned_date"
                    className="p-1 rounded   focus:outline-none border  w-full"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 items-center">
                <div className=" ">
                  <p>Issue Type*</p>
                </div>
                <div className="col-span-1  ">
                  <select
                    disabled={!edit}
                    value={data?.issue_category}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        issue_category: e.target.value,
                      }));
                    }}
                    name=""
                    id=""
                    className="p-1 rounded   focus:outline-none border  w-full"
                  >
                    {" "}
                    <option value="" hidden>
                      Select
                    </option>
                    {[
                      ...Object.values(OpsTaskDeviceIssueType),
                      "RE-ALLOCATION",
                      "MANUAL ERROR",
                    ].map((f) => (
                      <option value={f} key={f}>
                        {f}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-2 items-center">
                <label htmlFor="replace" className="">
                  Replace this device
                </label>
                <div className="col-span-1">
                  <input
                    disabled={!edit}
                    type="checkbox"
                    name="replace"
                    id="replace"
                    checked={replace}
                    onChange={(e) => {
                      setReplace(e.target.checked);
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="  flex flex-col gap-5">
          <div className="grid grid-cols-2">
            <div className="">Device Id</div>
            <div className="col-span-1">
              <InventorySearchField
                status={[
                  InventoryStatus.stocked,
                  InventoryStatus.returnedNStocked,
                ]}
                disabled={!edit}
                value={{
                  device_id: replaceDevice.device_id,
                  name: replaceDevice.product_name,
                }}
                onSelect={(v) => {
                  setReplaceDevice((o) => ({
                    ...o,
                    device_id: v.device_id,
                    product_name: v.device.product_name,
                  }));
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Product</div>
            <div className="col-span-1">{replaceDevice.product_name}</div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Org namespace</div>
            <div className="col-span-1">
              {replaceDevice.org_namespace ?? "---"}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Delivery Date</div>
            <div className="col-span-1">
              {replaceDevice.delivery_date
                ? moment(replaceDevice.delivery_date).format("DD-MM-YYYY")
                : "---"}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Installation Date</div>
            <div className="col-span-1">
              {replaceDevice.installation_date
                ? moment(replaceDevice.installation_date).format("DD-MM-YYYY")
                : "---"}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Returned Date</div>
            <div className="col-span-1">
              {replaceDevice.return_back_date
                ? moment(replaceDevice.return_back_date).format("DD-MM-YYYY")
                : "---"}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Replaced By Device</div>
            <div className="col-span-1">
              {replaceDevice.replaced_by ?? "---"}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="">Replaced To Device</div>
            <div className="col-span-1">
              {replaceDevice.replaced_to ?? "---"}
            </div>
          </div>
          <div className="grid grid-cols-2 items-center">
            <div className="">status</div>
            <div className="col-span-1">
              <select
                disabled={!edit}
                name=""
                id=""
                className="p-1 rounded   focus:outline-none border"
                value={replaceDevice.status}
                onChange={(e) => {
                  setReplaceDevice((o) => ({ ...o, status: e.target.value }));
                }}
              >
                <option value="" hidden>
                  select
                </option>
                {[InventoryStatus.delivered, InventoryStatus.installed].map(
                  (s, i) => (
                    <option value={s} key={i}>
                      {s}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          {replaceDevice.status === InventoryStatus.delivered ? (
            <div className="grid grid-cols-2 items-center">
              <div className="delevery_date">Delivery date</div>
              <div className="col-span-1">
                <input
                  disabled={!edit}
                  value={
                    replaceDevice.delivery_date
                      ? moment(replaceDevice.delivery_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    if (!e.target.value) {
                      setReplaceDevice((o) => ({
                        ...o,
                        delivery_date: null,
                      }));
                      return;
                    }
                    const date = UTCToLocalDate(e.target.value);
                    date?.setHours(0, 0, 0, 0);
                    setReplaceDevice((o) => ({ ...o, delivery_date: date! }));
                  }}
                  type="date"
                  name=""
                  id="delevery_date"
                  className="p-1 rounded   focus:outline-none border"
                />
              </div>
            </div>
          ) : replaceDevice.status === InventoryStatus.installed ? (
            <div className="grid grid-cols-2 items-center">
              <div className="installation_date">Installation date</div>
              <div className="col-span-1">
                <input
                  disabled={!edit}
                  value={
                    replaceDevice.installation_date
                      ? moment(replaceDevice.installation_date).format(
                          "YYYY-MM-DD"
                        )
                      : ""
                  }
                  onChange={(e) => {
                    if (!e.target.value) {
                      setReplaceDevice((o) => ({
                        ...o,
                        installation_date: null,
                      }));
                      return;
                    }
                    const date = UTCToLocalDate(e.target.value);
                    date?.setHours(0, 0, 0, 0);
                    setReplaceDevice((o) => ({
                      ...o,
                      installation_date: date!,
                    }));
                  }}
                  type="date"
                  name=""
                  id="installation_date"
                  className="p-1 rounded   focus:outline-none border"
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}

      {/* <div className="flex mt-5 justify-center gap-5">
        {showReplace && (
          <button
            title="Back"
            onClick={() => {
              setShowReplace(false);
            }}
            className="rounded-full bg-myPrimaryColor text-white p-2 hover:scale-110 transform"
          >
            <Icon path={mdiArrowLeft} size={0.8}></Icon>
          </button>
        )}
        <button
          title="Submit"
          onClick={() => onSubmit()}
          className="rounded-full bg-myPrimaryColor text-white p-2 hover:scale-110 transform"
        >
          <Icon path={mdiArrowRight} size={0.8}></Icon>
        </button>
      </div> */}

      {edit ? (
        <div className="grid grid-cols-2 gap-5 my-2">
          <button
            onClick={() => {
              if (showReplace) {
                setShowReplace(false);
              } else {
                setEdit(false);
              }
            }}
            className="bg-blue-50 text-blue-500 border-blue-300 border rounded-lg px-3 py-1 text-center"
          >
            {showReplace ? "Back" : "Cancel"}
          </button>

          <button
            onClick={onSubmit}
            className="bg-green-50 text-green-500 border-green-300 border rounded-lg px-3 py-1 text-center"
          >
            {data.status === InventoryStatus.returnedNStocked &&
            replace &&
            !showReplace
              ? "Next"
              : "Submit"}
          </button>
        </div>
      ) : (
        <button
          onClick={() => setEdit(true)}
          className="bg-blue-50 text-blue-500 border-blue-300 border rounded-lg px-3 py-1 my-2 text-center w-full"
        >
          Edit
        </button>
      )}
    </div>
  );
};

export default DeviceUpdateWidgets;
