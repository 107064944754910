import { FunctionComponent, useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import { NavLink, useHistory } from "react-router-dom";
import {
  OrderByItemModel,
  OrderModel,
} from "../../../../service/models/orders/order_model";

import {
  OrderTypeLabel,
  OrderType,
  OrderStatusLabel,
  ACTION,
  MODULE,
  OrderStatus,
  PaymentStatus,
} from "../../../../utils/enums";

import LoadingWidget from "../../../components/loading_spinner";
import ModalDialog from "../../../new_components/common/modal";
import FilterWidget from "./components/filter_widget";
import Pagination from "../../../new_components/common/pagination";
import OrderTableWidget from "./components/table";
import OrderComments from "./components/order_comments";
import OrderOverView from "./components/order_overview";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../new_components/common/drawer_modal";
import StadiumButton from "../../../new_components/common/stadium_button";
import {
  GetOrdersApi,
  UpdateOrderDetailApi,
} from "../../../../service/repos/order_repo";
import { loadavg } from "os";
import { formToObject } from "../../../../utils/formDataToJson";
import {
  GetExecutivesApi,
  GetProductsApi,
  GetStatesApi,
} from "../../../../service/repos/products_repo";
import ExportWidget from "./components/export_widget";
import CashFlowChart from "./components/cash_flow_chart";
import {
  GetOrdersAnalytics,
  GetTransactionAnalytics,
} from "../../../../service/repos/analytics/order_analytics";

import ProductModel from "../../../../service/models/product_model";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

import Icon from "@mdi/react";
import {
  mdiChevronLeft,
  mdiFileDownloadOutline,
  mdiFilterOutline,
  mdiFilterRemoveOutline,
  mdiMenuDown,
  mdiMenuUp,
  mdiPlus,
  mdiReload,
  mdiShoppingOutline,
} from "@mdi/js";
import SearchFieldWidget from "../../../new_components/search_field";
import FilterDropDown from "../../../new_components/common/filter_drop_down";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import ActionHistory from "./components/history_logs";
import { useModal } from "../../../../context_providers/modal/modal_context";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { useAuth } from "../../../../context_providers/auth/authProvider";
interface OrderListFilter {
  page?: number;
  count?: number;
  search?: string;
  fromDate?: string;
  toDate?: string;
  state?: string[];
  orderStatus?: string[];
  paymentStatus?: string[];
  saleExecutive?: string[];
  type?: string[];
  items?: string[];
  site_survey?: string[];
  wiring_status?: string[];
  subsidy_status?: string[];
}
interface OrderListPageProps {}
const initialFilter: OrderListFilter = {
  page: 1,
  count: 100,
  orderStatus: [
    OrderStatus.CONFIRMED,
    OrderStatus.DELIVERED,
    OrderStatus.DISPATCHED,
    OrderStatus.INSTALLED,
    OrderStatus.PARTIAL_DELIVERED,
    OrderStatus.WANT_TO_CANCEL,
    OrderStatus.config_pending,
    OrderStatus.hold,
    OrderStatus.write_off,
  ],
};
const OrderListPage: FunctionComponent<OrderListPageProps> = () => {
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();
  const [loading, setLoading] = useState(false);
  const tempFilter = localStorage.getItem("filter");
  const tempExe = localStorage.getItem("exe");

  const [filter, setFilter] = useState<OrderListFilter>(
    tempFilter ? JSON.parse(tempFilter) : initialFilter
  );

  // const [page, setPage] = useState(1);
  // const [count, setCount] = useState(100);
  const [total, setTotal] = useState(0);
  const [orderBy, setOrderBy] = useState("order_date");
  const [sortOrder, setSortOrder] = useState("desc");
  // const [search, setSearch] = useState(tempFilter ? filter.search : "");
  const [timeStamp, setTimeStamp] = useState("");
  const [orders, setOrders] = useState<OrderByItemModel[]>([]);
  // const { setLoading } = useModal();
  // const orderState = useAppSelector((state) => state.OrderReducer);
  // const dispatch = useAppDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [ShowComment, setShowComment] = useState<{ [key: string]: any }>();
  const [ShowHistory, setShowHistory] = useState<{ [key: string]: any }>();
  const [selectedOrder, setSelectedOrder] = useState<OrderByItemModel>();
  const [filterCollaped, setFilterCollapsed] = useState(true);
  const [states, setState] = useState<
    { state_code: string; name: string; id: number }[]
  >([]);
  const [executives, setExecutives] = useState<{ name: string; uid: string }[]>(
    [...(tempExe ? JSON.parse(tempExe) : [])]
  );
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [showExport, setShowExport] = useState(false);
  const [analytics, setAnalytics] = useState<{
    total_orders: number;
    total: number;
    subtotal: number;
    discount: number;
    addition: number;
    paid: number;
    qty: number;
    delivered_qty: number;
    installed_qty: number;
    returned_qty: number;
    net_qty: number;
  }>();

  const getStates = async () => {
    const res = await GetStatesApi();
    if (res)
      setState(
        res.data.map((f) => {
          f.name = f.name.toUpperCase();
          return f;
        })
      );
  };
  // const getExecutives = async () => {
  //   const res = await GetExecutivesApi("Sales");
  //   if (res) setExecutives(res.data ?? []);
  // };

  const getproducts = async () => {
    const res = await GetProductsApi();
    if (res) setProducts(res.data ?? []);
  };

  const getOrders = async () => {
    setLoading(true);
    if (filter.fromDate) {
      filter.fromDate = UTCToLocalDate(filter.fromDate) as any;
    }
    if (filter.toDate) {
      filter.toDate = UTCToLocalDate(filter.toDate) as any;
    }
    const res = await GetOrdersApi({
      ...filter,
      order_by: orderBy,
      sort_order: sortOrder,
    });

    if (res.success) {
      setTimeStamp(res.timeStamp);
      setOrders(res.data.orders);
      if (res.data.metadata) setTotal(res.data.metadata.total);

      setAnalytics(res.analytics);
    } else {
      showToast({
        type: ToastType.error,
        text: res.error,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    localStorage.setItem("filter", JSON.stringify(filter));
    localStorage.setItem("exe", JSON.stringify(executives));

    getOrders();
    // if (executives.length === 0) getExecutives();
    if (states.length === 0) getStates();
    if (products.length === 0) getproducts();
  }, [filter, orderBy, sortOrder]);

  const onOrderDetailChanged = async (data: { [key: string]: any }) => {
    setLoading(true);
    const res = await UpdateOrderDetailApi({
      ...data,
      order_id: ShowComment!.order_id,
    });
    if (res.success) {
      setOrders((o) => {
        return o.map((ord) => {
          if (ord.order_id === ShowComment!.order_id) {
            return { ...ord, ...data };
          }
          return ord;
        });
      });
    } else showToast({ type: ToastType.error, text: res.error ?? "" });
    setLoading(false);
  };

  const [exportDisabled, setExportDisabled] = useState(true);
  useEffect(() => {
    setExportDisabled(
      !isAuthorised({
        module: MODULE.export_data,
        action: [ACTION.READ],
      })
    );
  }, []);

  return (
    <>
      <div
        className={`h-max px-3 pt-5 relative md:h-screen h-89  ${
          selectedOrder
            ? "overflow-hidden"
            : "overflow-y-auto overflow-x-hidden"
        }`}
      >
        <div className="flex flex-wrap justify-between  items-end">
          <div className="">
            <h1 className="font-bold text-2xl text-gray-500 ">
              Orders{" "}
              <span className="text-sm">({analytics?.total_orders})</span>
            </h1>
            <div className="flex gap-2">
              <p className="text-xs text-gray-400">
                Fetched at :{moment(timeStamp).format("hh:mm:ss A")}
              </p>
              <span
                className="transform hover:scale-125 duration-300  cursor-pointer text-gray-400 hover:text-gray-800 "
                onClick={() => {
                  getOrders();
                }}
              >
                <Tooltip title="Refresh">
                  <div className="flex  items-center ">
                    <Icon path={mdiReload} className="w-4 h-4"></Icon>
                  </div>
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="flex gap-2 items-center flex-wrap">
            <SearchFieldWidget
              placeholder="Search order id, name, mobile, email"
              defaulValue={filter.search ?? ""}
              onClear={() => {
                setFilter({ ...filter, search: "" });
                // setSearch("");
              }}
              onSubmit={(search) => {
                if (search) {
                  setFilter({ ...filter, page: 1, search });
                }
              }}
            />
            <div className="flex flex-wrap items-center gap-2">
              {!exportDisabled && (
                <div
                  onClick={() => setShowExport(true)}
                  className=" p-2 duration-300 border   rounded cursor-pointer  hover:bg-gray-800 hover:text-white"
                >
                  <Tooltip title="Download to excel">
                    <div className="flex items-center gap-1 ">
                      <Icon
                        path={mdiFileDownloadOutline}
                        className="w-4 h-4"
                      ></Icon>
                      <p className="text-xs font-semibold">Export</p>
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>

            <div className="flex gap-2">
              <h6 className="font-normal">Filters</h6>
              <div
                onClick={() => setFilterCollapsed(!filterCollaped)}
                className="flex   items-center justify-center bg-gray-200 p-1 rounded-full hover:bg-gray-800 hover:text-white"
              >
                <Icon
                  path={filterCollaped ? mdiMenuDown : mdiMenuUp}
                  className="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </div>

        {!filterCollaped && (
          <div className="rounded p-1 grid grid-cols-1 md:grid-cols-9  gap-3    mt-2  ">
            <div className="col-span-2">
              <FilterDropDown
                placeholder="Order Type"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    type: undefined,
                  })
                }
                onDelete={(type) => {
                  setFilter({
                    ...filter,
                    type: filter.type?.filter((val) => val !== type),
                  });
                }}
                onSelect={(type) => {
                  setFilter({
                    ...filter,
                    type: [...(filter.type ?? []), type],
                  });
                }}
                selectedOptions={filter.type}
                options={Object.values(OrderType).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
            <div className="col-span-2">
              <FilterDropDown
                placeholder="Products"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    items: undefined,
                  })
                }
                onDelete={(item) => {
                  setFilter({
                    ...filter,
                    items: filter.items?.filter((val) => val !== item),
                  });
                }}
                onSelect={(item) => {
                  setFilter({
                    ...filter,
                    items: [...(filter.items ?? []), item],
                  });
                }}
                selectedOptions={filter.items}
                options={products.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
              />
            </div>{" "}
            <div className="col-span-2">
              <FilterDropDown
                placeholder="Order status"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    orderStatus: undefined,
                  })
                }
                onDelete={(status) => {
                  setFilter({
                    ...filter,
                    orderStatus: filter.orderStatus?.filter(
                      (val) => val !== status
                    ),
                  });
                }}
                onSelect={(status) => {
                  setFilter({
                    ...filter,
                    orderStatus: [...(filter.orderStatus ?? []), status],
                  });
                }}
                selectedOptions={filter.orderStatus}
                options={Object.values(OrderStatus).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
            <div className="col-span-2">
              <FilterDropDown
                placeholder="Payment status"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    paymentStatus: undefined,
                  })
                }
                onDelete={(status) => {
                  setFilter({
                    ...filter,
                    paymentStatus: filter.paymentStatus?.filter(
                      (val) => val !== status
                    ),
                  });
                }}
                onSelect={(status) => {
                  setFilter({
                    ...filter,
                    paymentStatus: [...(filter.paymentStatus ?? []), status],
                  });
                }}
                selectedOptions={filter.paymentStatus}
                options={Object.values(PaymentStatus).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
            <div className="flex flex-col   gap-2">
              <div
                className={`flex items-center justify-center p-2 duration-300 border hover:bg-gray-800 hover:text-white rounded cursor-pointer `}
                onClick={() => setFilter({ ...initialFilter })}
              >
                <Tooltip title="Reset filter">
                  <div className="flex gap-1 items-center justify-center ">
                    <Icon path={mdiFilterRemoveOutline} className="w-4 h-4" />
                    <p className="text-xs font-semibold">Reset</p>
                  </div>
                </Tooltip>
              </div>
              <div
                onClick={() => setShowFilter(true)}
                className="flex items-center p-2 justify-center  duration-300 border hover:bg-gray-800 hover:text-white rounded cursor-pointer  "
              >
                <Tooltip title="More filter">
                  <div className="flex gap-1 items-center justify-center">
                    <Icon path={mdiFilterOutline} className="w-4 h-4" />
                    <p className="text-xs font-semibold">More</p>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        )}

        {orders.length > 0 ? (
          <>
            {/* <div className="mt-3 mb-2">
              <Pagination
                itemCount={orders.length}
                page={page}
                total={total}
                count={count}
                onChange={(p) => {
                  setPage(p);
                  // dispatch(GoToPageOrderThunk({ page: p, count }));
                }}
              />
            </div> */}
            <OrderTableWidget
              // onFilterSelect={(f) => {
              //   setFilter(f);
              // }}
              analytics={analytics}
              onSort={(order_by) => {
                if (orderBy !== order_by) {
                  setSortOrder("asc");
                  setOrderBy(order_by);
                } else {
                  if (sortOrder === "asc") setSortOrder("desc");
                  else setSortOrder("asc");
                }
              }}
              onOrderClick={(order) => {
                setSelectedOrder(order);
              }}
              onShowHistory={(order) => setShowHistory(order)}
              onShowComment={(order) => setShowComment(order)}
              orders={orders}
              order_by={orderBy}
              sort_order={sortOrder}
            />
            <div className="mt-2">
              <Pagination
                itemCount={orders.length}
                page={filter.page ?? 1}
                total={total}
                count={filter.count ?? 100}
                onChange={(page) => {
                  setFilter((old) => ({ ...old, page }));
                  // dispatch(GoToPageOrderThunk({ page: p, count }));
                }}
              />
            </div>
          </>
        ) : !loading ? (
          <div className="w-full flex justify-center items-center pl-4 ">
            <div className="mt-12 xl:mt-44">
              <div className="text-xl text-gray-500 ">No Orders found !</div>
              <div className="mt-4">
                <img src="/images/nodata.png" alt="" className="w-48 h-48" />
              </div>
            </div>
          </div>
        ) : (
          // <p className="text-2xl text-gray-500">No Orders found!</p>
          <></>
        )}
        {/* <div className="flex mt-10 items-center gap-2">
          <p className="text-gray-500 text-xs md:text-sm ">Powered by </p>
          <div className=" w-auto ">
            <img
              src="/assets/logo.png"
              alt="kazam image"
              className="h-8 md:h-10"
            />
          </div>
        </div> */}
        {/* <LoadingWidget loading={loading} /> */}
      </div>

      {ShowHistory && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={ShowHistory !== undefined}
          onClose={() => setShowHistory(undefined)}
          title="History"
        >
          <ActionHistory
            order_id={ShowHistory.order_id}
            // show={ShowHistory !== undefined}
            // onClose={(v) => setShowHistory(undefined)}
          />
        </DrawerModal>
      )}
      {showExport && (
        <ModalDialog
          show={showExport}
          onClose={() => setShowExport(false)}
          title="Export Data"
        >
          <ExportWidget filter={filter} />
        </ModalDialog>
      )}
      {ShowComment && (
        <DrawerModal
          onClose={() => setShowComment(undefined)}
          title=""
          show={ShowComment !== undefined}
          location={DrawerModalLoaction.right}
        >
          <OrderComments
            onRemarkChange={onOrderDetailChanged}
            order={{
              order_id: ShowComment.order_id,
              hold_remark: ShowComment.hold_remark,
              registration_remark: ShowComment.registration_remark,
              remark: ShowComment.remark,
              subsidy_status: ShowComment.subsidy_status,
            }}
            // onClose={(v) => setShowComment(undefined)}
            // show={ShowComment !== undefined}
          />
        </DrawerModal>
      )}

      {showFilter && (
        <DrawerModal
          onClose={() => setShowFilter(false)}
          title="Filter"
          show={showFilter}
          location={DrawerModalLoaction.right}
        >
          <FilterWidget
            products={products}
            filter={filter}
            onReset={() => setFilter(initialFilter)}
            onSubmit={(data, emps) => {
              setFilter(data);
              setShowFilter(false);
              setExecutives([...emps]);
            }}
            executives={executives}
            states={states}
            onClose={() => setShowFilter(false)}
          />
        </DrawerModal>
      )}
      {/* <div
        className={`absolute    right-0 overflow-auto top-0    w-full bg-white z-50 duration-500 ease-in-out  ${
          selectedOrder ? "left-0 h-full" : "left-full  detail-container "
        }`}
      >
      
        <OrderDetailPage
          order_id={selectedOrder?.order_id!}
          onBack={() => setSelectedOrder(undefined)}
        />
      
      </div> */}
      {loading && <LoadingWidget loading={loading} />}
    </>
  );
};

export default OrderListPage;
