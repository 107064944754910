import { mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import debounce from "lodash.debounce";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import { useModal } from "../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../procurement/components/create_view/form_field";
import TableView from "../../../procurement/components/table_comp";
import { fetchGet, toQueryString } from "../../../service/api_client";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../utils/date_util";
import { currencyFormat } from "../../../utils/orders_utils";
import { urls } from "../../../utils/urls";
import {
  ItemMovementModel,
  StockItemModel,
  StockMovementType,
} from "../models/inventory_voucher";
import { ItemModel } from "../models/item_model";
import { isArray } from "lodash";
import { voucherType } from "../../accounts/models/common_model";

interface StockItemMovementScreenProps {}

const StockItemMovementScreen: FunctionComponent<
  StockItemMovementScreenProps
> = (props) => {
  const { id } = useParams<{ id: string }>();
  const navaigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/stocks`;
  const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [item, setItem] = useState<ItemModel>();
  const [closingStock, setClosingStock] = useState<{
    _id: string;
    item_id: string;
    created_at: string;
    warehouse_id: string;
    total_inward_qty: number;
    total_inward_value: number;
    total_outward_qty: number;
    total_outward_value: number;
    closing_qty: number;
  }>();
  const [openingStock, setOpeningStock] = useState<{
    unit_no: number;
    price: number;
  }>();
  const [CurrentStock, setCurrentStock] = useState<{
    _id: string;
    inward_qty: number;
    inward_value: number;
    outward_qty: number;
    outward_value: number;
  }>();
  const [data, setData] = useState<ItemMovementModel[]>([]);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  // useEffect(() => {
  //   const now = new Date();
  //   let from = new Date(new Date(now).setHours(0, 0, 0, 0));
  //   from = new Date(from.setDate(1));
  //   let to = new Date(new Date(now).setHours(23, 59, 59, 999));
  //   to = new Date(to.setMonth(now.getMonth() + 1));
  //   to = new Date(to.setDate(0));
  //   setFromDate(from);
  //   setToDate(to);
  // }, []);
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
    fromDate?: Date;
    toDate?: Date;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + `/${id}` + toQueryString(prop));
      if (res.success) {
        setItem(res.data.item);
        setCurrentStock(res.data.current_stock);
        setClosingStock(res.data.closing_stock);
        setOpeningStock(res.data.opening_stock);
        setData(res.data.data.data);
        if (res.data.data.metadata) {
          setTotal(res.data.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };
  const debouncedHandler = useCallback(
    debounce(
      (prop: {
        search?: string;
        page: number;
        count: number;
        all?: boolean;
        fromDate?: Date;
        toDate?: Date;
      }) => {
        if (prop) fetchData(prop);
        else setData([]);
      },
      100
    ),
    []
  );
  useEffect(() => {
    if (id) debouncedHandler({ page, count, search, fromDate, toDate });
  }, [page, search, fromDate, toDate]);
  // useEffect(() => {
  //   if (search) fetchData({ page, count, search });
  // }, [search]);
  // return <LedgerCreateScreen />;

  const onSelect = (d: ItemMovementModel) => {
    let path = "";
    switch (d.voucher_type) {
      case voucherType.purchase:
        path = urls.purchase_voucher;
        break;
      case voucherType.sales:
        path = urls.sales_voucher;
        break;
      case voucherType.debit_note:
        path = urls.debit_note;
        break;
      case voucherType.credit_note:
        path = urls.credit_note;
        break;
      case voucherType.manufacturing:
        path = urls.stock_manufacture_voucher;
        break;
      case voucherType.stock_journal:
        path = urls.stock_transfer_voucher;
        break;

      default:
        break;
    }
    navaigation.push(path + "/update/" + d.voucher_id);
  };
  const [closingPrice, setClosingPrice] = useState(0);
  // const [curPrice, setCurPrice] = useState(0);
  // const [curInwardQty, setCurInwardQty] = useState(0);
  // const [curOutwardQty, setCurOutwardQty] = useState(0);
  // const [curInwardValue, setCurInwardValue] = useState(0);
  // const [curOutwardValue, setCurOutwardValue] = useState(0);
  useEffect(() => {
    if (closingStock) {
      let t =
        !closingStock || closingStock?.total_inward_qty <= 0
          ? 0
          : Number(
              (
                (closingStock!.total_inward_value * closingStock!.closing_qty) /
                closingStock!.total_inward_qty
              ).toFixed(2)
            );
      t = t / closingStock.closing_qty;
      setClosingPrice(t);
    }
  }, [closingStock]);
  // useEffect(() => {
  //   if (data) {
  //     let tPrice = 0;
  //     let tInQty = 0;
  //     let tOutQty = 0;
  //     let tInVal = 0;
  //     let tOutVal = 0;
  //     for (let index = 0; index < data.length; index++) {
  //       const ele = data[index];
  //       if (ele.movement_type === StockMovementType.inward) {
  //         tInQty += ele.unit_no;
  //         tInVal += ele.unit_no * ele.price;
  //       } else {
  //         tOutQty += ele.unit_no;
  //         tOutVal += ele.unit_no * ele.price;
  //       }
  //     }
  //     tPrice =
  //       tInQty <= 0
  //         ? 0
  //         : Number(((tInVal * (tInQty - tOutQty)) / tInQty).toFixed(2));
  //     tPrice = tPrice / (tInQty - tOutQty);
  //     setCurPrice(tPrice);
  //     setCurInwardQty(tInQty);
  //     setCurOutwardQty(tOutQty);
  //     setCurInwardValue(tInVal);
  //     setCurOutwardValue(tOutVal);
  //   }
  // }, [data]);
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            <p className="border-l-4 border-myPrimaryColor pl-2">
              {" "}
              {data[0] ? data[0].item?.name : " Stock Items "}
            </p>
            <button
              title="Refersh"
              onClick={() =>
                debouncedHandler({ page, count, search, fromDate, toDate })
              }
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            {" "}
            <div className="text-sm flex items-center gap-2">
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  name=""
                  id="fromDate"
                  className="p-1 rounded "
                  value={fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""}
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const from = new Date(new Date(now).setHours(0, 0, 0, 0));

                    setFromDate(from);
                  }}
                />
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  name=""
                  id="toDate"
                  className="p-1 rounded "
                  value={toDate ? moment(toDate).format("YYYY-MM-DD") : ""}
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const to = new Date(
                      new Date(now).setHours(23, 59, 59, 999)
                    );
                    setToDate(to);
                  }}
                />
              </div>
            </div>
            {/* <SearchFieldWidget
              defaulValue={search}
              onClear={() => {
                setSearch("");
                setPage(1);
              }}
              onSubmit={(val) => {
                setPage(1);
                setSearch(val);

                // setTimeout(() => {
                //   setSearch(val);
                // }, 10);
              }}
            /> */}
            {/* <div
              onClick={() => {
                showModal({
                  title: `Add Ledger`,
                  type: ModalType.modal,
                  container(id) {
                    return (
                      <LedgerCreateScreen
                        onClose={(d) => {
                          if (d)
                            setData((o) => {
                              o.pop();
                              return [d, ...o];
                            });
                          setTotal((o) => o + 1);
                          onClose(id);
                        }}
                      />
                    );
                  },
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div> */}
          </div>
        </section>{" "}
        <TableView
          headers={[
            {
              key: "date",
              title: "Date",
              type: FieldType.date,
            },

            {
              key: "voucher_type",
              title: "Voucher Type",
              type: FieldType.string,
            },
            {
              key: "voucher_id",
              title: "Voucher id",
              type: FieldType.string,
            },
            {
              key: "warehouse_id",
              title: "warehouse",
              type: FieldType.string,
            },

            {
              key: "price",
              title: "Price",
              type: FieldType.currency,
            },
            {
              key: "unit_no",
              title: "Inward unit",
              type: FieldType.number,
            },
            {
              key: "",
              title: "Inward value",
              type: FieldType.currency,
            },
            {
              key: "unit_no",
              title: "Outward unit",
              type: FieldType.number,
            },
            {
              key: "",
              title: "Outward value",
              type: FieldType.currency,
            },
          ]}
          rows={data.map((data, i) => {
            return {
              data: data,
              values: [
                data.date,

                data.voucher_type,
                data.voucher_id,
                data.warehouse?.name || "--",
                currencyFormat(data.price),
                ...(data.movement_type === StockMovementType.inward
                  ? [
                      data.bill_unit_no + " " + data.item?.unit.symbol,
                      currencyFormat(data.bill_unit_no * data.price),
                      "--",
                      "--",
                    ]
                  : [
                      "--",
                      "--",
                      data.unit_no + " " + data.item?.unit.symbol,
                      currencyFormat(data.unit_no * data.price),
                    ]),
              ],
            };
          })}
          onClick={onSelect}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
        />{" "}
        <div className="flex justify-end text-xs ">
          <div className="grid grid-cols-7  divide-x divide-y  border ">
            <div className=""></div>
            {/* <div className="p-1">Price</div> */}
            <div className="p-1 col-span-2 flex flex-col items-center divide-y w-full">
              <div className="">Inward</div>
              <div className="grid grid-cols-2 justify-around w-full ">
                <div className="">Unit</div>
                <div className="">Value</div>
              </div>
            </div>
            <div className="p-1 col-span-2 flex flex-col items-center divide-y w-full">
              <div className="">Outward</div>
              <div className="grid grid-cols-2 justify-around w-full">
                <div className="">Unit</div>
                <div className="">Value</div>
              </div>
            </div>
            <div className="p-1 col-span-2 flex flex-col items-center divide-y w-full">
              <div className="">Closing</div>
              <div className="grid grid-cols-2 w-full">
                <div className="">Unit</div>
                <div className="">Value</div>
              </div>
            </div>
            {/* <div className="p-1">Opening stock</div> */}
            {/* <div className="p-1">
              {rupeeFormat(item?.opening_stock?.price || 0)}
            </div> */}
            {/* <div className=" col-span-2 grid grid-cols-2 divide-x">
              <div className="p-1 text-center">
                {item?.opening_stock?.unit_no}
              </div>
              <div className="p-1 text-right">
                {currencyFormat(
                  (item?.opening_stock.unit_no || 0) *
                    (item?.opening_stock.price || 0)
                )}
              </div>
            </div>
            <div className="p-1 col-span-2 grid grid-cols-2">
              <div className="">--</div>
              <div className="">--</div>
            </div>{" "}
            <div className=" col-span-2 grid grid-cols-2 divide-x">
              <div className="p-1 text-center">
                {item?.opening_stock?.unit_no}
              </div>
              <div className="p-1 text-right">
                {currencyFormat(
                  (item?.opening_stock.unit_no || 0) *
                    (item?.opening_stock.price || 0)
                )}
              </div>
            </div> */}
            <div className="p-1">Opening stock</div>
            {/* <div className="p-1">--</div> */}
            <div className=" col-span-2 grid grid-cols-2 divide-x">
              <div className="p-1 text-center">
                {(openingStock?.unit_no ?? 0) >= 0
                  ? openingStock?.unit_no ?? 0
                  : "--"}
              </div>
              <div className="p-1 text-right">
                {(openingStock?.unit_no ?? 0) >= 0
                  ? currencyFormat(openingStock?.price ?? 0)
                  : "--"}
              </div>
            </div>
            <div className=" col-span-2 grid grid-cols-2 divide-x">
              <div className="p-1 text-center">
                {(openingStock?.unit_no ?? 0) < 0
                  ? openingStock?.unit_no ?? 0
                  : "--"}
              </div>
              <div className="p-1 text-right">
                {(openingStock?.unit_no ?? 0) < 0
                  ? currencyFormat(openingStock?.price ?? 0)
                  : "--"}
              </div>
            </div>
            <div className=" col-span-2 grid grid-cols-2 divide-x">
              <div className="p-1 text-center">--</div>
              <div className="p-1 text-right">-- </div>
            </div>
            <div className="p-1">current stock</div>
            {/* <div className="p-1">--</div> */}
            <div className=" col-span-2 grid grid-cols-2 divide-x">
              <div className="p-1 text-center">{CurrentStock?.inward_qty}</div>
              <div className="p-1 text-right">
                {currencyFormat(CurrentStock?.inward_value ?? 0)}
              </div>
            </div>
            <div className=" col-span-2 grid grid-cols-2 divide-x">
              <div className="p-1 text-center">{CurrentStock?.outward_qty}</div>
              <div className="p-1 text-right">
                {currencyFormat(CurrentStock?.outward_value ?? 0)}
              </div>
            </div>
            <div className=" col-span-2 grid grid-cols-2 divide-x">
              <div className="p-1 text-center">
                {(CurrentStock?.inward_qty ?? 0) -
                  (CurrentStock?.outward_qty ?? 0)}
              </div>
              <div className="p-1 text-right">--</div>
            </div>
            <div className="p-1 font-bold">closing stock</div>
            {/* <div className="p-1">{rupeeFormat(closingPrice)}</div> */}
            <div className=" col-span-2 grid grid-cols-2 divide-x  font-bold">
              <div className="p-1 text-center">
                {closingStock?.total_inward_qty}
              </div>
              <div className="p-1 text-right">
                {currencyFormat(
                  (closingStock?.total_inward_qty || 0) * closingPrice
                )}
              </div>
            </div>
            <div className=" col-span-2 grid grid-cols-2 divide-x  font-bold">
              <div className="p-1 text-center">
                {closingStock?.total_outward_qty}
              </div>
              <div className="p-1 text-right">
                {currencyFormat(closingStock?.total_outward_value || 0)}
              </div>
            </div>
            <div className=" col-span-2 grid grid-cols-2 divide-x  font-bold">
              <div className="p-1 text-center">{closingStock?.closing_qty}</div>
              <div className="p-1 text-right">
                {currencyFormat(
                  (closingStock?.closing_qty || 0) * closingPrice
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default StockItemMovementScreen;
