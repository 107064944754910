import {
  mdiCheck,
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiChevronUp,
  mdiChevronDown,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet } from "../../../../service/api_client";
import { numberRegEx } from "../../../../utils/constant";
import ItemSearchField from "../../../inventory/items/item_search_field";
import { ItemModel } from "../../../inventory/models/item_model";
import WarehouseSearchField from "../../../inventory/warehouse/warehouse_search_field";
import { defaultPoItemValue, PoItem } from "../../model/purchase_order_model";
import { Currency } from "../../../accounts/models/party_model";

interface PoItemRowProps {
  data?: PoItem;
  currency: Currency;
  onSubmit: (data: PoItem) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: PoItem) => void;
}

const PoItemRow: FunctionComponent<PoItemRowProps> = (props) => {
  const [data, setData] = useState<PoItem>({
    ...defaultPoItemValue,
  });
  const { showToast } = useToast();
  const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit } = props;

  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!data.item || data.price < 0 || !data.unit_no) {
      showToast({
        type: ToastType.warning,
        text: "Please select Item,price and Qty",
      });
      return;
    }
    onSubmit(data);
  };

  // const getStockValue = async (item: ItemModel) => {
  //   const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/${item.id}/value`;
  //   setLoading(true);
  //   const res = await fetchGet(url);
  //   if (res.success) {
  //     setData((o) => ({
  //       ...o,
  //       item: item,
  //       item_id: item.id,
  //       price: res.data.price,
  //     }));
  //   } else showToast({ type: ToastType.error, text: res.error });
  //   setLoading(false);
  // };

  useEffect(() => {
    if (props.data) setData({ ...props.data });
  }, [props.data]);

  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white   text-sm  "
      >
        <div className="grid grid-cols-9 gap-5">
          <div className="col-span-2">
            <ItemSearchField
              disabled={!editable || props.disabled}
              value={data.item}
              // onSelect={getStockValue}
              onSelect={(d) => {
                setData((old) => {
                  return { ...old, item: d, item_id: d.id };
                });
              }}
            />
          </div>
          <input
            value={data?.item.tax_detail.hsn_sac || "--"}
            disabled={true}
            placeholder=""
            type="text"
            className="text-center  p-1 focus:outline-none border rounded"
          />
          <div className="grid grid-cols-4 gap-1 items-center">
            <input
              value={data?.unit_no}
              onChange={(e) => {
                const val = e.target.value.trim();
                if (!val || numberRegEx.test(val)) {
                  const value = Number(val || 0);
                  setData((ol) => ({
                    ...ol,
                    unit_no: value,
                    bill_unit_no:
                      ol.unit_no === ol.bill_unit_no ? value : ol.bill_unit_no,
                  }));
                }
              }}
              disabled={!editable || props.disabled}
              placeholder=""
              type="number"
              className="text-right  p-1 focus:outline-none border rounded col-span-3"
            />
            <div className="">{data.item?.unit.symbol}</div>
          </div>
          {/* <div className="grid grid-cols-4 gap-1 items-center">
            <input
              value={data?.bill_unit_no}
              onChange={(e) => {
                const val = e.target.value.trim();
                if (!val || numberRegEx.test(val)) {
                  const value = Number(val || 0);
                  setData((ol) => ({ ...ol, bill_unit_no: value }));
                }
              }}
              disabled={!editable || props.disabled}
              placeholder=""
              type="number"
              className="text-right  p-1 focus:outline-none border rounded col-span-3"
            />
            <div className="">{data.item?.unit.symbol}</div>
          </div> */}
          {/* <input
            value={data?.price}
            onChange={(e) => {
              let v = 0;
              v = Number(e.target.value);
              setData((ol) => ({ ...ol, price: v }));
            }}
            disabled={!editable || props.disabled}
            placeholder=""
            type="number"
            className="text-right  p-1 focus:outline-none border rounded"
          /> */}

          <div className="grid grid-cols-4  items-center">
            <div className="text-right">{props.currency.symbol}</div>
            <input
              value={data?.price}
              onChange={(e) => {
                let v = 0;
                v = Number(e.target.value);

                setData((ol) => ({ ...ol, price: v }));
              }}
              disabled={!editable || props.disabled}
              placeholder=""
              type="number"
              className="text-right p-1  focus:outline-none border rounded col-span-3"
            />
          </div>
          <div className="grid grid-cols-4  items-center">
            <div className="text-right">{props.currency.symbol}</div>
            <input
              value={(data?.bill_unit_no ?? 0) * (data?.price ?? 0)}
              disabled={true}
              placeholder=""
              type="number"
              className="text-right p-1  focus:outline-none border rounded col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 gap-1 items-center">
            <input
              value={data.received_unit_no}
              disabled={true}
              placeholder=""
              type="number"
              className="text-right  p-1 focus:outline-none border rounded col-span-3"
            />
            <div className="">{data.item?.unit.symbol}</div>
          </div>
          <div className="grid grid-cols-4 gap-1 items-center">
            <input
              value={data.returned_unit_no}
              disabled={true}
              placeholder=""
              type="number"
              className="text-right  p-1 focus:outline-none border rounded col-span-3"
            />
            <div className="">{data.item?.unit.symbol}</div>
          </div>

          <div className="flex gap-2 items-center justify-end">
            {props.data ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setData(props.data!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(data);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.data && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>
        {/* {showMore && props.item && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.item.id}</p>
              <p>Extra : {rupeeFormat(props.item.addition_amt ?? 0)}</p>
              <p>Discount : {rupeeFormat(props.item.discount_amt ?? 0)}</p>
              <p>Paid : {rupeeFormat(props.item.paid ?? 0)}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.created_at
                      ? moment(props.item.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.updated_at
                      ? moment(props.item.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )} */}
      </form>
    </>
  );
};

export default PoItemRow;
