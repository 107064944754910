import {
  mdiCheck,
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiChevronUp,
  mdiChevronDown,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { numberRegEx } from "../../../../../../utils/constant";
import LedgerSearchField from "../../../../../accounts/ledgers/ledger_search_field";
import {
  accountTxnType,
  LedgerEntryModel,
} from "../../../../../accounts/models/common_model";
import { account_sub_group } from "../../../../../accounts/models/ledger_group_model";
import {
  LedgerTaxDetail,
  tax_category,
  tax_type,
} from "../../../../../accounts/models/ledger_model";
import { ledgerEntryDefaultValue } from "../../../../../accounts/models/purchase_model";
import { ItemMovementModel } from "../../../../models/inventory_voucher";

export const getItemTaxAmount = ({
  items,
  ledgerTaxDetail,
}: {
  items: ItemMovementModel[];
  ledgerTaxDetail: LedgerTaxDetail;
}) => {
  let amount = 0;

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (!item.item?.tax_applicable) continue;
    if (ledgerTaxDetail.type === tax_type.gst) {
      if (
        ledgerTaxDetail.category ===
        tax_category[ledgerTaxDetail.type].integreted
      ) {
        amount +=
          item.bill_unit_no *
          item.price *
          ((item.item?.tax_detail.integreted ?? 0) / 100);
      } else if (
        ledgerTaxDetail.category === tax_category[ledgerTaxDetail.type].state
      ) {
        amount +=
          item.bill_unit_no *
          item.price *
          ((item.item?.tax_detail.state ?? 0) / 100);
      } else if (
        ledgerTaxDetail.category === tax_category[ledgerTaxDetail.type].central
      ) {
        amount +=
          item.bill_unit_no *
          item.price *
          ((item.item?.tax_detail.central ?? 0) / 100);
      }
    }
  }

  return amount;
};
interface TaxEntryRowProps {
  items: ItemMovementModel[];
  entry?: LedgerEntryModel;
  onSubmit: (data: LedgerEntryModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: LedgerEntryModel) => void;
}

const TaxEntryRow: FunctionComponent<TaxEntryRowProps> = (props) => {
  const [data, setData] = useState<LedgerEntryModel>({
    ...(props.entry || ledgerEntryDefaultValue),
    type: accountTxnType.debitor,
  });
  const { showToast } = useToast();
  const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit } = props;

  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!data.ledger || !data.amount) {
      showToast({
        type: ToastType.warning,
        text: "Please select Ledger and Amount",
      });
      return;
    }
    onSubmit(data);
  };
  useEffect(() => {
    if (data.ledger) {
      // const amount = getItemTaxAmount({
      //   items: props.items ?? [],
      //   ledgerTaxDetail: data.ledger?.tax_detail!,
      // });

      // setData((o) => ({ ...o, amount }));
      // // props.onSubmit({ ...data, amount });
      setData({ ...data });
    }
  }, [props.entry, props.items]);
  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white    p-1 "
      >
        <div className="grid grid-cols-6 gap-x-1">
          <div className="col-span-4">
            <LedgerSearchField
              group={account_sub_group.duties_and_taxes.name}
              disabled={!editable || props.disabled}
              value={data.ledger}
              onSelect={(d) => {
                setData((o) => ({
                  ...o,
                }));
                const t = { ...data, ledger: d, ledger_id: d.id };
                props.onSubmit(t);
              }}
            />
          </div>
          <input
            disabled={!editable || props.disabled}
            value={data.amount}
            onChange={(e) => {
              const val = e.target.value.trim();
              if (!val || numberRegEx.test(val)) {
                const value = Number(val || 0);
                setData((o) => ({
                  ...o,
                  amount: value,
                }));
              }
            }}
            placeholder=""
            type="text"
            className="text-right  p-1 focus:outline-none border rounded  w-full"
          />

          <div className="flex gap-2 items-center justify-end">
            {props.entry ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setData(props.entry!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(data);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.entry && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>
        {/* {showMore && props.item && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.item.id}</p>
              <p>Extra : {rupeeFormat(props.item.addition_amt ?? 0)}</p>
              <p>Discount : {rupeeFormat(props.item.discount_amt ?? 0)}</p>
              <p>Paid : {rupeeFormat(props.item.paid ?? 0)}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.created_at
                      ? moment(props.item.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.updated_at
                      ? moment(props.item.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )} */}
      </form>
    </>
  );
};

export default TaxEntryRow;
