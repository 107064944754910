import { mdiCheck, mdiClose, mdiPencil, mdiPlus, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import { useOrderDetailCtx } from "../../../../../context_providers/order_detail_context";

import OrderItemModel from "../../../../../service/models/orders/order_item_model";

import { MODULE, ACTION } from "../../../../../utils/enums";
import ProductRowWidget from "./product_row";

interface ProductSectionProps {}
const defaultItem: OrderItemModel = {
  product_id: 0,
  name: "",
  price: 0,
  qty: 0,
  delivered_qty: 0,
  installed_qty: 0,
  returned_qty: 0,
};
const ProductSection: FunctionComponent<ProductSectionProps> = () => {
  const { user, isAuthorised, logout } = useAuth();
  const {
    order,
    reconcileOrder,
    setOrder,
    products,
    getProducts,
    onProductAdded,
    onProductDeleted,
    onProductUpdated,
  } = useOrderDetailCtx();

  const [addNew, setAddNew] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [deliveredDisabled, setDeliveredDisabled] = useState(false);
  const [formId, setFormId] = useState(0);

  useEffect(() => {
    setDisabled(
      !isAuthorised({ module: MODULE.order, action: [ACTION.UPDATE] })
    );
    const superAdmin = isAuthorised({
      module: MODULE.modify_cart,
      action: [ACTION.UPDATE],
    });
    // setDeliveredDisabled(!superAdmin);
    if (!superAdmin)
      setDisabled(
        Date.now() -
          new Date(order.created_at as any).getTime() / (60000 * 60 * 24 * 3) >
          2
      );
  }, []);
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      <div className="flex justify-between items-end my-1">
        <h3 className="font-semibold text-gray-500">Products | services</h3>
        <div className="flex justify-end   text-sm items-center gap-2 ">
          <div
            onClick={reconcileOrder}
            className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
          >
            <Icon path={mdiRefresh} className="h-4 w-4"></Icon>Reconcile Qty
          </div>
          {addNew ? (
            <div className="flex gap-2">
              <div
                onClick={() => {
                  setAddNew(false);
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-red-100 hover:scale-105 duration-200 hover:text-red-600 hover:bg-red-200 text-red-400  transform"
              >
                <Icon path={mdiClose} className="h-4 w-4"></Icon>Cancel
              </div>
            </div>
          ) : (
            !disabled && (
              <div
                onClick={() => {
                  setAddNew(true);
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
              </div>
            )
          )}
        </div>
      </div>
      <div className="bg-white rounded-md shadow py-3 px-5 mb-2 text-sm">
        <div className="grid grid-cols-10 gap-3 ">
          <h4 className="font-semibold col-span-2 text-center">
            <span className="text-base text-red-500">*</span> Product/Service
          </h4>
          <h4 className="font-semibold text-center">
            {" "}
            <span className="text-base text-red-500">*</span>Quantity
          </h4>
          <h4 className="font-semibold text-center">Price per unit</h4>
          <h4 className="font-semibold text-center">Delivered</h4>
          <h4 className="font-semibold text-center">Installed</h4>
          <h4 className="font-semibold text-center">Returned</h4>
          <h4 className="font-semibold text-center">Net Qty</h4>
          <h4 className="font-semibold text-center">Amount</h4>

          <h4 className="font-semibold text-center">Action</h4>
        </div>
      </div>
      <div className="flex flex-col gap-2 text-sm">
        {order.items.map((item, i) => (
          <ProductRowWidget
            disabled={disabled}
            edit={false}
            products={products}
            key={item.id}
            item={item}
            onDelete={onProductDeleted}
            onSubmit={onProductUpdated}
          />
        ))}

        {addNew && !disabled && (
          <div className="   border-dashed  border-2 rounded-md border-green-300 p-2 text-sm">
            <ProductRowWidget
              disabled={false}
              key={formId}
              edit={true}
              // form_id="item_form"
              onSubmit={(data) => {
                if (window.confirm("Are you sure")) {
                  onProductAdded(data);
                  setFormId(Math.random());
                }
              }}
              products={products}
            />
            <div className="flex justify-between">
              <p className="text-sm italic text-gray-400">
                Please select product and qty then hit + to add product.
              </p>
              <p className="text-sm italic text-gray-400">
                Note: Enter amount including GST.
              </p>
            </div>
            {/* <div className="flex justify-center my-3">
              <button
                form="item_form"
                className="bg-myPrimaryColor rounded-full p-2 hover:scale-110 transform duration-300"
              >
                <Icon path={mdiPlus} className="h-5 w-5 text-white"></Icon>
              </button>
            </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default ProductSection;
