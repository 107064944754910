import { BaseModel } from "../../../service/models/base";
import { ItemMovementModel } from "../../inventory/models/inventory_voucher";
import { ItemModel } from "../../inventory/models/item_model";
import {
  LedgerEntryModel,
  OrderDetail,
  orderDetailDefailtValue,
  VoucherModel,
} from "./common_model";
import { ledgerDefaultValue, LedgerModel } from "./ledger_model";
import { PartyModel } from "./party_model";

export interface PurchaseVoucherItem {
  id: string;
  item_id: string;
  item?: ItemModel;
  bill_unit_no: number;
  unit_no: number;
  price: number;
  item_split?: ItemMovementModel[];
}

export const purchaseItemDefaultValue: PurchaseVoucherItem = {
  id: "",
  item_id: "",
  bill_unit_no: 0,
  unit_no: 0,
  price: 0,
};

export interface PurchaseVoucherModel extends VoucherModel {
  voucher_type: "Purchase";
  entry_type?: "General" | "Invoice";
  date: Date;

  purchase_ledger_entry: LedgerEntryModel;
  purchase_ledger_entry_id?: string;
  party_ledger_entry: LedgerEntryModel;
  party_ledger_entry_id?: string;

  invoice_date?: Date;
  invoice_no?: string;
  items?: PurchaseVoucherItem[];
  taxes: LedgerEntryModel[];
  tax_ids?: string[];
  tax?: number;
  extras: LedgerEntryModel[];
  extra_ids?: string[];
  extra?: number;
  discounts: LedgerEntryModel[];
  discount_ids?: string[];
  sub_total: number;
  discount?: number;
  total: number;
  paid: number;
  due_date: Date;
  credit_period_day?: number;
  party_detail?: PartyModel;
  party_id?: string;
  order_detail?: OrderDetail;
  remark?: string;
}

export const ledgerEntryDefaultValue: LedgerEntryModel = {
  ledger_id: "",
  voucher_id: "",
  voucher_type: "",
  date: new Date(),
  type: "",
  amount: 0,
};
export const defaultPurchaseValue: PurchaseVoucherModel = {
  voucher_type: "Purchase",
  entry_type: "Invoice",
  date: new Date(),
  due_date: new Date(),
  taxes: [],
  discounts: [],
  sub_total: 0,
  total: 0,
  paid: 0,
  purchase_ledger_entry: ledgerEntryDefaultValue,
  party_ledger_entry: ledgerEntryDefaultValue,
  purchase_ledger_entry_id: "",
  party_ledger_entry_id: "",
  tax_ids: [],
  discount_ids: [],
  order_detail: orderDetailDefailtValue,
  attachments: [],
  extras: [],
};
