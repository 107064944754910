import axios from "axios";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
  toQueryString,
} from "../../api_client";
import { ExcessDataModel } from "../../models/orders/order_excess_model";
import { OrderModel } from "../../models/orders/order_model";
import OrderItemModel from "../../models/orders/order_item_model";
import { DiscountDataModel } from "../../models/orders/order_discount_model";
import { TransactionDataModel } from "../../models/trannsaction_model";
import { SubscriptionPlanModel } from "../../models/orders/subscription_plan_model";
import { CustomerDetailModel } from "../../models/orders/customer_detail_model";
import { OrderDevice } from "../../../ui/pages/orders/order_detail/order_devices/order_device_row";
import { ItemMovementModel } from "../../../erp/inventory/models/inventory_voucher";
// ${process.env.REACT_APP_BACKEND_BASE_URL}
export const GetOrdersApi = async (option: {
  mine?: boolean;
  count?: number;
  page?: number;
  all?: string;
  order_by?: string;
  sort_order?: string;
  search?: string;
  fromDate?: string;
  toDate?: string;
  state?: string[];
  orderStatus?: string[];
  paymentStatus?: string[];
  saleExecutive?: string[];
  type?: string[];
  items?: string[];
}) => {
  let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/get-orders`;

  // if (option.page)
  //   url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders?page=${option.page}&`;
  // else if (option.count)
  //   url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders?count=${option.count}&`;
  // if (option.page && option.count)
  //   url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders?page=${option.page}&count=${option.count}&`;
  // if (option.order_by && option.sort_order)
  //   url = url + `order_by=${option.order_by}&sort_order=${option.sort_order}&`;
  // if (option.fromDate) url = url + "fromDate=" + option.fromDate + "&";
  // if (option.toDate) url = url + "toDate=" + option.toDate + "&";

  // if (option.state?.length) url = url + "state=" + option.state + "&";
  // if (option.orderStatus?.length)
  //   url = url + "orderStatus=" + option.orderStatus + "&";
  // if (option.paymentStatus?.length)
  //   url = url + "paymentStatus=" + option.paymentStatus + "&";
  // if (option.saleExecutive?.length)
  //   url = url + "saleExecutive=" + option.saleExecutive + "&";
  // if (option.type?.length) url = url + "type=" + option.type + "&";
  // if (option.items?.length) url = url + "items=" + option.items + "&";
  // if (option.search) url = url + `search=${option.search}&`;
  // if (option.all) url = url + `all=${option.all}`;

  try {
    const res = await fetchPost(url, option);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOrderApi = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${id}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const ReconcileOrderApi = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${id}/reconcile`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOrderNumberApi = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/generate-order-id`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const SearchOrderRepo = async (option: {
  search: string;
  page?: number;
  count?: number;
  all?: boolean;
}) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/search?`;
    if (option.page && option.count)
      url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/search?page=${option.page}&count=${option.count}&`;
    else if (option.page)
      url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/search?page=${option.page}&`;
    else if (option.count)
      url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/search?count=${option.count}&`;
    if (option.search) url = url + `search=${option.search}&`;
    if (option.all) url = url + `all=${option.all}`;

    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CreateOrderApi = async (data: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const UpdateOrderApi = async (data: OrderModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.id}`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const CreateOrderItemApi = async (item: OrderItemModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${item.order_id}/items`;
    const res = await fetchPost(url, { item });
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const UpdateOrderItemApi = async (item: OrderItemModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${item.order_id}/items/${item.id}`;
    const res = await fetchPut(url, { item });
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const DeleteOrderItemApi = async (item: {
  order_id: string;
  item_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${item.order_id}/items/${item.item_id}`;
    const res = await fetchDelete(url);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};

export const CreateOrderExtraApi = async (extra: ItemMovementModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${extra.voucher_id}/extras`;
    const res = await fetchPost(url, { extra });
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const UpdateOrderExtraApi = async (extra: ItemMovementModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${extra.voucher_id}/extras/${extra.id}`;
    const res = await fetchPut(url, { extra });
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const DeleteOrderExtraApi = async (item: {
  order_id: string;
  item_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${item.order_id}/extras/${item.item_id}`;
    const res = await fetchDelete(url);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};

export const CreateOrderDiscountApi = async (discount: DiscountDataModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${discount.order_id}/discounts`;
    const res = await fetchPost(url, { discount });
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const UpdateOrderDiscountApi = async (discount: DiscountDataModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${discount.order_id}/discounts/${discount.id}`;
    const res = await fetchPut(url, { discount });
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const DeleteOrderDiscountApi = async (item: {
  order_id: string;
  item_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${item.order_id}/discounts/${item.item_id}`;
    const res = await fetchDelete(url);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const CreateOrderPaymentApi = async (data: {
  form: FormData;
  order_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.order_id}/payments`;
    const res = await fetchPost(url, data.form);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const UpdateOrderPaymentApi = async (payment: TransactionDataModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${payment.ref_id}/payments/${payment.id}`;
    const res = await fetchPut(url, { payment });
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const DeleteOrderPaymentApi = async (item: {
  order_id: string;
  item_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${item.order_id}/payments/${item.item_id}`;
    const res = await fetchDelete(url);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const GetOrderPaymentApi = async ({
  order_id,
  ref_id,
  ref_type,
}: {
  order_id: string;
  ref_id: string;
  ref_type: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/payments/${ref_type}/${ref_id}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const GetOrderSubscriptionApi = async (order_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/subscription/plan`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const CreateOrderSubscriptionApi = async (
  data: SubscriptionPlanModel
) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.order_id}/subscription/plan`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const UpdateOrderSubscriptionApi = async (
  data: SubscriptionPlanModel
) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.order_id}/subscription/plan/${data.id}`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const GetOrderSubscriptionBillsApi = async (order_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/subscription/bill`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
// export const UpdateOrderDiscountApi = async (
//   order_id: number,
//   discounts: DiscountDataModel[]
// ) => {
//   try {
//     const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/discounts`;

//     const res = await fetchPut(url, { order_id, discounts });

//     return res;
//   } catch (error: any) {
//     return { success: false };
//   }
// };
// export const UpdateOrderExtrasApi = async (
//   order_id: number,
//   extras: ExcessDataModel[]
// ) => {
//   try {
//     const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/extras`;

//     const res = await fetchPut(url, { order_id, extras });

//     return res;
//   } catch (error: any) {
//     return { success: false };
//   }
// };
export const UpdateOrderStatusApi = async (data: {
  id: number;
  status: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/status`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const UpdateOrderDetailApi = async (data: {
  [key: string]: any;
  order_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.id}/detail`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export async function DownloadInvoice(order_id: string) {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/invoice/${order_id}`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      }
    );

    return { success: true, data: res.data };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
}

export const GetOrderCommentApi = async (data: {
  order_id: string;
  category: string;
  page: number;
  count: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${
      data.order_id
    }/comments${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CreateOrderCommentApi = async (data: {
  order_id: string;
  comment: string;
  category: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.order_id}/comments`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const DeleteOrderCommentApi = async (data: {
  order_id: string;
  comment_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.order_id}/comments/${data.comment_id}`;
    const res = await fetchDelete(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOrderLogsApi = async (data: {
  log_type?: string;
  order_id: string;
  page?: number;
  count?: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${
      data.order_id
    }/logs${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
// export const CreateOrderOperationApi = async (data: {
//   order_id: number;
//   operation: string;
// }) => {
//   try {
//     const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.order_id}/action`;
//     const res = await fetchPost(url, data);

//     return res;
//   } catch (error: any) {
//     return { success: false, error: error.message };
//   }
// };
export const GetOrderChatApi = async (id: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${id}/communication`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CreateOrderChatApi = async (data: {
  template_id: number;
  order_id: string;
  invoice_id?: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/communication`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const SendOrderMailApi = async (data: {
  template_id: number;
  order_id: string;
  invoice_id?: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.order_id}/communication`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UploadOrderAttachmentApi = async (data: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/attachment`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOrderAttachmentApi = async (id: number | string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/attachment/${id}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const DeleteOrderAttachmentApi = async (id: number | string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/attachment/${id}`;
    const res = await fetchDelete(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const CreateOrderDeviceApi = async (data: {
  device_ids: string[];
  order_id: string;
  status: string;
  org_namespace: string;
  org_name: string;
  date: Date;
  item_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.order_id}/devices`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
// {
//   id: string;
//   order_id: string;
//   device_id: string;
//   status: string;
//   product_name: string;
//   created_by: any;
//   created_at: Date;
//   created_by_name: string;
//   updated_at: Date;
//   updated_by: any;
//   updated_by_name: string;
//   installation_date: Date;
//   return_back_date: Date;
//   delivery_date: Date;
//   item_id: string;
// }
export const UpdateOrderDeviceApi = async (data: OrderDevice) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.order_id}/devices/${data.id}`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const UpdateOrderDeviceReplaceApi = async (data: {
  device: OrderDevice;
  replaceDevice?: OrderDevice;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${data.device.order_id}/devices/${data.device.id}`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};
export const GetOrderDevicesApi = async (order_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/devices`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false };
  }
};

export const GetLastOrderOfCustomerApi = async (user_id: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/users/${user_id}/orders/last`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetCustomersOfOrderApi = async (data: {
  search?: string;
  page?: number;
  count?: number;
  all?: boolean;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/orders/customers${toQueryString(data)}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateOrderCustomerApi = async ({
  data,
  order_id,
}: {
  data: CustomerDetailModel;
  order_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/billing-profile/${data.id}`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateOrderDeliveryProfileApi = async ({
  data,
  order_id,
}: {
  data: CustomerDetailModel;
  order_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/delivery-profile/${data.id}`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
