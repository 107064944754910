import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPost, fetchPut } from "../../../service/api_client";
import { UTCToLocalDate } from "../../../utils/date_util";
import { urls } from "../../../utils/urls";
import ItemGroupSearchField from "../../inventory/item_group/item_group_search_field";
import ItemUnitSearchField from "../../inventory/item_unit/item_unit_search_field";
import LedgerGroupSearchField from "../ledger_group/ledger_group_search_field";
import { accountTxnType } from "../models/common_model";
import { account_sub_group } from "../models/ledger_group_model";
import {
  ledgerDefaultValue,
  LedgerModel,
  ledgerTaxDetailDefaultValue,
  tax_category,
  tax_type,
} from "../models/ledger_model";
import PartySearchField from "../parties/party_search_field";
import { itemTaxDetailDefault } from "../../inventory/models/item_model";
import { mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";

interface LedgerCreateScreenProps {
  onClose: (data?: LedgerModel) => void;
  data?: LedgerModel;
}

const LedgerCreateScreen: FunctionComponent<LedgerCreateScreenProps> = (
  props
) => {
  const is_update_page = useRouteMatch(
    urls.procurement_masters_ledgers + "/update/:id"
  );
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/ledgers`;

  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [data, setData] = useState<LedgerModel>({
    ...(props.data || ledgerDefaultValue),
  });
  const submit = async () => {
    if (!data.name || !data.group || !data.group.name) {
      return showToast({
        type: ToastType.error,
        text: "Please fill all the required details",
      });
    }
    if (data.group.name === account_sub_group.bank_accounts.name) {
      if (
        !data.bank_detail?.ac_no ||
        !data.bank_detail?.ifsc ||
        !data.bank_detail?.branch ||
        !data.bank_detail?.bank_name
      )
        return showToast({
          type: ToastType.error,
          text: "Please fill bank details",
        });
    }
    if (data.group.name === account_sub_group.duties_and_taxes.name) {
      if (!data.tax_detail?.type)
        return showToast({
          type: ToastType.error,
          text: "Please select tax type",
        });
      if (data.tax_detail?.type === tax_type.gst && !data.tax_detail?.category)
        return showToast({
          type: ToastType.error,
          text: "Please select tax category",
        });
    }
    if (data.service_tax_applicable) {
      if (!data.service_tax?.type)
        return showToast({
          type: ToastType.error,
          text: "Please select serivce tax type",
        });
    }

    setLoading(true);
    const res = data.id
      ? await fetchPut(url + "/" + data.id, data)
      : await fetchPost(url, data);
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      if (!data.id) setData(ledgerDefaultValue);
      if (props.onClose) props.onClose(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (id && is_update_page) getData(id);
  }, []);
  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  return (
    <>
      {" "}
      <div className="  grid grid-cols-1 m-5 gap-2 select-none text-sm">
        <h1 className="  text-xl font-bold border-l-4 border-myPrimaryColor pl-2 ">
          Ledger {id ? "update" : "create"}
        </h1>
        <div className="  grid grid-cols-2 m-5 gap-2 select-none">
          <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white rounded-lg">
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="name">
                Name*
              </label>
              <input
                placeholder="Enter name"
                className="p-1 rounded  focus:outline-none w-full border"
                id="name"
                name="name"
                value={data.name || ""}
                required={true}
                type="text"
                onChange={(e) => {
                  setData((o) => ({ ...o, name: e.target.value }));
                }}
              ></input>
            </div>
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="name">
                Description
              </label>
              <textarea
                placeholder="Short description"
                className="p-1 rounded  focus:outline-none w-full border"
                id="desc"
                name="desc"
                value={data.description || ""}
                onChange={(e) => {
                  setData((o) => ({ ...o, description: e.target.value }));
                }}
              ></textarea>
            </div>

            <div className="grid grid-cols-2 gap-5 items-end">
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="under">
                  Under Group*
                </label>

                <LedgerGroupSearchField
                  value={data.group}
                  onSelect={(d) => {
                    setData((o) => ({
                      ...o,
                      group: d,
                    }));
                  }}
                />
              </div>
              <div className="flex   items-center text-sm w-full gap-2 ">
                <input
                  type="checkbox"
                  name=""
                  id="use_default"
                  checked={data.is_default}
                  onChange={(e) => {
                    setData((o) => ({
                      ...o,
                      is_default: e.target.checked,
                    }));
                  }}
                />
                <label className="font-semibold w-full" htmlFor="use_default">
                  Use as default
                </label>
              </div>
            </div>
            {data.group.name === account_sub_group.bank_accounts.name && (
              <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="bank_name">
                    Bank name*
                  </label>
                  <input
                    placeholder="Enter bank name"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="bank_name"
                    name="name"
                    value={data.bank_detail?.bank_name || ""}
                    required={true}
                    type="text"
                    onChange={(e) => {
                      setData((o) => {
                        if (!o.bank_detail) {
                          o.bank_detail = {
                            ac_no: "",
                            bank_name: "",
                            branch: "",
                            ifsc: "",
                          };
                        }
                        o.bank_detail.bank_name = e.target.value;
                        return { ...o };
                      });
                    }}
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="bank_ac">
                    Account number*
                  </label>
                  <input
                    placeholder="Enter bank name"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="bank_ac"
                    name="name"
                    value={data.bank_detail?.ac_no || ""}
                    required={true}
                    type="text"
                    onChange={(e) => {
                      setData((o) => {
                        if (!o.bank_detail) {
                          o.bank_detail = {
                            ac_no: "",
                            bank_name: "",
                            branch: "",
                            ifsc: "",
                          };
                        }
                        o.bank_detail.ac_no = e.target.value;
                        return { ...o };
                      });
                    }}
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="bank_ifsc">
                    IFSC*
                  </label>
                  <input
                    placeholder="Enter bank name"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="bank_ifsc"
                    name="name"
                    value={data.bank_detail?.ifsc || ""}
                    required={true}
                    type="text"
                    onChange={(e) => {
                      setData((o) => {
                        if (!o.bank_detail) {
                          o.bank_detail = {
                            ac_no: "",
                            bank_name: "",
                            branch: "",
                            ifsc: "",
                          };
                        }
                        o.bank_detail.ifsc = e.target.value;
                        return { ...o };
                      });
                    }}
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="bank_branch">
                    Branch*
                  </label>
                  <input
                    placeholder="Enter bank name"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="bank_branch"
                    name="name"
                    value={data.bank_detail?.branch || ""}
                    required={true}
                    type="text"
                    onChange={(e) => {
                      setData((o) => {
                        if (!o.bank_detail) {
                          o.bank_detail = {
                            ac_no: "",
                            bank_name: "",
                            branch: "",
                            ifsc: "",
                          };
                        }
                        o.bank_detail.branch = e.target.value;
                        return { ...o };
                      });
                    }}
                  ></input>
                </div>
              </div>
            )}
            {data.group.name === account_sub_group.duties_and_taxes.name && (
              <div className="grid grid-cols-2 gap-5">
                <div className="">
                  <label htmlFor="tax_type">Type of tax/duty*</label>
                  <select
                    value={data.tax_detail?.type}
                    onChange={(e) => {
                      setData((o) => {
                        if (!o.tax_detail) {
                          o.tax_detail = ledgerTaxDetailDefaultValue;
                        }
                        o.tax_detail.type = e.target.value;
                        return { ...o };
                      });
                    }}
                    name=""
                    id="tax_type"
                    className="p-1 rounded  focus:outline-none w-full border"
                  >
                    <option value="" hidden>
                      Select
                    </option>

                    {Object.values(tax_type).map((c, i) => (
                      <option value={c} key={i}>
                        {c}
                      </option>
                    ))}
                  </select>
                </div>
                {data.tax_detail?.type === tax_type.gst && (
                  <div className="">
                    <label htmlFor="tax_cat">Tax category</label>
                    <select
                      value={data.tax_detail?.category}
                      onChange={(e) => {
                        setData((o) => {
                          if (!o.tax_detail) {
                            o.tax_detail = ledgerTaxDetailDefaultValue;
                          }
                          o.tax_detail.category = e.target.value;
                          return { ...o };
                        });
                      }}
                      name=""
                      id="tax_cat"
                      className="p-1 rounded  focus:outline-none w-full border"
                    >
                      <option value="" hidden>
                        Select
                      </option>

                      {Object.values(tax_category[data.tax_detail.type]).map(
                        (c, i) => (
                          <option value={c} key={i}>
                            {c}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}
              </div>
            )}

            {[
              account_sub_group.sundry_debtors.name,
              account_sub_group.sundry_creditors.name,
            ].includes(data.group.name) && (
              <div className="grid grid-cols-2 gap-5">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="unit">
                    Party
                  </label>
                  <PartySearchField
                    show_add={true}
                    value={data.party}
                    onSelect={(d) => {
                      setData((o) => ({ ...o, party: d }));
                    }}
                  />
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold" htmlFor="credit_period">
                    Credit Period (in days)*
                  </label>
                  <input
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        credit_periods: Number(e.target.value),
                      }));
                    }}
                    type="number"
                    name="credit_period"
                    id="credit_period"
                    value={data.credit_periods}
                    className="p-1 rounded  focus:outline-none w-full border"
                  />
                </div>
              </div>
            )}
            <div className="">
              <h4 className="font-semibold"> Opening Balance </h4>
              <div className="grid grid-cols-3 gap-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="date">
                    Date*
                  </label>
                  <input
                    placeholder="0"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="date"
                    name="date"
                    value={
                      data.opening_balance.date
                        ? moment(data.opening_balance.date).format("YYYY-MM-DD")
                        : ""
                    }
                    required={true}
                    type="date"
                    onChange={(e) => {
                      const date = UTCToLocalDate(e.target.value)!;

                      setData((o) => ({
                        ...o,
                        opening_balance: { ...o.opening_balance!, date },
                      }));
                    }}
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="price">
                    Amount*
                  </label>
                  <input
                    placeholder="0"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="price"
                    name="price"
                    value={data.opening_balance?.amount || ""}
                    required={true}
                    type="number"
                    onChange={(e) => {
                      const amount = Number(e.target.value);

                      setData((o) => ({
                        ...o,
                        opening_balance: { ...o.opening_balance!, amount },
                      }));
                    }}
                  ></input>
                </div>{" "}
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="item_type">
                    Type*
                  </label>
                  <select
                    value={data.opening_balance.txn_type}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        opening_balance: {
                          ...o.opening_balance!,
                          txn_type: e.target.value,
                        },
                      }));
                    }}
                    name="item_type"
                    id="item_type"
                    className="p-1 rounded  focus:outline-none w-full border"
                  >
                    <option value="" hidden>
                      select
                    </option>
                    {Object.values(accountTxnType).map((type, i) => (
                      <option value={type} key={i}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
            <div className="">
              <h4 className="font-semibold">Service Tax Details </h4>
              <div className="flex gap-1 text-gray-500 text-xs items-center">
                <Icon path={mdiInformationOutline} size={0.8}></Icon>Note :
                Service tax applicable on serivces like indirect expenses
                Eg:Delivery charges
              </div>
            </div>
            <div className="flex gap-5   items-center text-sm w-full">
              <input
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    service_tax_applicable: e.target.checked,
                    service_tax: e.target.checked
                      ? { ...itemTaxDetailDefault }
                      : undefined,
                  }));
                }}
                type="checkbox"
                name="service_tax_applicable"
                id="service_tax_applicable"
                checked={data.service_tax_applicable}
              />
              <label className="font-semibold" htmlFor="service_tax_applicable">
                Service Tax Applicable
              </label>
            </div>
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="tax_type">
                Tax type{data.service_tax_applicable ? "*" : ""}
              </label>
              <select
                disabled={!data.service_tax_applicable}
                value={data.service_tax?.type}
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    service_tax: { ...o.service_tax!, type: e.target.value },
                  }));
                }}
                name="tax_type"
                id="tax_type"
                className="p-1 rounded  focus:outline-none w-full border"
              >
                <option value="" hidden>
                  select
                </option>
                {Object.values(tax_type).map((tax_type, i) => (
                  <option value={tax_type} key={i}>
                    {tax_type.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="hsn">
                HSN/SAC
                {/* {data.service_tax_applicable ? "*" : ""} */}
              </label>
              <input
                disabled={!data.service_tax_applicable}
                placeholder="Enter HSN or SAC number"
                className="p-1 rounded  focus:outline-none w-full border"
                id="hsn"
                name="hsn"
                value={data.service_tax?.hsn_sac || ""}
                required={data.service_tax_applicable}
                type="text"
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    service_tax: { ...o.service_tax!, hsn_sac: e.target.value },
                  }));
                }}
              ></input>
            </div>
            {data.service_tax?.type === tax_type.gst && (
              <>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="integrated">
                    Integrated Tax (%)*
                  </label>
                  <input
                    disabled={!data.service_tax_applicable}
                    placeholder="0"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="integrated"
                    name="integrated"
                    value={data.service_tax.integreted || 0}
                    required={true}
                    type="number"
                    max={100}
                    min={0}
                    onChange={(e) => {
                      const integreted = Number(e.target.value);
                      setData((o) => ({
                        ...o,
                        service_tax: {
                          ...o.service_tax!,
                          integreted,
                          central: integreted / 2,
                          state: integreted / 2,
                        },
                      }));
                    }}
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="central">
                    Central Tax(%)*
                  </label>
                  <input
                    disabled={!data.service_tax_applicable}
                    placeholder="0"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="central"
                    name="central"
                    value={data.service_tax.central || 0}
                    required={true}
                    type="number"
                    max={100}
                    min={0}
                    onChange={(e) => {
                      const central = Number(e.target.value);
                      setData((o) => ({
                        ...o,
                        service_tax: {
                          ...o.service_tax!,
                          integreted: o.service_tax!.state + central,
                          central,
                        },
                      }));
                    }}
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="state">
                    State Tax(%)*
                  </label>
                  <input
                    disabled={!data.service_tax_applicable}
                    placeholder="0"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="state"
                    name="state"
                    value={data.service_tax.state || 0}
                    required={true}
                    type="number"
                    max={100}
                    min={0}
                    onChange={(e) => {
                      const state = Number(e.target.value);
                      setData((o) => ({
                        ...o,
                        service_tax: {
                          ...o.service_tax!,
                          integreted: o.service_tax!.central + state,

                          state,
                        },
                      }));
                    }}
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="cess">
                    Cess Tax(%)*
                  </label>
                  <input
                    disabled={!data.service_tax_applicable}
                    placeholder="0"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="cess"
                    name="cess"
                    value={data.service_tax.cess || 0}
                    required={true}
                    type="number"
                    max={100}
                    min={0}
                    onChange={(e) => {
                      const cess = Number(e.target.value);
                      setData((o) => ({
                        ...o,
                        service_tax: {
                          ...o.service_tax!,
                          cess,
                        },
                      }));
                    }}
                  ></input>
                </div>
              </>
            )}
          </div>
        </div>{" "}
        <div className="flex justify-center gap-5 mt-5 mb-2">
          <button
            onClick={submit}
            type="button"
            className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
          >
            Submit
          </button>
        </div>
      </div>
      {loading && <LoadingWidget />}
    </>
  );
};

export default LedgerCreateScreen;
