import { FunctionComponent, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import {
  mdiComment,
  mdiCommentOutline,
  mdiNavigationVariantOutline,
  mdiShieldCheckOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import SurveySection from "./components/survey_section";
import { GetOpsTaskApi } from "../../../../../service/repos/ops/ops_tickets_repo";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  OpsTaskDeviceItemStatus,
  OpsTaskModel,
} from "../../../../../service/models/ops/ops_ticket";
import { useParams } from "react-router-dom";
import StatusWidget from "./components/status_widget";
import moment from "moment";
import { Ops_task_status, TaskType } from "../../../../../utils/enums";
import {
  OpsTaskProvider,
  useOpsTask,
} from "../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";

import { fetchGet } from "../../../content_panel/services/serviceClient";
import LoadingWidget from "../../../../components/loading_spinner";
import OtpVerificationSection from "./components/otp_verification_section";
import { fetchPut } from "../../../../../service/api_client";
import { durationLabel } from "../../../../../utils/date_util";
import TimerWidget from "../../attendance/timer_widget";
import ServiceDeviceSection from "./components/service_device_section";
import DeviceServiceScreen from "./components/device_service_screen";
import ChecklistScreen from "./components/checklist_screen";
import RemarkWidget from "./components/remark_widget";
import { useTaskSession } from "../../../../../context_providers/task_session_ctx";
import { urls } from "../../../../../utils/urls";
import ComponentUsedSection from "./components/component_used_section";
import DeviceTxnTest from "./components/device_txn_test";
import DeviceScanSection from "./components/delivery_section";
import DeviceScannerScreen from "./components/device_scanner";
import ModalDialog from "../../../../new_components/common/modal";
import TaskAttachmentWidget from "../../components/attachment_widget";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../../new_components/common/drawer_modal";
import CommentWidget from "../../comment_widget";
import AttachmentScreen from "./components/attachments";
import CommentScreen from "./components/comment_screen";
import InstallationSection from "./components/device_item_list_section";
import { getDistance } from "../../../../../utils/distance_util";
import CommonDetailSection from "./components/common_detail_section";
import DeviceItemDetailSection from "./components/device_item_detail";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import DeviceListSection from "./components/device_item_list_section";

interface EngineerTaskDetailProps {}

const EngineerTaskDetail: FunctionComponent<EngineerTaskDetailProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);
  const { path } = useRouteMatch();
  return (
    <OpsTaskProvider>
      <Route exact path={`${path}`}>
        <div className="p-2">
          <TaskDetail />
        </div>
      </Route>
      {/* <Route exact path={`${path}/device/:device_id/scan`}>
        <DeviceScannerScreen />
      </Route> */}
      <Route exact path={`${path}/attachments`}>
        <AttachmentScreen />
      </Route>
      <Route exact path={`${path}/comments`}>
        <CommentScreen />
      </Route>
      <Route exact path={`${path}/device/:device_id/detail`}>
        <div className="p-2">
          <DeviceItemDetailSection />
        </div>
      </Route>
      <Route exact path={`${path}/device/:device_id/detail/components`}>
        <div className="p-2">
          <ComponentUsedSection />
        </div>
      </Route>
      <Route exact path={`${path}/device/:device_id/transaction-test`}>
        <div className="p-2">
          <DeviceTxnTest />
        </div>
      </Route>
      <Route exact path={`${path}/device/:device_id/service`}>
        <div className="p-2">
          <DeviceServiceScreen />
        </div>
      </Route>
      <Route exact path={`${path}/checklist`}>
        <div className="p-2">
          <ChecklistScreen />
        </div>
      </Route>
      <Route exact path={`${path}/verification`}>
        <OtpVerificationSection />
      </Route>
    </OpsTaskProvider>
  );
};

export default EngineerTaskDetail;

interface TaskDetailProps {}

const TaskDetail: FunctionComponent<TaskDetailProps> = () => {
  const { user, isAuthorised } = useAuth();
  const { onDuty, getLocation, position } = useTaskSession();
  const { data, setData } = useOpsTask();
  const { showToast } = useToast();
  const [showComments, setShowComments] = useState<boolean>(false);
  const [showAttachment, setShowAttachment] = useState(false);
  const [loading, setLoading] = useState(false);

  const [allDone, setAllDone] = useState(false);
  const { url, path } = useRouteMatch();
  const start_task = async () => {
    await getLocation();

    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/start`;
    const res = await fetchPut(url, {
      id: data?.id,
      start_location: {
        type: "Point",
        coordinates: [
          position!.coords!.longitude!,
          position!.coords!.latitude!,
        ],
      },
    });
    if (res.success) {
      setData({ ...res.data });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const stop_task = async () => {
    if (!window.confirm("Are you sure")) return;
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/stop`;
    // const res = await fetchPut(url, { id: data?.id });
    const res = await fetchPut(url, {
      id: data?.id,
      stop_location: {
        type: "Point",
        coordinates: [
          position!.coords!.longitude!,
          position!.coords!.latitude!,
        ],
      },
    });
    if (res.success) {
      setData({ ...res.data });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      let all_done = data?.device_items?.reduce(
        (pv, v) => pv && [OpsTaskDeviceItemStatus.completed].includes(v.status),
        true
      );
      if (!data.device_items?.length) all_done = false;
      if (
        [TaskType.instalation, TaskType.service, TaskType.complaint].includes(
          data?.type!
        )
      ) {
        if (
          !data.checklist?.section_a.length ||
          !data.checklist?.section_b.length ||
          !data.checklist?.section_c.length
        ) {
          all_done = false;
        }
      }
      if (
        data.distance_travelled !== undefined &&
        (all_done ||
          [TaskType.scout, TaskType.other, TaskType].includes(data!.type) ||
          (data.type == TaskType.survey && data.attachments?.length))
      ) {
        setAllDone(true);
      }
    }
  }, [data]);

  return (
    <>
      <div className="flex justify-between mb-2">
        <h1 className="font-semibold text-lg">#{data?.id}</h1>
        <div className="">
          <StatusWidget status={data?.status ?? ""} />
        </div>
      </div>
      <div className="flex justify-between">
        <p
          className="text-sm uppercase  font-semibold "
          style={{ color: "#a88132" }}
        >
          {data?.type}
        </p>{" "}
        {data?.status == Ops_task_status.completed ? (
          <p className="text-xs text-green-500 ">
            Completed On{" "}
            {moment(data?.end_date).utcOffset(330).format("DD MMM YYYY")}
          </p>
        ) : (
          <div className="">
            {new Date(data?.last_date!).getTime() > Date.now() ? (
              <p className="text-xs text-yellow-500 ">
                Due date{" "}
                {moment(data?.last_date).utcOffset(330).format("DD MMM YYYY")}
              </p>
            ) : (
              <p className="text-xs text-red-500 ">
                Over due on{" "}
                {moment(data?.last_date).utcOffset(330).format("DD MMM YYYY")}
              </p>
            )}
          </div>
        )}
      </div>
      <div className="border rounded-lg p-3 bg-white">
        <div className="flex gap-5 items-end justify-between">
          <div className="flex flex-col justify-between">
            <p className="  text-myDdarkBgcolor font-semibold ">
              {data?.cust_name}
            </p>
            <p className="text-sm  text-myDdarkBgcolor font-semibold ">
              {data?.cust_mobile}
              <br />
              {data?.cust_alt_mobile}
            </p>
            <div className="text-sm text-gray-500 mt-1">
              {data?.address?.full_address ?? "--"}
            </div>
            <span className="whitespace-nowrap text-xs mb-1">
              Distance from start point {data?.distance?.toFixed(2) ?? "--"} KM
            </span>
          </div>
          <div className="flex flex-col items-center">
            <span className="whitespace-nowrap text-xs mb-1">
              {data?.address?.location?.coordinates[1] &&
              data?.address?.location?.coordinates[0]
                ? getDistance({
                    lat1: position?.coords.latitude ?? 0,
                    lon1: position?.coords.longitude ?? 0,
                    lat2: data?.address?.location?.coordinates[1] ?? 0,
                    lon2: data?.address?.location?.coordinates[0] ?? 0,
                    unit: "K",
                  }).toFixed(2)
                : "--"}{" "}
              KM
            </span>
            <a
              href={`https://maps.google.com/?q=${data?.address?.location?.coordinates[1]},${data?.address?.location?.coordinates[0]}&z=18`}
              className=""
            >
              <div className="rounded shadow p-2">
                <Icon path={mdiNavigationVariantOutline} size={1} />
              </div>
            </a>
            <div className="whitespace-nowrap" style={{ fontSize: "8px" }}>
              View on Map
            </div>
          </div>
        </div>
      </div>

      {[
        TaskType.instalation,
        TaskType.configuration,
        TaskType.complaint,
        TaskType.service,
      ].includes(data!.type) ? (
        <DeviceListSection />
      ) : data?.type == TaskType.delivery ||
        data?.type == TaskType.uninstallation ? (
        <DeviceScanSection />
      ) : (
        <></>
      )}

      {[TaskType.instalation, TaskType.service, TaskType.complaint].includes(
        data?.type!
      ) && (
        <Link to={`${url}/checklist`} className=" my-2 ">
          <div className="flex justify-between items-center bg-purple-50 text-purple-500 border rounded-lg border-purple-300 text-center px-3 py-2 w-full font-bold  ">
            <div className="flex items-center gap-1 uppercase">
              <Icon path={mdiShieldCheckOutline} size={0.8} /> Safety check
            </div>
            <div className="text-xs">
              {!data?.checklist?.section_a.length
                ? "Section A is pending"
                : !data?.checklist?.section_b.length
                ? "Section B is pending"
                : !data?.checklist?.section_c.length
                ? "Section C is pending"
                : "Completed"}
            </div>
          </div>
        </Link>
      )}

      <hr className="border my-5 border-dashed" />
      <CommonDetailSection />
      <hr className="border my-5 border-dashed" />
      <div className="grid grid-cols-2 gap-5 my-2">
        <Link to={`${url}/attachments`} className="">
          <div className="bg-blue-50 text-blue-500 border-blue-300 border rounded-lg px-3 py-1 text-center">
            Attachments
          </div>
        </Link>
        <Link to={`${url}/comments`} className="">
          <div className="bg-yellow-50 text-yellow-500 border-yellow-300 border rounded-lg px-3 py-1 text-center">
            Comments
          </div>
        </Link>
      </div>
      {data?.status == Ops_task_status.completed &&
        [TaskType.instalation, TaskType.service, TaskType.complaint].includes(
          data.type
        ) && (
          <div className="grid grid-cols-2 gap-5 my-2">
            <a
              target="_blank"
              href={`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/${data.id}/work-completion-report/download?auth=${user?.token}`}
              className=""
            >
              <div className="bg-blue-50 text-blue-500 border-blue-300 border rounded-lg px-3 py-1 text-center">
                Download WCR
              </div>
            </a>
            <a
              target="_blank"
              href={`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/${data.id}/boq-report/download?auth=${user?.token}`}
              className=""
            >
              <div className="bg-yellow-50 text-yellow-500 border-yellow-300 border rounded-lg px-3 py-1 text-center">
                Download BOQ
              </div>
            </a>
          </div>
        )}
      {data?.type == TaskType.survey && (
        <p className="text-purple-500 text-sm italic text-center my-2 animate-pulse">
          Upload Site Images to complete the task.
        </p>
      )}
      {data?.status == Ops_task_status.under_progress && (
        // <div className="fixed bottom-0 right-0 left-0 p-3 bg-white shadow-sm ">
        <button
          onClick={stop_task}
          className="flex justify-between bg-red-50 text-red-500 border-red-300 border rounded-lg text-center px-3 py-2 my-5  w-full font-bold   "
        >
          <p> STOP</p>
          <p>{data?.start_date && <TimerWidget start={data.start_date} />}</p>
        </button>
        // </div>
      )}

      <div className="h-20"></div>
      {/* {data?.type == TaskType.survey && (
        <button
          onClick={stop_task}
          className="flex justify-between bg-red-50 text-red-500 border-red-300 border rounded-lg   text-center px-3 py-2 w-full font-bold my-5"
        >
          <p> STOP</p>
          <p>{data.start_date && <TimerWidget start={data.start_date} />}</p>
        </button>
      )} */}

      {onDuty ? (
        <>
          {data?.status == Ops_task_status.pending ? (
            <div className="fixed bottom-0 right-0 left-0 p-3 bg-white shadow-sm ">
              <button
                onClick={start_task}
                className="bg-purple-50 text-purple-500 border-purple-300 border rounded-lg   text-center px-3 py-2 w-full font-bold"
              >
                START
              </button>
            </div>
          ) : (
            allDone &&
            !data?.verified && (
              <div className="fixed bottom-0 right-0 left-0 p-3 bg-white shadow-sm ">
                <Link to={`${url}/verification`} className=" ">
                  <div className="bg-green-50 text-green-500 border rounded-lg border-green-300 text-center px-3 py-2 w-full font-bold">
                    OTP Verification
                  </div>
                </Link>
              </div>
            )
          )}
        </>
      ) : (
        <>
          {" "}
          <div className="fixed bottom-0 right-0 left-0 p-3 bg-white shadow-sm ">
            <Link to={`${urls.operation_engineer_task}/checkin`} className=" ">
              <div className="bg-red-50 text-red-500 border rounded-lg border-red-300 text-center px-3 py-2 w-full font-bold animate-pulse">
                You are OFF DUTY Click here to START DUTY
              </div>
            </Link>
          </div>
        </>
      )}

      {loading && <LoadingWidget loading={true} />}
    </>
  );
};
