import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { AttachmentModel } from "../../../../service/models/attachment_modal";
import {
  ComplaintCommentModel,
  ComplaintLogModel,
  Complaintmodel,
} from "../../../../service/models/complaint_model";
import { CustomerDetailModel } from "../../../../service/models/orders/customer_detail_model";
import { getCityStateApi } from "../../../../service/repos/address/address_repo";

import {
  CreateComplaint,
  CreateComplaintComment,
  GetComplaint,
  UpdateComplaint,
} from "../../../../service/repos/support/complaint_repo";

import { DeviceIdRegex } from "../../../../utils/constant";
import {
  ACTION,
  complaintStatus,
  complaint_by,
  complaint_related_to,
  MODULE,
  PriorityType,
  SubDepartments,
  ComplaintTicketType,
} from "../../../../utils/enums";
import { isValidJson } from "../../../../utils/formDataToJson";
import { compressImage } from "../../../../utils/image_compressor";
import { urls } from "../../../../utils/urls";
import ImageTile from "../../../components/common/image_tile";
import PdfTile from "../../../components/common/pdf_tile";

import LoadingWidget from "../../../components/loading_spinner";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../new_components/common/drawer_modal";
import FileDropZone from "../../../new_components/common/file_dropdown";
import ModalDialog from "../../../new_components/common/modal";
import EmployeeSearchWidget from "../../../new_components/emp_search_widget";
import InventoryDropDown from "../../new_ops/components/inventory_dropdown";
import OrderDropDown from "../../orders/components/order_dropdown";
import AttachmentWidget from "../detail/components/attaments_widget";
// import AttachmentWidget from "./components/attaments_widget";
import CommentWidget from "./components/comment_widget";
import LogsWidget from "./components/logs_widget";
import OrdersListWidget from "./components/order_list";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";
import {
  AddressModel,
  getDefaultAddressValue,
  getFullAddress,
} from "../../../../service/models/address_model";
import customer from "../../../../service/schema/customer";
import AddressPickerWidget from "../../../new_components/common/location/AddressPicker";

interface CreateComplaintPageProps {
  location: any;
}
const defaultValue = (): Complaintmodel => {
  return {
    category: "",
    address: getDefaultAddressValue(),
    id: "",
    related_to: "",
    pre_post_delivery: "",
    status: complaintStatus.pending,
    complaint_by: "",
    complainant_name: "",
    complainant_mobile: "",
    city: "",
    state: "",
    pincode: 0,
    res_dep: "",
    res_person: "",
    subject: "",
    description: "",
    next_action_date: Date.now(),
    complaint_date: Date.now(),
    created_by: 0,
    updated_by: 0,
    created_by_name: "",
    updated_by_name: "",
    res_person_name: "",
    active: true,
    priority: PriorityType.low,
    ticket_type: ComplaintTicketType.incident,
  };
};
const CreateComplaintPage: FunctionComponent<CreateComplaintPageProps> = (
  props
) => {
  const [diabled, setDisabled] = useState(true);
  const { showToast } = useToast();
  const { showModal, onClose } = useModal();
  const { user, isAuthorised } = useAuth();
  const query = new URLSearchParams(props.location.search);
  const id = query.get("id");

  const [complaint, setComplaint] = useState<Complaintmodel>({
    ...defaultValue(),
  });
  const [preVal, setPreVal] = useState<Complaintmodel>({
    ...defaultValue(),
  });

  const [dep, setDep] = useState(complaint.res_dep ?? "");
  // const [pincode, setPincode] = useState<number>();
  const [showOrders, setShowOrders] = useState(false);
  const [edit, setEdit] = useState(id ? false : true);
  const [showAttachments, setShowAttachments] = useState(false);
  const [files, setFile] = useState<File[]>([]);
  // const [selectedDeviceIds, setSelectedDeviceIds] = useState<
  //   { device_id: string; name: string }[]
  // >([]);
  const getComplaint = async () => {
    if (!id) return;
    setLoading(true);
    const res = await GetComplaint(id);

    if (res.success) {
      setDep(res.data.res_dep);
      const next_action_date = res.data.next_action_date
        ? moment(res.data.next_action_date, "YYYY-MM-DD HH:mm:ss").format(
            "YYYY-MM-DD"
          )
        : undefined;
      const complaint_date = res.data.complaint_date
        ? moment(res.data.complaint_date, "YYYY-MM-DD HH:mm:ss").format(
            "YYYY-MM-DD"
          )
        : undefined;

      setComplaint({ ...res.data, next_action_date, complaint_date });
      setPreVal({ ...res.data, next_action_date, complaint_date });
      // setPincode(res.data.pincode ?? "");
    }
    setLoading(false);
  };

  // const getCityState = async () => {
  //   setLoading(true);
  //   const res = await getCityStateApi(complaint.pincode!);

  //   if (res.success) {
  //     if (!res.data) {
  //       setLoading(false);

  //       showToast({ type: ToastType.error, text: "Invalid zip code !" });
  //       return;
  //     }
  //     const { city, state } = res.data;
  //     setComplaint((c) => ({
  //       ...c,
  //       city,
  //       state,
  //     }));
  //   } else showToast({ type: ToastType.error, text: res.error ?? "" });
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   if (isAuthorised(MODULE.COMPLAINT, [ACTION.UPDATE], authState.auth)) {
  //     setEdit(true);
  //   }
  //   getComplaint();
  // }, []);
  // useEffect(() => {
  //   getEmps();
  // }, [dep]);
  // useEffect(() => {
  //   if (complaint.pincode && `${complaint.pincode}`.length === 6)
  //     getCityState();
  // }, [complaint.pincode]);

  const submit = async (e: any) => {
    e.preventDefault();

    let ids = complaint.device_id ? complaint.device_id.split(",") : [];
    const validator = DeviceIdRegex;
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      id = id.trim();

      if (!validator.test(id)) {
        showToast({
          type: ToastType.warning,
          text: `device id : ${id} is not valid`,
        });
        return;
      }
      ids[i] = id;
    }

    complaint.device_id = `${ids}`.trim();
    if (complaint.complaint_by.toLowerCase() == "host" && !complaint.order_id) {
      return showToast({
        text: "Please select order id",
        type: ToastType.error,
      });
    }
    setLoading(true);
    let form = new FormData();

    // if (
    //   complaint.id &&
    //   complaint.status === complaintStatus.solved &&
    //   preVal.status !== complaintStatus.solved
    // ) {
    //   const lastComment = window.prompt("Enter the final resolution");
    //   if (lastComment) {
    //     const data: ComplaintCommentModel = {
    //       comment: lastComment,
    //       complaint_id: complaint.id!,
    //     };

    //     const res = await CreateComplaintComment(data);

    //     if (!res.success) {
    //       showToast({ type: ToastType.error, text: res.error ?? "" })
    //       return;
    //     }
    //   } else {
    //     setLoading(false);
    //     return;
    //   }
    // }
    // if (
    //   complaint.id &&
    //   complaint.status === complaintStatus.noted_for_future &&
    //   preVal.status !== complaintStatus.noted_for_future
    // ) {
    //   const lastComment = window.prompt("What is your final learning ?");
    //   if (lastComment) {
    //     const data: ComplaintCommentModel = {
    //       comment: lastComment,
    //       complaint_id: complaint.id!,
    //     };

    //     const res = await CreateComplaintComment(data);

    //     if (!res.success) {
    //       showToast({ type: ToastType.error, text: res.error ?? "" })
    //       return;
    //     }
    //   } else {
    //     setLoading(false);
    //     return;
    //   }
    // }

    form.append("data", JSON.stringify(complaint));
    if (files && files.length) {
      files.forEach((f) => {
        form.append("attachment", f);
      });
    }

    const res = id
      ? await UpdateComplaint({ ...complaint, id })
      : await CreateComplaint(form);

    if (res.success) {
      if (!id) setComplaint({ ...defaultValue() });

      showToast({
        type: ToastType.success,
        text: `Complaint ${
          id ? "Updated" : "Created"
        } successfylly complaint ID :  ${res.data.id}`,
      });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const [loading, setLoading] = useState(false);
  const [showLogs, setShowLogs] = useState<boolean>(false);
  const [showComments, setShowComments] = useState<boolean>(false);
  useEffect(() => {
    setDisabled(
      !isAuthorised({ module: MODULE.complaint, action: [ACTION.UPDATE] })
    );
  }, []);
  return (
    <div className="p-5 mb-5">
      {showComments && (
        <DrawerModal
          show={showComments}
          title={"Comments"}
          location={DrawerModalLoaction.right}
          onClose={() => {
            setShowComments(false);
          }}
        >
          <CommentWidget complaint_id={complaint!.id} />
        </DrawerModal>
      )}
      {showLogs && (
        <DrawerModal
          show={showLogs}
          title={"Log history"}
          location={DrawerModalLoaction.right}
          onClose={() => {
            setShowLogs(false);
          }}
        >
          <LogsWidget complaint_id={complaint!.id} />
        </DrawerModal>
      )}
      {showAttachments && (
        <ModalDialog
          show={showAttachments}
          title="Attachments"
          onClose={() => setShowAttachments(false)}
        >
          <AttachmentWidget complaint_id={complaint.id} />
        </ModalDialog>
      )}
      {/* {showOrders && (
        <ModalDialog
          title="Select Order related to complaint"
          show={showOrders}
          onClose={() => setShowOrders(false)}
        >
          <OrdersListWidget
            onSelect={(d) => {
              setComplaint((o) => ({
                ...o,
                complaint_by: "Host",
                oid: d.oid,
                order_id: d.order_id,
                complainant_name: d.firstName + " " + (d.lastName ?? ""),
                address: d.line1 + " " + (d.line2 ?? ""),

                complainant_mobile: d.mobile,
                city: d.city,
                state: d.state,
                pincode: d.pincode,
              }));

              setPincode(d.pincode);
              setShowOrders(false);
            }}
          /> 
        </ModalDialog>
      )} */}
      <div className="flex justify-center my-3">
        <h1 className="text-2xl font-semibold">
          {id ? `Complaint ID : ${id}` : "Create Complaint"}
        </h1>
      </div>

      <form onSubmit={submit} autoComplete="new-password">
        {complaint.order_id && (
          <div className="text-sm">
            <NavLink target="_blank" to={"/orders/" + complaint.order_id}>
              <div className="mx-1 cursor-pointer flex   text-blue-400">
                Goto : {complaint.order_id}
              </div>
            </NavLink>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 ">
          <div className="grid grid-cols-1 md:grid-cols-2 border rounded-md gap-2   p-5 bg-white">
            <div className="flex flex-col">
              <label htmlFor="complaint_by" className="mr-2 text-sm">
                Complaint by*
              </label>
              <select
                disabled={!edit}
                required
                value={complaint.complaint_by}
                onChange={(e) => {
                  setComplaint((c) => ({
                    ...c,
                    complaint_by: e.target.value,
                  }));
                }}
                name="complaint_by"
                id="complaint_by"
                className="border px-2 py-1"
              >
                <option value="" disabled hidden>
                  Select
                </option>
                {complaint_by.map((d, i) => (
                  <option key={i} value={d}>
                    {d}
                  </option>
                ))}
              </select>
              {complaint.complaint_by &&
                complaint_by.indexOf(complaint.complaint_by) === -1 && (
                  <p className="text-xs text-red-400">
                    Please reselect prev val {complaint.complaint_by}{" "}
                  </p>
                )}
            </div>
            <div className="flex flex-col">
              <label htmlFor="order_id" className="mr-2 text-sm">
                Order
              </label>
              <OrderDropDown
                order_id={complaint.order_id}
                onSelect={function (d: {
                  order_id: string;
                  order_date: string;
                  total: number;
                  customer: CustomerDetailModel;
                }): void {
                  setComplaint((o) => ({
                    ...o,
                    complaint_by: "Host",

                    order_id: d.order_id,
                    complainant_name: (
                      d.customer.firstName +
                      " " +
                      (d.customer.lastName ?? "")
                    ).trim(),
                    complainant_mobile: d.customer.mobile,
                    address: {
                      ...d.customer.address,
                      full_address:
                        d.customer.address.full_address ??
                        getFullAddress(d.customer.address),
                    },
                    // address:
                    //   d.customer.address.line1 +
                    //   " " +
                    //   (d.customer.address.line2 ?? ""),

                    // city: d.customer.address.city,
                    // state: d.customer.address.state,
                    // pincode: d.customer.address.pincode,
                  }));
                }}
                placeholder="Search order"
                withBtn={false}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="complaint_related_to" className="mr-2 text-sm">
                Complaint ralated to*
              </label>
              <select
                disabled={!edit}
                required
                value={complaint.related_to}
                onChange={(e) => {
                  setComplaint((c) => ({ ...c, related_to: e.target.value }));
                }}
                name="related_to"
                id="complaint_related_to"
                className="border px-2 py-1"
              >
                <option value="" disabled hidden>
                  Select
                </option>
                {complaint_related_to.map((d, i) => (
                  <option key={i} value={d}>
                    {d}
                  </option>
                ))}
              </select>
              {complaint.related_to &&
                complaint_related_to.indexOf(complaint.related_to) === -1 && (
                  <p className="text-xs text-red-400">
                    Please reselect prev val {complaint.related_to}{" "}
                  </p>
                )}
            </div>
            <div className="">
              <label htmlFor="complaint_related_to" className="mr-2 text-sm">
                Pre/Post Delivery*
              </label>
              <div className=" border p-1">
                <input
                  disabled={!edit}
                  onChange={(e) => {
                    setComplaint((c) => ({
                      ...c,
                      pre_post_delivery: e.target.name,
                    }));
                  }}
                  checked={
                    complaint.pre_post_delivery
                      ? complaint.pre_post_delivery.toLowerCase() ===
                        "pre delivery".toLowerCase()
                      : false
                  }
                  type="radio"
                  id="pre"
                  name="Pre Delivery"
                  value="Pre delivery"
                  className="mx-2"
                />
                <label htmlFor="pre">Pre</label>
                <input
                  disabled={!edit}
                  checked={
                    complaint.pre_post_delivery
                      ? complaint.pre_post_delivery.toLowerCase() ===
                        "post Delivery".toLowerCase()
                      : false
                  }
                  onChange={(e) => {
                    setComplaint((c) => ({
                      ...c,
                      pre_post_delivery: e.target.name,
                    }));
                  }}
                  type="radio"
                  id="post"
                  name="Post Delivery"
                  value="Post Delivery"
                  className="mx-2"
                />
                <label htmlFor="post">Post</label>
              </div>{" "}
            </div>
            <div className="flex flex-col">
              <label htmlFor="res_dep" className="mr-2 text-sm">
                Reponsible department*
              </label>
              <select
                disabled={!edit}
                required
                value={complaint.res_dep}
                onChange={(e) => {
                  setDep(e.target.value);
                  setComplaint((c) => ({
                    ...c,
                    res_dep: e.target.value,
                  }));
                }}
                name="res_dep"
                id="res_dep"
                className="border px-2 py-1"
              >
                <option value="" disabled hidden>
                  Select
                </option>
                {Object.keys(SubDepartments).map((k, i) => (
                  <optgroup label={k}>
                    {SubDepartments[k].map((sub_dep, i) => (
                      <option value={`${k}-${sub_dep}`}>{`${sub_dep}`}</option>
                    ))}
                  </optgroup>
                ))}
                {/* {Departments.map((d, i) => (
                  <option value={d} key={i}>
                    {d}
                  </option>
                ))} */}
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="res_person" className="mr-2 text-sm">
                Reponsible person (Primary)*
              </label>

              {!loading && (
                <EmployeeSearchWidget
                  withBtn={false}
                  onSelect={(em) => {
                    setComplaint((c) => ({
                      ...c,
                      res_person: em.uid!,
                      res_person_name: em.first_name + " " + em.last_name,
                    }));
                  }}
                  emp={
                    complaint.res_person
                      ? {
                          uid: complaint.res_person,
                          name: complaint.res_person_name!,
                        }
                      : undefined
                  }
                  disabled={!edit}
                  placeholder="Primary person"
                />
              )}
            </div>
            <div className="flex flex-col">
              <label htmlFor="res_person" className="mr-2 text-sm">
                Reponsible person (Secondary)
              </label>

              {!loading && (
                <EmployeeSearchWidget
                  withBtn={false}
                  onSelect={(em) => {
                    setComplaint((c) => ({
                      ...c,
                      res_person_sec_id: em.uid!,
                      res_person_sec_name: em.first_name + " " + em.last_name,
                    }));
                  }}
                  emp={
                    complaint.res_person_sec_id
                      ? {
                          uid: complaint.res_person_sec_id,
                          name: complaint.res_person_sec_name ?? "",
                        }
                      : undefined
                  }
                  disabled={!edit}
                  placeholder="Secondary person "
                />
              )}
            </div>{" "}
            <div className="flex flex-col">
              <label htmlFor="category" className="mr-2 text-sm">
                Category *
              </label>
              <select
                required
                disabled={!edit}
                value={complaint.category}
                onChange={(e) => {
                  setDep(e.target.value);
                  setComplaint((c) => ({
                    ...c,
                    category: e.target.value,
                  }));
                }}
                name="category"
                id="category"
                className="border px-2 py-1"
              >
                <option value="" disabled hidden>
                  Select
                </option>
                {["Fleets/HUB", "RWA", "Individual", "Others"].map((c, i) => (
                  <option value={c} key={i}>
                    {c}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="ticket_type" className="mr-2 text-sm">
                Ticket Type *
              </label>
              <select
                required
                disabled={!edit}
                value={complaint.ticket_type}
                onChange={(e) => {
                  setDep(e.target.value);
                  setComplaint((c) => ({
                    ...c,
                    ticket_type: e.target.value,
                  }));
                }}
                name="ticket_type"
                id="ticket_type"
                className="border px-2 py-1"
              >
                <option value="" disabled hidden>
                  Select
                </option>
                {Object.values(ComplaintTicketType).map((c, i) => (
                  <option value={c} key={i}>
                    {c}
                  </option>
                ))}
              </select>
            </div>
            {/* <div>
              <InventoryDropDown
                selected={selectedDeviceIds}
                onSelect={(v) => setSelectedDeviceIds((o) => [...o, v])}
              />
              <div className="">
                <div className="flex flex-wrap gap-2 col-span-2 text-xs border-t py-1">
                  {selectedDeviceIds.map((d, i) => (
                    <div className="border rounded-3xl p-1 flex gap-1" key={i}>
                      <p>
                        {d.device_id}{" "}
                        <span style={{ fontSize: 8 }}>({d.name})</span>
                      </p>
                      <span
                        onClick={() => {
                          setSelectedDeviceIds((o) =>
                            o.filter((v, j) => j !== i)
                          );
                        }}
                        className="hover:text-red-500"
                      >
                        <Icon path={mdiClose} size="16"></Icon>
                      </span>
                    </div>
                  ))}
                  {complaint.device_id && !isValidJson(complaint.device_id) && (
                    <div className="">
                      <p>{complaint.device_id}</p>
                      <p className="text-xs text-red-400">
                        Please select the above device ids from suggestions and
                        update.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
            <div className="flex flex-col">
              <label htmlFor="device_id" className="mr-2 text-sm">
                Device ID
              </label>
              <div className="">
                <textarea
                  disabled={!edit}
                  value={complaint.device_id}
                  onChange={(e) => {
                    setComplaint((c) => ({
                      ...c,
                      device_id: e.target.value,
                    }));
                  }}
                  className="border px-2 py-1 w-full"
                  name="device_id"
                  id="device_id"
                  placeholder="Device ID"
                />
                <div className="text-xs">
                  * IDs should be separated by comma(,){" "}
                </div>{" "}
              </div>
            </div>{" "}
          </div>
          <div className="border rounded-md p-5  bg-white">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-end ">
              <div className="flex flex-col">
                <label htmlFor="complainant_name" className="mr-2 text-sm">
                  Complainant name*
                </label>
                <input
                  disabled={!edit}
                  required
                  value={complaint.complainant_name}
                  onChange={(e) => {
                    setComplaint((c) => ({
                      ...c,
                      complainant_name: e.target.value,
                    }));
                  }}
                  className="border px-2 py-1"
                  type="text"
                  name="complainant_name"
                  id="complainant_name"
                  placeholder="name"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="complainant_number" className="mr-2 text-sm">
                  Complainant Contact*
                </label>
                <input
                  disabled={!edit}
                  required
                  value={complaint.complainant_mobile}
                  onChange={(e) => {
                    // if (
                    //   e.target.value.length === 10 &&
                    //   complaint.complaint_by === "Host"
                    // ) {

                    //   // searchHost(e.target.value);
                    // }
                    if (e.target.value.length <= 10)
                      setComplaint((c) => ({
                        ...c,
                        complainant_mobile: e.target.value,
                      }));
                  }}
                  className="border px-2 py-1"
                  type="number"
                  pattern="[1-9]{1}[0-9]{9}"
                  title="Please enter a valid 10 digit mobile number"
                  name="complainant_mobile"
                  id="complainant_number"
                  placeholder="Contact number"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="alt_number" className="mr-2 text-sm">
                  Alternative Contact
                </label>
                <input
                  disabled={!edit}
                  value={complaint.alt_mobile}
                  onChange={(e) => {
                    if (e.target.value.length <= 10)
                      setComplaint((c) => ({
                        ...c,
                        alt_mobile: e.target.value,
                      }));
                  }}
                  className="border px-2 py-1"
                  type="number"
                  pattern="[1-9]{1}[0-9]{9}"
                  title="Please enter a valid 10 digit mobile number"
                  name="alt_mobile"
                  id="alt_number"
                  placeholder="Alternative contact number"
                />
              </div>
              <div className="flex    flex-col col-span-2">
                <label htmlFor="address-picker">Address</label>
                <div
                  onClick={() => {
                    showModal({
                      type: ModalType.drawer,
                      title: "Address Picker",
                      alignment: ModalAlignment.right,
                      container(id) {
                        return (
                          <div className="  bg-gray-100 p-1 h-full w-full">
                            <AddressPickerWidget
                              data={complaint.address}
                              location_required={false}
                              onSubmit={function (
                                _address: AddressModel
                              ): void {
                                setComplaint((o) => ({
                                  ...o!,
                                  address: { ...o!.address, ..._address },
                                }));
                                onClose(id);
                              }}
                              onCancel={function (): void {
                                onClose(id);
                              }}
                            />
                          </div>
                        );
                      },
                    });
                  }}
                  className="border rounded px-2 p-1 focus:outline-none h-32  "
                >
                  {complaint.address.full_address ?? "Full address"}
                </div>
              </div>
              {/* <div className="flex flex-col">
              <label htmlFor="city" className="mr-2 text-sm">
                City/District*
              </label>
              <input
                disabled
                value={complaint.city}
                onClick={(e) => {
                  showToast({
                    type: ToastType.error,
                    text: "Please enter 6 digit postal code",
                  });
                }}
                onChange={(e) => {
                  setComplaint((c) => ({
                    ...c,
                    city: e.target.value,
                  }));
                }}
                className="border px-2 py-1"
                type="text"
                name="city"
                id="city"
                placeholder="City"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="state" className="mr-2 text-sm">
                State*
              </label>
              <input
                disabled
                value={complaint.state}
                onChange={(e) => {
                  setComplaint((c) => ({
                    ...c,
                    state: e.target.value,
                  }));
                }}
                onClick={(e) => {
                  showToast({
                    type: ToastType.error,
                    text: "Please enter 6 digit postal code",
                  });
                }}
                className="border px-2 py-1"
                type="text"
                name="state"
                id="state"
                placeholder="State"
                required
              />
            </div>
            <div className="flex items-end w-full">
              <div className="flex flex-col w-full">
                <label htmlFor="pincode" className="mr-2 text-sm">
                  Postal code*
                </label>
                <input
                  disabled={!edit}
                  type="number"
                  name="pincode"
                  placeholder="Postal code"
                  pattern="[0-9]{6}"
                  title="Please enter a 6 digit pincode"
                  value={complaint.pincode}
                  onChange={(e) => {
                    const t = parseInt(e.target.value);
                    if (e.target.value.length <= 6)
                      setComplaint((o) => ({ ...o!, pincode: t }));

                    // setComplaint((c) => ({
                    //   ...c,
                    //   pincode: parseInt(e.target.value),
                    // }));
                  }}
                  className="border px-2 py-1"
                  id="pincode"
                  required
                />
              </div>
              <div className="p-1">
                <svg
                  onClick={() => {
                    if (complaint.pincode?.toString().length === 6) {
                      getCityState();
                    } else {
                      showToast({
                        type: ToastType.error,
                        text: "Please enter 6 digit postal code",
                      });
                    }
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
            <p className="text-xs text-gray-400">
              Please enter pincode to get city/district and state.
            </p> */}
            </div>
          </div>
        </div>
        <div className="grid grid-col-1 md:grid-cols-3 pt-5 gap-3 ">
          <div className="grid grid-cols-1 p-5 col-span-2 border rounded-sm  bg-white ">
            <div className="flex flex-col">
              <label htmlFor="subject" className="mr-2">
                Subject*
              </label>
              <input
                disabled={!edit}
                required
                value={complaint.subject}
                onChange={(e) => {
                  setComplaint((c) => ({
                    ...c,
                    subject: e.target.value,
                  }));
                }}
                className="border px-2 py-1 text-sm"
                type="text"
                name="subject"
                id="subject"
                placeholder="Maximum 90 characters"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="description" className="mr-2 text-sm">
                Description*
              </label>
              <textarea
                disabled={!edit}
                required
                rows={4}
                value={complaint.description}
                onChange={(e) => {
                  setComplaint((c) => ({
                    ...c,
                    description: e.target.value,
                  }));
                }}
                className="border px-2 py-1 text-sm"
                name="description"
                id="description"
                placeholder="Detailed description of issue"
              />
            </div>
          </div>
          <div className="">
            <div className=" grid grid-cols-1 border rounded-md gap-2 items-end p-5  bg-white">
              <div className="flex flex-wrap items-center  justify-between gap-3">
                <label htmlFor="active" className="whitespace-nowrap  text-sm">
                  Visibility
                </label>

                <select
                  className="border rounded text-sm w-48 h-8 focus:outline-none cursor-pointer"
                  name="active"
                  id="active"
                  disabled={!edit}
                  value={complaint.active ? "true" : "false"}
                  onChange={(e) => {
                    setComplaint((o) => ({
                      ...o,
                      active: e.target.value === "true",
                    }));
                  }}
                >
                  <option value="" hidden>
                    Select..
                  </option>
                  <option value="true">Active</option>
                  <option value="false">Closed</option>
                </select>
              </div>
              <div className="flex flex-wrap items-center  justify-between gap-3">
                <label htmlFor="status" className="mr-2 text-sm">
                  Status*
                </label>
                <select
                  disabled={!edit}
                  required
                  value={complaint.status}
                  onChange={(e) => {
                    setComplaint((c) => ({
                      ...c,
                      status: e.target.value,
                    }));
                  }}
                  name="status"
                  id="status"
                  className={` px-2 py-1 w-48  rounded border-2 focus:outline-none bg-yellow-100 `}
                >
                  <option value="" disabled hidden>
                    Select
                  </option>
                  {Object.values(complaintStatus).map((d) => (
                    <option value={d} className="bg-white">
                      {d}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-wrap items-center  justify-between gap-3">
                <label htmlFor="action" className="mr-2 text-sm">
                  Date*
                </label>
                <input
                  disabled={!edit}
                  required
                  value={moment(complaint.complaint_date).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setComplaint((c) => ({
                      ...c,
                      complaint_date:
                        new Date(e.target.value).getTime() -
                        1000 * 60 * 60 * 5.5,
                    }));
                  }}
                  type="date"
                  name="complaint_date"
                  id="action"
                  className="border px-2 py-1 w-48"
                />
              </div>
              <div className="flex flex-wrap  items-center  justify-between gap-3">
                <label htmlFor="action" className="mr-2 text-sm">
                  Due Date*
                </label>
                <input
                  min={moment().format("YYYY-MM-DD")}
                  required
                  disabled={!edit}
                  value={moment(complaint.next_action_date).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) => {
                    setComplaint((c) => ({
                      ...c,
                      next_action_date:
                        new Date(e.target.value).getTime() -
                        1000 * 60 * 60 * 5.5,
                    }));
                  }}
                  type="date"
                  name="next_action_date"
                  id="action"
                  className="border px-2 py-1 w-48"
                />
              </div>{" "}
              <div className="flex flex-wrap items-center  justify-between gap-3">
                <label htmlFor="priority" className="mr-2 text-sm">
                  Priority
                </label>
                <select
                  disabled={!edit}
                  required
                  value={complaint.priority}
                  onChange={(e) => {
                    setComplaint((c) => ({
                      ...c,
                      priority: e.target.value,
                    }));
                  }}
                  name="priority"
                  id="priority"
                  className={` px-2 py-1 w-48  rounded border-2 focus:outline-none  ${
                    complaint.priority === PriorityType.low
                      ? "bg-yellow-400"
                      : ""
                  } `}
                >
                  <option value="" disabled hidden>
                    Select
                  </option>
                  {Object.values(PriorityType).map((d) => (
                    <option value={d} className="bg-white">
                      {d}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {complaint.id && (
          <div className="flex  my-5  gap-3">
            <button
              type="button"
              onClick={() => setShowComments(true)}
              className="border rounded px-2 py-1"
            >
              Comment
            </button>
            <button
              type="button"
              onClick={() => setShowLogs(true)}
              className="border rounded px-2 py-1"
            >
              History
            </button>
            <button
              type="button"
              onClick={() => setShowAttachments(true)}
              className={
                " rounded border px-3 py-1 hover:bg-black hover:text-white  "
              }
            >
              Attachments
            </button>
            {!diabled && (
              <button
                type="button"
                onClick={() => setEdit(true)}
                className={
                  " rounded border px-3 py-1 hover:bg-black hover:text-white  " +
                  (edit ? "hidden" : "block")
                }
              >
                Edit
              </button>
            )}
          </div>
        )}
        {!complaint.id && (
          <div className="my-5">
            <p className=" text-sm">Attachments</p>
            <div className="grid grid-cols-3 mb-10">
              <div className="">
                <FileDropZone
                  onChange={async (files) => {
                    if (files.length > 0) {
                      // const encoded = await fileToBase64(files[0]);
                      const temp: File[] = [];
                      for (let index = 0; index < files.length; index++) {
                        const file = files[index];
                        if (file.type.startsWith("image")) {
                          // setLoading(true);
                          const comFile = await compressImage(file);

                          if (comFile.size > 3000000) {
                            showToast({
                              type: ToastType.error,
                              text: "Please upload file size less than 3 mb",
                            });
                            return;
                          }
                          temp.push(comFile);
                          // setLoading(false);
                        } else {
                          if (file.size > 3000000) {
                            showToast({
                              type: ToastType.error,
                              text: "Please upload file size less than 3 mb",
                            });
                            return;
                          }
                          temp.push(file);
                        }
                      }
                      setFile((o) => [...o, ...temp]);
                    }
                  }}
                  type="image/*,application/pdf"
                  multiSelect={true}
                />
              </div>
              {files.length > 0 && (
                <div className="flex flex-wrap col-span-2">
                  {files.map((file, i) => (
                    <div className="">
                      {file.type.startsWith("image") ? (
                        <ImageTile url={URL.createObjectURL(file)} />
                      ) : (
                        <PdfTile url={URL.createObjectURL(file)} />
                      )}
                      <div className="flex justify-end">
                        <p
                          className="cursor-pointer text-blue-400"
                          onClick={() => {
                            files.splice(i, 1);
                            setFile([...files]);
                          }}
                        >
                          delete
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {complaint.id && (
          <p className="flex gap-5 text-sm my-3">
            <span>
              {" "}
              Created by :<b>{complaint.created_by_name ?? "--"}</b>
              <span className="text-xs mx-2">
                {moment(complaint.created_at, "YYYY-MM-DD hh:mm:ss").format(
                  "D MMM YYYY HH:MM a"
                )}
              </span>
            </span>
            <span>
              {" "}
              Updated by :<b>{complaint.updated_by_name ?? "--"}</b>
              <span className="text-xs mx-2">
                {moment(complaint.updated_at, "YYYY-MM-DD hh:mm:ss").format(
                  "D MMM YYYY HH:MM a"
                )}
              </span>
            </span>
          </p>
        )}

        {edit && (
          <div className="flex justify-center mt-3 mb-5 gap-5  fixed -bottom-6 left-52 bg-white p-3 right-0 ">
            {complaint.id && (
              <button
                onClick={() => {
                  setEdit(false);
                  setComplaint(preVal);
                }}
                type="button"
                className="border bg-gray-100  flex gap-2 hover:bg-black hover:text-white rounded items-center px-3 py-1"
              >
                Cancel
              </button>
            )}
            <button className="border bg-gray-100  flex gap-2 hover:bg-black hover:text-white rounded items-center px-3 py-1">
              {id ? "Update" : "Create"}
            </button>
          </div>
        )}
      </form>
      <hr />

      <LoadingWidget loading={loading} />
    </div>
  );
};

export default CreateComplaintPage;
