import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  fetchGet,
  fetchPut,
  fetchPost,
} from "../../../../../service/api_client";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import { currencyFormat } from "../../../../../utils/orders_utils";
import { LedgerEntryModel } from "../../../../accounts/models/common_model";
import {
  ItemMovementModel,
  stockJournalDefaultValue,
  StockJournalModel,
} from "../../../models/inventory_voucher";
import ItemRow from "./components/item_row";
import TaxEntryRow, { getItemTaxAmount } from "./components/tax_entry_row";

interface StockJournalProps {}

const StockJournal: FunctionComponent<StockJournalProps> = () => {
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/transfer`;
  const [loading, setLoading] = useState(false);
  const [addNewId1, setAddNewId1] = useState(Math.random() * 1000);
  const [addNewId2, setAddNewId2] = useState(Math.random() * 1000);
  const [addNewTaxId1, setAddNewTaxId1] = useState(Math.random() * 1000);
  const [addNewTaxId2, setAddNewTaxId2] = useState(Math.random() * 1000);
  const { showToast } = useToast();

  const [data, setData] = useState<StockJournalModel>({
    ...stockJournalDefaultValue,
  });
  useEffect(() => {
    // setData(props.location.state || stockManufacturingDefaultValue);
    if (id) getVoucher(id);
  }, []);
  const getVoucher = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const submit = async () => {
    if (!data.source?.length)
      return showToast({
        type: ToastType.error,
        text: "Please add source component consumption",
      });
    if (!data.destination?.length)
      return showToast({
        type: ToastType.error,
        text: "Please add destination product",
      });

    setLoading(true);

    const res = id
      ? await fetchPut(url + "/" + id, data)
      : await fetchPost(url, data);
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      // props.onClose(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  return (
    <>
      <div className=" mt-2 px-6">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Material Transfer
          </div>
          <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>{" "}
            <input
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div>
        </div>
        {/* <div className="flex justify-center text-lg font-semibold">
          Transfer of material
        </div> */}
        <div className="grid grid-cols-2  gap-2 mt-5 ">
          <div className="flex flex-col gap-5">
            <div className="">
              <h3 className="text-sm font-bold">Source(consumption)</h3>
              <div className="bg-white rounded flex flex-col gap-1 h-96 overflow-auto">
                <div className="grid grid-cols-7 gap-1 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t sticky top-0">
                  <div className="col-span-2">Particular</div>
                  <div className=" ">Warehouse</div>
                  <div className="text-center">Qty</div>
                  <div className="text-right">Rate</div>
                  <div className="text-right">Amount</div>
                </div>
                {data.source?.map((comp) => {
                  return (
                    <ItemRow
                      component={comp}
                      edit={false}
                      disabled={false}
                      key={comp.id}
                      onDelete={(d) => {
                        setData((o) => {
                          const source = [
                            ...(o.source || []).filter((it) => it.id !== d.id),
                          ];
                          const source_taxes = o.source_taxes.map((t, i) => {
                            t.amount = getItemTaxAmount({
                              items: source,
                              ledgerTaxDetail: t.ledger!.tax_detail!,
                            });

                            return { ...t };
                          });
                          let source_tax = source_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const source_sub_total = source.reduce(
                            (pv, val) => pv + val.bill_unit_no * val.price,
                            0
                          );
                          const source_total =
                            source_sub_total + (source_tax ?? 0);

                          return {
                            ...o,
                            source,
                            source_sub_total,
                            source_taxes,
                            source_tax,
                            source_total,
                          };
                        });
                      }}
                      onSubmit={function (d: ItemMovementModel): void {
                        setData((o) => {
                          const source = [
                            ...(o.source || []).map((it) => {
                              if (it.id === d.id) return d;

                              return it;
                            }),
                          ];
                          const source_taxes = o.source_taxes.map((t, i) => {
                            t.amount = getItemTaxAmount({
                              items: source,
                              ledgerTaxDetail: t.ledger!.tax_detail!,
                            });

                            return { ...t };
                          });
                          let source_tax = source_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const source_sub_total = source.reduce(
                            (pv, val) => pv + val.bill_unit_no * val.price,
                            0
                          );
                          const source_total =
                            source_sub_total + (source_tax ?? 0);

                          return {
                            ...o,
                            source,
                            source_sub_total,
                            source_taxes,
                            source_tax,
                            source_total,
                          };
                        });
                      }}
                    />
                  );
                })}
                <ItemRow
                  key={addNewId1}
                  onSubmit={function (data: ItemMovementModel): void {
                    data.id = (Math.random() * 1000).toString();
                    setData((o) => {
                      const source = [...(o.source || []), data];
                      const source_taxes = o.source_taxes.map((t, i) => {
                        t.amount = getItemTaxAmount({
                          items: source,
                          ledgerTaxDetail: t.ledger!.tax_detail!,
                        });

                        return { ...t };
                      });
                      let source_tax = source_taxes.reduce(
                        (pv, val) => pv + val.amount,
                        0
                      );
                      const source_sub_total = source.reduce(
                        (pv, val) => pv + val.bill_unit_no * val.price,
                        0
                      );
                      const source_total = source_sub_total + (source_tax ?? 0);

                      return {
                        ...o,
                        source,
                        source_sub_total,
                        source_taxes,
                        source_tax,
                        source_total,
                      };
                    });
                    setAddNewId1(Math.random() * 1000);
                  }}
                  edit={true}
                  disabled={false}
                />
              </div>
            </div>

            <div className="">
              <h3 className="text-sm font-bold">Taxes</h3>
              <div className="bg-white rounded flex flex-col gap-1">
                <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                  <div className="col-span-2">Particular</div>
                  <div className="text-center">Amount</div>
                </div>
                {data.source_taxes.map((tax, i) => (
                  <TaxEntryRow
                    key={tax.amount}
                    entry={tax}
                    items={[...(data.source || [])]}
                    onDelete={(d) => {
                      setData((o) => {
                        const source_taxes = [...(o.source_taxes || [])].filter(
                          (i) => i.id !== d.id
                        );
                        let source_tax = source_taxes.reduce(
                          (pv, val) => pv + val.amount,
                          0
                        );
                        const source_total =
                          o.source_sub_total + (source_tax ?? 0);
                        return {
                          ...o,
                          source_taxes,
                          source_tax,
                          source_total,
                        };
                      });
                    }}
                    onSubmit={function (d: LedgerEntryModel): void {
                      setData((o) => {
                        const source_taxes = [
                          ...(o.source_taxes || [[]]).map((t) => {
                            if (t.id === d.id) {
                              return { ...d };
                            }
                            return { ...t };
                          }),
                        ].map((t, i) => {
                          t.amount = getItemTaxAmount({
                            items: data.source || [],
                            ledgerTaxDetail: t.ledger!.tax_detail!,
                          });

                          return { ...t };
                        });
                        let source_tax = source_taxes.reduce(
                          (pv, val) => pv + val.amount,
                          0
                        );
                        const source_total =
                          o.source_sub_total + (source_tax ?? 0);
                        return {
                          ...o,
                          source_taxes,
                          source_tax,
                          source_total,
                        };
                      });
                    }}
                    edit={false}
                    disabled={false}
                  />
                ))}
                <TaxEntryRow
                  key={addNewTaxId1}
                  items={[...(data.source || [])]}
                  onSubmit={function (d: LedgerEntryModel): void {
                    d.id = (Math.random() * 1000).toString();
                    setData((o) => {
                      const source_taxes = [...(o.source_taxes || [[]]), d].map(
                        (t, i) => {
                          if (t.ledger!.tax_detail)
                            t.amount = getItemTaxAmount({
                              items: data.source || [],
                              ledgerTaxDetail: t.ledger!.tax_detail!,
                            });

                          return { ...t };
                        }
                      );
                      let source_tax = source_taxes.reduce(
                        (pv, val) => pv + val.amount,
                        0
                      );
                      const source_total =
                        o.source_sub_total + (source_tax ?? 0);
                      return {
                        ...o,
                        source_taxes,
                        source_tax,
                        source_total,
                      };
                    });
                    setAddNewTaxId1(Math.random() * 1000);
                  }}
                  edit={true}
                  disabled={false}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5 items-start justify-start">
            <div className="">
              <h3 className="text-sm font-bold">Destination(production)</h3>
              <div className="bg-white rounded flex flex-col gap-1 h-96 overflow-auto">
                <div className="grid grid-cols-7 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t  sticky top-0">
                  <div className="col-span-2">Particular</div>
                  <div className=" ">Warehouse</div>
                  <div className="text-center">Qty</div>
                  <div className="text-right">Rate</div>
                  <div className="text-right">Amount</div>
                </div>
                {data.destination?.map((comp, i) => {
                  return (
                    <ItemRow
                      component={comp}
                      edit={false}
                      disabled={false}
                      key={i}
                      onDelete={(d) => {
                        setData((o) => {
                          const destination = [
                            ...(o.destination || []).filter(
                              (it) => it.id !== d.id
                            ),
                          ];
                          const destination_taxes = o.destination_taxes.map(
                            (t, i) => {
                              t.amount = getItemTaxAmount({
                                items: destination,
                                ledgerTaxDetail: t.ledger!.tax_detail!,
                              });

                              return { ...t };
                            }
                          );
                          let destination_tax = destination_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const destination_sub_total = destination.reduce(
                            (pv, val) => pv + val.bill_unit_no * val.price,
                            0
                          );
                          const destination_total =
                            destination_sub_total + (destination_tax ?? 0);

                          return {
                            ...o,
                            destination,
                            destination_sub_total,
                            destination_taxes,
                            destination_tax,
                            destination_total,
                          };
                        });
                      }}
                      onSubmit={function (d: ItemMovementModel): void {
                        setData((o) => {
                          const destination = [
                            ...(o.destination || []).map((it) => {
                              if (it.id === d.id) return d;

                              return it;
                            }),
                          ];
                          const destination_taxes = o.destination_taxes.map(
                            (t, i) => {
                              t.amount = getItemTaxAmount({
                                items: destination,
                                ledgerTaxDetail: t.ledger!.tax_detail!,
                              });

                              return { ...t };
                            }
                          );
                          let destination_tax = destination_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const destination_sub_total = destination.reduce(
                            (pv, val) => pv + val.bill_unit_no * val.price,
                            0
                          );
                          const destination_total =
                            destination_sub_total + (destination_tax ?? 0);

                          return {
                            ...o,
                            destination,
                            destination_sub_total,
                            destination_taxes,
                            destination_tax,
                            destination_total,
                          };
                        });
                      }}
                    />
                  );
                })}
                <ItemRow
                  key={addNewId2}
                  onSubmit={function (data: ItemMovementModel): void {
                    data.id = (Math.random() * 1000).toString();
                    // setData((o) => ({
                    //   ...o,
                    //   destination: [...(o.destination || []), data],
                    // }));

                    setData((o) => {
                      const destination = [...(o.destination || []), data];
                      const destination_taxes = o.destination_taxes.map(
                        (t, i) => {
                          t.amount = getItemTaxAmount({
                            items: destination,
                            ledgerTaxDetail: t.ledger!.tax_detail!,
                          });

                          return { ...t };
                        }
                      );
                      let destination_tax = destination_taxes.reduce(
                        (pv, val) => pv + val.amount,
                        0
                      );
                      const destination_sub_total = destination.reduce(
                        (pv, val) => pv + val.bill_unit_no * val.price,
                        0
                      );
                      const destination_total =
                        destination_sub_total + (destination_tax ?? 0);

                      return {
                        ...o,
                        destination,
                        destination_sub_total,
                        destination_taxes,
                        destination_tax,
                        destination_total,
                      };
                    });
                    setAddNewId2(Math.random() * 1000);
                  }}
                  edit={true}
                  disabled={false}
                />
              </div>
            </div>
            <div className="">
              <h3 className="text-sm font-bold">Taxes</h3>
              <div className="bg-white rounded flex flex-col gap-1">
                <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                  <div className="col-span-2">Particular</div>
                  <div className="text-center">Amount</div>
                </div>
                {data.destination_taxes.map((tax, i) => {
                  return (
                    <TaxEntryRow
                      key={tax.amount}
                      entry={tax}
                      items={[...(data.destination || [])]}
                      onDelete={(d) => {
                        setData((o) => {
                          const destination_taxes = [
                            ...(o.destination_taxes || []),
                          ].filter((i) => i.id !== d.id);
                          let destination_tax = destination_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const destination_total =
                            o.destination_sub_total + (destination_tax ?? 0);
                          return {
                            ...o,
                            destination_taxes,
                            destination_tax,
                            destination_total,
                          };
                        });
                      }}
                      onSubmit={function (d: LedgerEntryModel): void {
                        setData((o) => {
                          const destination_taxes = [
                            ...(o.destination_taxes || [[]]).map((t) => {
                              if (t.id === d.id) {
                                return { ...d };
                              }
                              return { ...t };
                            }),
                          ].map((t, i) => {
                            t.amount = getItemTaxAmount({
                              items: data.destination || [],
                              ledgerTaxDetail: t.ledger!.tax_detail!,
                            });

                            return { ...t };
                          });
                          let destination_tax = destination_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const destination_total =
                            o.destination_sub_total + (destination_tax ?? 0);
                          return {
                            ...o,
                            destination_taxes,
                            destination_tax,
                            destination_total,
                          };
                        });
                      }}
                      edit={false}
                      disabled={false}
                    />
                  );
                })}
                <TaxEntryRow
                  key={addNewTaxId2}
                  items={[...(data.destination || [])]}
                  onSubmit={function (d: LedgerEntryModel): void {
                    d.id = (Math.random() * 1000).toString();
                    setData((o) => {
                      const destination_taxes = [
                        ...(o.destination_taxes || [[]]),
                        d,
                      ].map((t, i) => {
                        if (t.ledger!.tax_detail)
                          t.amount = getItemTaxAmount({
                            items: data.destination || [],
                            ledgerTaxDetail: t.ledger!.tax_detail!,
                          });

                        return { ...t };
                      });
                      let destination_tax = destination_taxes.reduce(
                        (pv, val) => pv + val.amount,
                        0
                      );
                      const destination_total =
                        o.destination_sub_total + (destination_tax ?? 0);
                      return {
                        ...o,
                        destination_taxes,
                        destination_tax,
                        destination_total,
                      };
                    });
                    setAddNewTaxId2(Math.random() * 1000);
                  }}
                  edit={true}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="text-sm flex justify-between">
            <div className="flex flex-col gap-2">
              <div className=" flex gap-5 justify-between">
                <div className="">Source subtotal:</div>
                <div className="">{data.source_sub_total}</div>
              </div>
              <div className=" flex gap-5 justify-between">
                <div className="">Source tax:</div>
                <div className="">{data.source_tax}</div>
              </div>
              <div className=" flex gap-5 justify-between">
                <div className="">Source total:</div>
                <div className="">{data.source_total}</div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className=" flex gap-5 justify-between">
                <div className="">Destination subtotal:</div>
                <div className="">{data.destination_sub_total}</div>
              </div>
              <div className=" flex gap-5 justify-between">
                <div className="">Destination tax:</div>
                <div className="">{data.destination_tax}</div>
              </div>
              <div className=" flex gap-5 justify-between">
                <div className="">Destination total:</div>
                <div className="">{data.destination_total}</div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex justify-end px-6 py-2">
          <button
            onClick={submit}
            className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm "
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default StockJournal;
