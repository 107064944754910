import { BaseModel } from "../../../service/models/base";
import { AttachmentModel } from "../../purchase_order/model/purchase_order_model";
import { LedgerModel } from "./ledger_model";
import { Currency } from "./party_model";
import { PaymentVoucherModel } from "./payment_model";
import { PurchaseVoucherModel } from "./purchase_model";
import { RecieptVoucherModel } from "./receipt_model";
import { SalesVoucherModel } from "./sale_model ";

export const voucherEntryType = {
  General: "General",
  Invoice: "Invoice",
};
export const PaymentEntryType = {
  General: "General",
  bill_payment: "Bill",
};

export const voucherType = {
  purchase: "Purchase",
  sales: "Sales",
  payment: "Payment",
  receipt: "Receipt",
  journal: "Journal",
  credit_note: "Credit note",
  debit_note: "Debit note",
  delivery_note: "Delivery note",
  stock_journal: "Stock journal",
  manufacturing: "Manufacturing",
};

export const accountTxnType = {
  debitor: "Dr",
  creditor: "Cr",
};

export interface LedgerEntryModel extends BaseModel {
  ledger_id?: string;
  ledger?: LedgerModel;
  voucher_id: string;
  voucher_type: string;
  voucher?:
    | PurchaseVoucherModel
    | SalesVoucherModel
    | PaymentVoucherModel
    | RecieptVoucherModel;
  date: Date;
  type: string;
  amount: number;
  rate?: number;
  // vendor_currency?: Currency;
  // vendor_amount?: number;
  // vendor_rate?: number;
}

export interface OrderDetail {
  order_id: string;
  po_no?: string;
  payment_terms: string;
  other_ref: string;
  delivery_terms: string;
}

export interface DispatchDetail {
  dispatch_doc_no: string;
  dispatch_through: string;
  destination: string;
  // billing_of_landing: string;
  // date: Date;
  vehicle_no: string;
  delivery_note?: string;
}
export interface VoucherModel extends BaseModel {
  voucher_type: string;
  date: Date;
  attachments: AttachmentModel[];
}
export const orderDetailDefailtValue: OrderDetail = {
  order_id: "",
  payment_terms: "",
  other_ref: "",
  delivery_terms: "",
};
export const dispatchDetailDefailtValue: DispatchDetail = {
  dispatch_doc_no: "",
  dispatch_through: "",
  destination: "",
  // billing_of_landing: "",
  // date: new Date(),
  vehicle_no: "",
};
