import {
  mdiCheck,
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiChevronUp,
  mdiChevronDown,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { fetchGet } from "../../../../../../service/api_client";
import { numberRegEx } from "../../../../../../utils/constant";
import ItemSearchField from "../../../../items/item_search_field";
import {
  itemMovementDefaultValue,
  ItemMovementModel,
} from "../../../../models/inventory_voucher";
import { ItemModel } from "../../../../models/item_model";
import WarehouseSearchField from "../../../../warehouse/warehouse_search_field";

interface ItemRowProps {
  component?: ItemMovementModel;
  onSubmit: (data: ItemMovementModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: ItemMovementModel) => void;
}

const ItemRow: FunctionComponent<ItemRowProps> = (props) => {
  const [data, setData] = useState<ItemMovementModel>({
    ...itemMovementDefaultValue,
  });
  const { showToast } = useToast();
  const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit } = props;

  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!data.item || data.price < 0 || !data.unit_no) {
      showToast({
        type: ToastType.warning,
        text: "Please select Item,price and Qty",
      });
      return;
    }
    onSubmit(data);
  };

  const getStockValue = async (item: ItemModel) => {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/${item.id}/value`;
    setLoading(true);
    const res = await fetchGet(url);
    if (res.success) {
      setData((o) => ({
        ...o,
        item: item,
        item_id: item.id,
        price: res.data.price,
      }));
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (props.component) setData({ ...props.component });
  }, [props.component]);

  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white   p-1  "
      >
        <div className="grid grid-cols-7 gap-1 items-center">
          <div className="col-span-2">
            <ItemSearchField
              disabled={!editable || props.disabled}
              value={data.item}
              onSelect={getStockValue}
              // onSelect={(d) => {

              // setData((old) => {
              //   return { ...old, item: d, item_id: d.id };
              // });
              // }}
            />
          </div>
          <div className="">
            <WarehouseSearchField
              disabled={!editable || props.disabled}
              value={data.warehouse}
              onSelect={(d) => {
                setData((old) => {
                  return { ...old, warehouse: d, warehouse_id: d.id };
                });
              }}
            />
          </div>

          <div className="grid grid-cols-4 gap-1 items-center">
            <input
              value={data?.unit_no}
              onChange={(e) => {
                const val = e.target.value.trim();
                if (!val || numberRegEx.test(val)) {
                  const value = Number(val || 0);
                  setData((ol) => ({
                    ...ol,
                    unit_no: value,
                    bill_unit_no: value,
                  }));
                }
              }}
              disabled={!editable || props.disabled}
              placeholder=""
              type="text"
              className="text-right  p-1 focus:outline-none border rounded col-span-3"
            />
            <div className="">{data.item?.unit?.symbol}</div>
          </div>
          <input
            value={data?.price}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setData((ol) => ({ ...ol, price: v }));
            }}
            disabled={!editable || props.disabled}
            placeholder=""
            type="text"
            className="text-right  p-1 focus:outline-none border rounded"
          />

          <input
            value={(data?.unit_no ?? 0) * (data?.price ?? 0)}
            disabled={true}
            placeholder=""
            type="text"
            className="text-right  p-1 focus:outline-none border rounded"
          />
          <div className="flex gap-2 items-center justify-end">
            {props.component ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setData(props.component!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(data);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.component && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>
        {/* {showMore && props.item && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.item.id}</p>
              <p>Extra : {rupeeFormat(props.item.addition_amt ?? 0)}</p>
              <p>Discount : {rupeeFormat(props.item.discount_amt ?? 0)}</p>
              <p>Paid : {rupeeFormat(props.item.paid ?? 0)}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.created_at
                      ? moment(props.item.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.updated_at
                      ? moment(props.item.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )} */}
      </form>
    </>
  );
};

export default ItemRow;
