import { BaseModel } from "../../../service/models/base";
import {
  AddressModel,
  addressDefaultValue,
} from "../../accounts/models/party_model";

export interface WarehouseGroupModel extends BaseModel {
  name: string;
  description?: String;
}
export interface WarehouseModel extends BaseModel {
  name: string;
  description?: String;
  group_id: string;
  group?: WarehouseGroupModel;
  address: AddressModel;
}

export const warehouseDefaultValue: WarehouseModel = {
  name: "",
  group_id: "",
  address: addressDefaultValue,
};
