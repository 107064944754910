import { FunctionComponent, useEffect, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { ACTION, MODULE } from "../../../utils/enums";
import {
  GetAnniversary,
  GetBirthdayToday,
} from "../../../service/repos/hrm/hrm_repo";
import { menuUrls, urls } from "../../../utils/urls";
import RequestModal from "./components/RequestModal";
import "./index.css";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { Briefcase, Info, LogOut, Menu, User, X } from "react-feather";
import { BirthdayToday } from "../../../service/models/user_model";
import { useAuth } from "../../../context_providers/auth/authProvider";
import WishingCard from "./components/WishingCard";
import AnniCard from "./components/AnniCard";
import { Dashboard } from '@material-ui/icons';

interface LandingScreenProps {}

const LandingScreen: FunctionComponent<LandingScreenProps> = () => {
  const { user, isAuthorised, logout } = useAuth();
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);
  const [birthData, setBirthData] = useState<[]>([]);
  const [anniData, setAnniData] = useState<[]>([]);
  const [showBirthModal, setShowBirthModal] = useState<boolean>(false);
  const [showAnniModal, setShowAnniModal] = useState<boolean>(false);
  const [accessedModule, setAccessedModule] = useState<any>([]);
  const [declinedModule, setDeclinedModule] = useState<any>([]);
  const [upcomingModule, setUpcomingModule] = useState<any>([]);
  const [showHrmNotify, setShowHrmNotify] = useState<boolean>(false);

  const date = new Date();
  const ref = useRef<HTMLDivElement>(null);

  const birthdayToday = async () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const res = await GetBirthdayToday(day, month, year);
    console.log(res);

    if (res.success) {
      setBirthData(res.data);
      if (res.data.length > 0) {
        handleBirthDay();
      }
    }
  };

  const anniToday = async () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const res = await GetAnniversary(day, month, year);

    if (res.success) {
      setAnniData(res.data);
      if (res.data.length > 0) {
        handleAnni();
      }
    }
  };

  const handleBirthDay = () => {
    const birthDay = localStorage.getItem("birthDay");
    const today = JSON.stringify(date.getDate());

    if (birthDay) {
      if (birthDay !== today) {
        setTimeout(() => {
          setShowBirthModal(true);
        }, 1000);
        localStorage.setItem("birthDay", today);
      }
    } else {
      setTimeout(() => {
        setShowBirthModal(true);
      }, 1000);
      localStorage.setItem("birthDay", today);
    }
  };
  const handleAnni = () => {
    const anniDay = localStorage.getItem("anniDay");
    const today = JSON.stringify(date.getDate());

    if (anniDay) {
      if (anniDay !== today) {
        setTimeout(() => {
          setShowAnniModal(true);
        }, 1000);
        localStorage.setItem("anniDay", today);
      }
    } else {
      setTimeout(() => {
        setShowAnniModal(true);
      }, 1000);
      localStorage.setItem("anniDay", today);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showSidePanel && ref?.current && !ref?.current?.contains(e.target)) {
        setShowSidePanel(false);
      }
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [showSidePanel]);

  useEffect(() => {
    birthdayToday();
    anniToday();
  }, []);

  const getUrl = (module: string) => {
    const links = menuUrls(module);

    if (links.length > 0) {
      for (let i = 0; i < links.length; i++) {
        const link = links[i];
        const granted = isAuthorised({
          module: link.module,
          action: link.action,
          operator: link.operator,
        });

        if (granted && link.url !== urls.root) {
          return link.url;
        }
      }
    }
  };

  const menus = [
    {
      url: getUrl(MODULE.hrm_panel),
      granted: isAuthorised({
        module: MODULE.hrm_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/hr.svg",
      lable: "HRM",
      desc: "Apply, track and manage your leaves, upload memories and much more.",
      dept: "All employees, HR",
    },
    {
      url: getUrl(MODULE.order_panel),
      granted: isAuthorised({
        module: MODULE.order_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/orders.svg",
      lable: "Orders",
      desc: "Create and view orders recieved in the company",
      dept: "Sales, Operation manager, Management",
    },
    {
      url: getUrl(MODULE.payment_panel),
      granted: isAuthorised({
        module: MODULE.payment_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/transaction.svg",
      lable: "Payments",
      desc: "View, approve, and handle payments from our customers/clients.",
      dept: "Finance,Management",
    },
    {
      url: getUrl(MODULE.device_inventory_panel),
      granted: isAuthorised({
        module: MODULE.device_inventory_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/inventory.svg",
      lable: "Inventory",
      desc: "Track inventory, stocked devices, installed devices, etc.",
      dept: "Operations, Management",
    },
    {
      url: getUrl(MODULE.ops_task_panel),
      granted: isAuthorised({
        module: MODULE.ops_task_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/task1.svg",
      lable: "Tasks Management",
      desc: "Create task, check assigned tasks and manage your operational workflow",
      dept: "Operations,Management",
    },
    {
      url: getUrl(MODULE.complaint_panel),
      granted: isAuthorised({
        module: MODULE.complaint_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/complaint.svg",
      lable: "Complaints",
      desc: "Create, track and manage the complaints related to our products",
      dept: "All employees",
    },
    {
      url: getUrl(MODULE.content_panel),
      granted: isAuthorised({
        module: MODULE.content_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/icons/content_creator.svg",
      lable: "Website Content panel",
      desc: "Create and manage website content",
      dept: "Content team",
    },
    {
      tag: "New",
      url: urls.kazam_academy,
      granted: true,
      icon: "/images/academy.svg",
      lable: "Kazam Academy",
      desc: "Access protocols,videos and understand how Kazam works ",
      dept: "All employees",
    },

    {
      tag: "Coming soon",
      url: "/",
      granted: true,
      icon: "/images/leads.svg",
      lable: "Sales Lead",
      desc: "Assign and track salesperson target given and achieved",
      dept: "Sales, Management",
    },
    {
      tag: "Coming soon",

      url: getUrl(MODULE.master_data_panel),
      granted: isAuthorised({
        module: MODULE.master_data_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/master.svg",
      lable: "Master Data",
      desc: "Master data",
      dept: "Management",
    },
    {
      url: getUrl(MODULE.access_panel),
      granted: isAuthorised({
        module: MODULE.access_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/access.svg",
      lable: "Access Management",
      desc: "Manage modular access given to the employees in ERP",
      dept: "Tech,Management",
    },
    {
      url: getUrl(MODULE.api_inegration_panel),
      granted: isAuthorised({
        module: MODULE.api_inegration_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/api_img.svg",
      lable: "API Integration",
      desc: "Tech API's",
      dept: "Tech",
    },
    {
      url: urls.protocol_category,
      granted: isAuthorised({
        module: MODULE.academy,
        action: [ACTION.READ],
      }),
      icon: "/images/academy.svg",
      lable: "Protocols",
      desc: "Check out Kazam protocols",
      dept: "All employees, HR",
    },
    {
      tag: "Coming soon",
      url: getUrl(MODULE.procurement_panel),
      granted: isAuthorised({
        module: MODULE.procurement_panel,
        action: [ACTION.READ],
      }),
      icon: "/images/logistics.svg",
      lable: "Procurement",
      desc: "Procurement",
      dept: "Procurement, Accounts",
    },
    {
      tag: "New",
      url: urls.Dashboard,
      granted: isAuthorised({
        module: MODULE.dashboard,
        action: [ACTION.READ],
      }),
      icon: "/images/data analysis svg.svg",
      lable: "Dashboards",
      desc: "Department wise Detailed analysis",
      dept: "All employees",
    },
  ];

  const handleModules = () => {
    const accessModule = menus.filter(
      (el) => el.granted === true && el.tag !== "Coming soon"
    );
    setAccessedModule(accessModule);
    const declinedModule = menus.filter(
      (el) => el.granted === false && el.tag !== "Coming soon"
    );
    setDeclinedModule(declinedModule);
    const upcomingModule = menus.filter(
      (el) => el.tag && el.tag === "Coming soon"
    );
    setUpcomingModule(upcomingModule);
  };

  const handleHrmNotify = () => {
    const hrmUpdate = localStorage.getItem("jobPortal");

    if (hrmUpdate) {
      if (hrmUpdate !== "true") {
        setTimeout(() => {
          setShowHrmNotify(true);
        }, 500);
        localStorage.setItem("jobPortal", "true");
      }
    } else {
      setTimeout(() => {
        setShowHrmNotify(true);
      }, 500);
      localStorage.setItem("jobPortal", "true");
    }
  };

  useEffect(() => {
    handleModules();
    handleHrmNotify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestModal = () => {
    setShowRequestModal(!showRequestModal);
  };

  return (
    <>
      {showRequestModal && (
        <RequestModal handleRequestModal={handleRequestModal} />
      )}
      {/* {showHrmNotify && <HrmNotify close={() => setShowHrmNotify(false)} />} */}
      <div
        className="relative min-h-screen flex flex-col items-center select-none "
        style={{ background: "#1F1F1F" }}
      >
        {/* <marquee className="bg-hrmBlue text-white p-2 text-sm sticky top-0 z-20">
          The leave calendar for the year 2024 has been recently updated and is
          now accessible for your reference. 🎉 {"  "}We are pleased to announce
          that the latest version of the Engineer Task Portal PWA is now live.
          🎉 
        </marquee> */}
        {/* <div className="flex justify-center bg-hrmBlue text-white py-2 w-full z-40 lg:px-0 px-2">
        
          <div className="">
            We Have Two Updates:
            <ol>
              <li>
                The leave calendar for the year 2024 has been recently updated
                and is now accessible for your reference. 🎉{" "}
                <span className="underline font-semibold ml-3">
                  <NavLink to={urls.hrm_leave_application}>Click here</NavLink>
                </span>
              </li>
              <li>
                We are pleased to announce that the latest version of the
                Engineer Task Portal PWA is now live. 🎉{" "}
                <span className="underline font-semibold ml-3">
                  <NavLink to={urls.operation_engineer_task}>
                    Click here
                  </NavLink>
                </span>
              </li>
            </ol>
          </div>
        </div> */}
        <div className="w-full flex justify-between pt-2">
          <div className="flex items-center gap-1 pl-6">
            <div className="">
              <img src="/images/logo.png" alt="" className="w-8" />
            </div>
            <div className=" lg:text-2xl font-extrabold text-primaryColor">
              ERP Dashboard
            </div>
          </div>

          <div className="mr-2  cursor-pointer landing_dropdown inline-block">
            <div className="w-10 h-10 lg:w-14  lg:h-14 rounded-full">
              <img
                src={user?.profile_image}
                alt=""
                className="w-full h-full rounded-full"
              />
            </div>
            <div className="nav_sub absolute right-2 bg-black py-6 px-8 rounded-md">
              <NavLink to={urls.hrm_profile}>
                <div className="flex items-center gap-3">
                  <div className=" font-semibold text-primaryColor">
                    Profile
                  </div>
                  <User className="text-primaryColor" size={18} />
                </div>
              </NavLink>
              <div onClick={logout} className="flex items-center gap-3 mt-3">
                <div className=" font-semibold text-primaryColor">Logout</div>
                <LogOut className="text-primaryColor" size={18} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col py-20 w-auto px-4 lg:px-0">
          {accessedModule.length > 0 && (
            <div className="">
              <div className="">
                <div className="text-teal lg:text-xl lg:mb-2">
                  Access granted
                </div>
                {/* <div className="w-1/2 h-px bg-teal bg-opacity-20"></div> */}
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-8 mt-6">
                {accessedModule.map((menu: any, i: number) => {
                  return <MenuTile {...menu} index={i} />;
                })}
              </div>
            </div>
          )}
          {declinedModule.length > 0 && (
            <div className="mt-20">
              <div className="flex justify-between">
                <div className="w-1/2">
                  <div className="text-hrmRed lg:text-xl lg:mb-2">
                    Modules denied
                  </div>
                  {/* <div className="w-full h-px bg-hrmRed bg-opacity-20"></div> */}
                </div>
                <div className="">
                  <button
                    className="py-2 px-4 rounded-md bg-green-300 bg-opacity-30 text-white font-semibold"
                    onClick={() => setShowRequestModal(true)}
                  >
                    Request Access
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-8 mt-6">
                {declinedModule.map((menu: any, i: number) => {
                  return <MenuTile {...menu} index={i} />;
                })}
              </div>
            </div>
          )}
          {upcomingModule.length > 0 && (
            <div className="mt-20">
              <div className="">
                <div className="text-hrmPurple lg:text-xl lg:mb-2">
                  Under development
                </div>
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-8 mt-6">
                {upcomingModule.map((menu: any, i: number) => {
                  return <MenuTile {...menu} index={i} />;
                })}
              </div>
            </div>
          )}
          <div className="flex mt-10 items-center gap-2 justify-center">
            <p className="text-gray-500 text-xs md:text-sm ">Powered by </p>
            <div className=" w-auto ">
              <img src="/assets/logo.png" alt="kazam" className="h-8 md:h-10" />
            </div>
          </div>
        </div>

        <div className="">
          {showBirthModal && (
            <WishingCard
              data={birthData}
              closeBirthdayModal={() => setShowBirthModal(false)}
            />
          )}
          {showAnniModal && (
            <AnniCard
              data={anniData}
              closeAnniModal={() => setShowAnniModal(false)}
            />
          )}
        </div>
      </div>

      <NavLink to={`${urls.hrm_home}?showQuick=true`}>
        <button className="text-sm fixed bottom-6 lg:bottom-12 right-3 lg:right-12  shadow-xl px-6 py-2 lg:py-3 whitespace-nowrap rounded-full text-white font-bold bg-hrmBlue ">
          Quick leave
        </button>
      </NavLink>
    </>
  );
};

export default LandingScreen;

interface MenuTileProps {
  index: number;
  desc?: string;
  dept?: string;
  url?: string;
  granted: boolean;
  // onClick: () => void;
  icon: any;
  lable: string;
  tag?: string;
}

const MenuTile: FunctionComponent<MenuTileProps> = ({
  url,
  icon,
  lable,
  desc,
  dept,
  // onClick,
  granted,
  tag,
  index,
}) => {
  const { showToast } = useToast();
  const history = useHistory();
  const [showDesc, setShowDesc] = useState<boolean>(false);

  const onGo = () => {
    if (url) history.push(url);
  };
  return (
    <div
      className={`relative card_div py-12 px-6 cursor-pointer homeCard rounded-xl flex flex-col justify-between items-center`}
      onClick={() => {
        if (granted) onGo();
        else {
          showToast({
            type: ToastType.info,
            text: "Sorry, you don't have access",
          });
        }
      }}
    >
      <div
        className="hidden lg:block absolute top-3 right-3"
        onMouseEnter={() => setShowDesc(true)}
        onMouseLeave={() => setShowDesc(false)}
      >
        <Info size={20} className="text-secondaryColor" />
      </div>
      {showDesc && (
        <div className="absolute right-0 bg-black py-8 px-4  z-20 rounded-lg">
          <div className="text-white text-lg whitespace-nowrap font-bold">
            {lable}
          </div>
          <div className="w-1/2 h-px my-2 bg-secondaryColor"></div>
          {desc && (
            <div className="text-primaryColor mt-4 text-sm italic">{desc}</div>
          )}
          {dept && (
            <div className="text-sm mt-8">
              <div className="text-secondaryColor">Department concerned</div>
              {/* <div className="w-1/2 h-px my-1 bg-secondaryColor"></div> */}
              <div className="text-white  text-sm italic">{dept}</div>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col items-center justify-between h-full ">
        <div className="">
          <img src={icon} alt="" className="w-12 lg:w-16" />
          {/* {icon} */}
        </div>
        <div className="text-lg lg:text-xl font-semibold mt-7 text-primaryColor">
          <div className="">{lable}</div>
        </div>
      </div>
    </div>
  );
};
