import { BaseModel } from "../../service/models/base";

export interface Permission {
  module: string;
  action: string[];
}
export interface Role extends BaseModel {
  old_id: any;
  title: string;
  desc: string;
  active: boolean;
  permissions: Permission[];
}

export interface EmpDocModel extends BaseModel {
  id?: any;
  uid: any;
  category: string;
  type: string;
  file_type: string;
  url: string;
  status: string;
}

export interface UserModel {
  _id?: any;
  id?: string;
  created_by?: any;
  updated_by?: any;
  created_at?: Date;
  updated_at?: Date;
  created_by_name?: string;
  updated_by_name?: string;
  deleted?: boolean;
  deleted_at?: Date;
  deleted_by?: any;
  deleted_by_name?: string;
  uid?: any;
  first_name: string;
  last_name: string;
  password?: string;
  profile_image?: string;
  mobile?: string;
  email: string;
  email_verified: boolean;
  mobile_verified: boolean;
  active: boolean;
  dob?: string | Date;
  isEmp?: boolean;
  emp_profile?: EmpProfileModel;
  token?: string;
}

export interface EmpProfileModel extends BaseModel {
  emp_id?: string;
  designation?: string;
  department?: string;
  sub_dep?: string;
  doj?: string | Date;
  dol?: string | Date;
  pp_month?: number;
  permanent_address?: string;
  address?: string;
  reporting_to_name?: string;
  reporting_to_id?: number;
  hod_id?: number;
  hod_name?: string;
  zone: string;
  isHod: boolean;
  isReportingManager: boolean;
  weekoff:
    | { off: number[]; alt_off: number[]; alt_off_even_week: boolean }
    | string;
  linked_in?: string;
  roles?: string[];
  permissions?: Role[];
  active: boolean;
  docs?: EmpDocModel[];
  blood_group?: string;
  emergency_contact?: string;
  emp_type?: string;
  date_of_earned_leave_start?: string | Date | null;
  education?: string;
  education_degree_name?: string;
}

export const defaultEmpValue: UserModel = {
  first_name: "",
  last_name: "",
  mobile: "",
  email: "",
  email_verified: false,
  mobile_verified: false,
  active: true,
  created_by: 0,
  updated_by: 0,
  emp_profile: {
    emp_id: "",
    roles: [],
    active: true,
    department: "",
    sub_dep: "",
    zone: "",
    doj: "",
    dol: "",
    isHod: false,
    isReportingManager: false,
    weekoff: {
      off: [],
      alt_off: [],
      alt_off_even_week: false,
    },
    permanent_address: "",
    blood_group: "",
    emergency_contact: "",
    education: "",
    education_degree_name: "",
    emp_type: "",
  },
};
