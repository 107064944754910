import { deDE } from "@material-ui/data-grid";
import { setDate } from "date-fns";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPost, fetchPut } from "../../../../service/api_client";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { urls } from "../../../../utils/urls";
import { tax_type } from "../../../accounts/models/ledger_model";
import ItemGroupSearchField from "../../item_group/item_group_search_field";
import ItemUnitSearchField from "../../item_unit/item_unit_search_field";
import {
  bomDefaultValue,
  itemDefaultValue,
  ItemModel,
  ItemType,
} from "../../models/item_model";

import BomItemWidget from "./bom_item";
import OpeningStock from "./opening_stock";
import { isArray } from "lodash";

interface ItemCreateScreenProps {
  data?: ItemModel;
  onClose: (id: ItemModel) => void;
}

const ItemCreateScreen: FunctionComponent<ItemCreateScreenProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const is_update_page = useRouteMatch(
    urls.procurement_masters_item + "/update/:id"
  );
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items`;
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [data, setData] = useState<ItemModel>({
    ...(props.data || itemDefaultValue),
  });
  const [bomId, setBomId] = useState(`${Math.random() * 1000}`);
  const [stockId, setStockId] = useState(`${Math.random() * 1000}`);
  const onSubmit = async () => {
    if (
      !data.name ||
      !data.type ||
      !data.group.name ||
      !data.unit.symbol ||
      (data.tax_applicable && !data.tax_detail.type) ||
      (data.bom_applicable &&
        (!data.bom.name || data.bom.items.length === 0 || !data.bom.unit_no))
    ) {
      showToast({
        type: ToastType.error,
        text: "Please fill required fields !",
      });
      return;
    }
    setLoading(true);

    const res = data.id
      ? await fetchPut(url + "/" + data.id, data)
      : await fetchPost(url, data);
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      if (props.onClose) props.onClose(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (id && is_update_page) getData(id);
  }, []);
  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      if (!isArray(res.data.opening_stock)) {
        res.data.opening_stock = [res.data.opening_stock];
      }
      setData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  return (
    <>
      <div className="  grid grid-cols-2 m-3 gap-2 select-none">
        <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
          <h4 className="font-semibold"> Item details </h4>

          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="name">
              Name*
            </label>
            <input
              placeholder="Enter name"
              className="p-1 rounded  focus:outline-none w-full border"
              id="name"
              name="name"
              value={data.name || ""}
              // required={true}
              type="text"
              onChange={(e) => {
                setData((o) => ({ ...o, name: e.target.value }));
              }}
            ></input>
          </div>
          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="name">
              Description
            </label>
            <textarea
              placeholder="Enter name"
              className="p-1 rounded  focus:outline-none w-full border"
              id="desc"
              name="desc"
              value={data.description || ""}
              onChange={(e) => {
                setData((o) => ({ ...o, description: e.target.value }));
              }}
            ></textarea>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="category">
                Category*
              </label>
              <select
                required
                className="p-1 rounded  focus:outline-none w-full border"
                name="category"
                id="category"
                value={data.category || ""}
                onChange={(e) => {
                  setData((o) => ({ ...o, category: e.target.value }));
                }}
              >
                <option value="" hidden>
                  {" "}
                  select
                </option>
                {[
                  "Electrical",
                  "Electronic",
                  "Mechanical",
                  "Packaging",
                  "Printing",
                  "Turnkey",
                  "Assembly",
                  "Office Supply",
                  "Charger",
                  "Marketing",
                  "Document",
                  "Others",
                ].map((category) => (
                  <option value={category}>{category}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="category">
                Sub-Category
              </label>
              <select
                required
                className="p-1 rounded focus:outline-none w-full border"
                name="subcategory"
                id="subcategory"
                value={data.subcategory || ""}
                onChange={(e) => {
                  setData((o) => ({ ...o, subcategory: e.target.value }));
                }}
              >
                <option value="" hidden>
                  {" "}
                  select
                </option>
                {[
                  "DB",
                  "MCCB",
                  "Isolator",
                  "MCB",
                  "RCCB",
                  "SPD",
                  "Accessory",
                  "Fire Extinguisher",
                  "Wire",
                  "Busbar",
                  "Device",
                  "Others"
                ].map((subcategory) => (
                  <option value={subcategory}>{subcategory}</option>
                ))}
              </select>
            </div>

            <div className="flex flex-col items-start text-sm w-full md:col-span-2">
              <label className="font-semibold w-full" htmlFor="manufacturer">
                Manufacturer
              </label>
              <input
                placeholder="manufacturer"
                className="p-1 rounded  focus:outline-none w-full border"
                id="manufacturer"
                name="manufacturer"
                value={data.manufacturer || ""}
                // required={true}
                type="text"
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    manufacturer: e.target.value.toUpperCase(),
                  }));
                }}
              ></input>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="item_code">
                Item code*
              </label>
              <input
                placeholder="Enter item code"
                className="p-1 rounded  focus:outline-none w-full border"
                id="item_code"
                name="item_code"
                value={data.item_code || ""}
                // required={true}
                type="text"
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    item_code: e.target.value.toUpperCase(),
                  }));
                }}
              ></input>
            </div>

            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="item_type">
                Item type*
              </label>
              <select
                value={data.type}
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    type: e.target.value,
                  }));
                }}
                name="item_type"
                id="item_type"
                className="p-1 rounded  focus:outline-none w-full border"
              >
                <option value="" hidden>
                  select
                </option>
                {Object.values(ItemType).map((type, i) => (
                  <option value={type} key={i}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="under">
                Under Group *
              </label>

              <ItemGroupSearchField
                value={data.group}
                onSelect={(d) => {
                  setData((o) => ({
                    ...o,
                    group: d,
                  }));
                }}
              />
            </div>

            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="unit">
                Unit measure *
              </label>
              <ItemUnitSearchField
                value={data.unit}
                onSelect={(d) => {
                  setData((o) => ({ ...o, unit: d }));
                }}
              />
            </div>
          </div>

          <div className="">
            <h4 className="font-semibold"> Opening stock </h4>
            <div className="grid grid-cols-10 gap-1">
              <div className="flex flex-col items-start text-sm w-full col-span-2">
                <label className="font-semibold w-full" htmlFor="date">
                  Date*
                </label>
              </div>
              <div className="flex flex-col items-start text-sm w-full col-span-3">
                <label className="font-semibold w-full" htmlFor="date">
                  Warehouse*
                </label>
              </div>
              <div className="flex flex-col items-start text-sm w-full col-span-2 ">
                <label className="font-semibold w-full" htmlFor="unit">
                  Qty*
                </label>
              </div>
              <div className="flex flex-col items-start text-sm w-full col-span-2">
                <label className="font-semibold w-full" htmlFor="price">
                  Price*
                </label>
              </div>
            </div>

            {data.opening_stock.map((item, i) => (
              <OpeningStock
                item={item}
                onChange={(d) => {
                  setData((o) => {
                    for (let j = 0; j < o.opening_stock.length; j++) {
                      let itm = o.opening_stock[j];

                      if (itm.id === item.id) {
                        itm = { ...itm, ...d };
                        o.opening_stock[j] = itm;
                      }
                    }
                    return { ...o };
                  });
                }}
                onDelete={(d) => {
                  setData((o) => ({
                    ...o,
                    opening_stock: [...(o.opening_stock || [])].filter(
                      (i) => i.id !== d.id
                    ),
                  }));
                }}
              />
            ))}
            <OpeningStock
              key={stockId}
              item={{
                id: stockId,
                date: new Date(),
                price: 0,
                unit_no: 0,
              }}
              onSubmit={(d) => {
                setData((o) => ({
                  ...o,
                  opening_stock: [...o.opening_stock, d],
                }));
                setStockId((Math.random() * 10000).toString());
              }}
            />
          </div>
          <div className="flex gap-1   items-center text-sm w-full">
            <input
              onChange={(e) => {
                setData((o) => ({
                  ...o,
                  bom_applicable: e.target.checked,
                }));
              }}
              type="checkbox"
              name="bom_applicable"
              id="bom_applicable"
              checked={data.bom_applicable}
            />
            <label className="font-semibold" htmlFor="bom_applicable">
              Bom Applicable*
            </label>
          </div>
        </div>
        <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
          <h4 className="font-semibold"> Tax details </h4>
          <div className="flex gap-5   items-center text-sm w-full">
            <input
              onChange={(e) => {
                setData((o) => ({
                  ...o,
                  tax_applicable: e.target.checked,
                }));
              }}
              type="checkbox"
              name="tax_applicable"
              id="tax_applicable"
              checked={data.tax_applicable}
            />
            <label className="font-semibold" htmlFor="tax_applicable">
              Tax Applicable
            </label>
          </div>
          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="tax_type">
              Tax type{data.tax_applicable ? "*" : ""}
            </label>
            <select
              disabled={!data.tax_applicable}
              value={data.tax_detail.type}
              onChange={(e) => {
                setData((o) => ({
                  ...o,
                  tax_detail: { ...o.tax_detail, type: e.target.value },
                }));
              }}
              name="tax_type"
              id="tax_type"
              className="p-1 rounded  focus:outline-none w-full border"
            >
              <option value="" hidden>
                select
              </option>
              {Object.values(tax_type).map((tax_type, i) => (
                <option value={tax_type} key={i}>
                  {tax_type.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="hsn">
              HSN/SAC
              {/* {data.tax_applicable ? "*" : ""} */}
            </label>
            <input
              disabled={!data.tax_applicable}
              placeholder="Enter HSN or SAC number"
              className="p-1 rounded  focus:outline-none w-full border"
              id="hsn"
              name="hsn"
              value={data.tax_detail.hsn_sac || ""}
              required={true}
              type="text"
              onChange={(e) => {
                setData((o) => ({
                  ...o,
                  tax_detail: { ...o.tax_detail, hsn_sac: e.target.value },
                }));
              }}
            ></input>
          </div>
          {data.tax_detail.type === tax_type.gst && (
            <>
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="integrated">
                  Integrated Tax (%)*
                </label>
                <input
                  disabled={!data.tax_applicable}
                  placeholder="0"
                  className="p-1 rounded  focus:outline-none w-full border"
                  id="integrated"
                  name="integrated"
                  value={data.tax_detail.integreted || 0}
                  required={true}
                  type="number"
                  max={100}
                  min={0}
                  onChange={(e) => {
                    const integreted = Number(e.target.value);
                    setData((o) => ({
                      ...o,
                      tax_detail: {
                        ...o.tax_detail,
                        integreted,
                        central: integreted / 2,
                        state: integreted / 2,
                      },
                    }));
                  }}
                ></input>
              </div>
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="central">
                  Central Tax(%)*
                </label>
                <input
                  disabled={!data.tax_applicable}
                  placeholder="0"
                  className="p-1 rounded  focus:outline-none w-full border"
                  id="central"
                  name="central"
                  value={data.tax_detail.central || 0}
                  required={true}
                  type="number"
                  max={100}
                  min={0}
                  onChange={(e) => {
                    const central = Number(e.target.value);
                    setData((o) => ({
                      ...o,
                      tax_detail: {
                        ...o.tax_detail,
                        integreted: o.tax_detail.state + central,
                        central,
                      },
                    }));
                  }}
                ></input>
              </div>
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="state">
                  State Tax(%)*
                </label>
                <input
                  disabled={!data.tax_applicable}
                  placeholder="0"
                  className="p-1 rounded  focus:outline-none w-full border"
                  id="state"
                  name="state"
                  value={data.tax_detail.state || 0}
                  required={true}
                  type="number"
                  max={100}
                  min={0}
                  onChange={(e) => {
                    const state = Number(e.target.value);
                    setData((o) => ({
                      ...o,
                      tax_detail: {
                        ...o.tax_detail,
                        integreted: o.tax_detail.central + state,

                        state,
                      },
                    }));
                  }}
                ></input>
              </div>
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="cess">
                  Cess Tax(%)*
                </label>
                <input
                  disabled={!data.tax_applicable}
                  placeholder="0"
                  className="p-1 rounded  focus:outline-none w-full border"
                  id="cess"
                  name="cess"
                  value={data.tax_detail.cess || 0}
                  required={true}
                  type="number"
                  max={100}
                  min={0}
                  onChange={(e) => {
                    const cess = Number(e.target.value);
                    setData((o) => ({
                      ...o,
                      tax_detail: {
                        ...o.tax_detail,
                        cess,
                      },
                    }));
                  }}
                ></input>
              </div>
            </>
          )}
        </div>
        {data.bom_applicable && (
          <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white">
            <h4 className="font-semibold"> Bom details </h4>{" "}
            <div className="grid grid-cols-2 gap-5">
              {" "}
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="bom-name">
                  Name*
                </label>
                <input
                  placeholder="Enter name"
                  className="p-1 rounded  focus:outline-none w-full border"
                  id="bom-name"
                  name="name"
                  value={data.bom.name || ""}
                  required={true}
                  type="text"
                  onChange={(e) => {
                    setData((o) => ({
                      ...o,
                      bom: { ...o.bom, name: e.target.value },
                    }));
                  }}
                ></input>
              </div>
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="bom-qty">
                  Qty*
                </label>
                <input
                  placeholder="0"
                  className="p-1 rounded  focus:outline-none w-full border"
                  id="bom-qty"
                  name="bom-qty"
                  value={data.bom?.unit_no || ""}
                  required={true}
                  type="number"
                  onChange={(e) => {
                    const unit_no = Number(e.target.value);

                    setData((o) => ({
                      ...o,
                      bom: { ...o.bom, unit_no },
                    }));
                  }}
                ></input>
              </div>
              {/* <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="unit">
                  Unit measure
                </label>
                {data.bom.unit_measure.symbol ? (
                  <div
                  onClick={() => {
                    setData((o) => ({
                      ...o,
                      bom: {
                        ...o.bom,
                        unit_measure: unitMeasureDefaultValue,
                      },
                      }));
                    }}
                    className="border rounded p-1 w-full"
                  >
                  {data.unit.symbol}
                  </div>
                ) : (
                  <ItemUnitSearchField
                  onSelect={(d) => {
                    setData((o) => ({
                      ...o,
                      bom: { ...o.bom, unit_measure: d },
                    }));
                  }}
                  />
                  )}
                </div> */}
            </div>
            <hr />
            <div className="">
              <h4 className="font-semibold mb-2">Consumption </h4>

              {data.bom.items.map((item, i) => (
                <BomItemWidget
                  item={item}
                  onChange={(d) => {
                    setData((o) => {
                      for (let j = 0; j < o.bom.items.length; j++) {
                        let itm = o.bom.items[j];

                        if (itm.id === item.id) {
                          itm = { ...itm, ...d };
                          o.bom.items[j] = itm;
                        }
                      }
                      return { ...o };
                    });
                  }}
                  onDelete={(d) => {
                    setData((o) => ({
                      ...o,
                      bom: {
                        ...o.bom,
                        items: [...(o.bom.items || [])].filter(
                          (i) => i.id !== d.id
                        ),
                      },
                    }));
                  }}
                />
              ))}
              <BomItemWidget
                key={bomId}
                item={{
                  id: (Math.random() * 10000).toString(),
                  item_id: "",
                  unit_no: 0,
                  price: 0,
                }}
                onSubmit={(d) => {
                  setData((o) => ({
                    ...o,
                    bom: { ...o.bom, items: [...o.bom.items, d] },
                  }));
                  setBomId((Math.random() * 10000).toString());
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center gap-5 mt-5 mb-2">
        <button
          onClick={onSubmit}
          type="button"
          className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
        >
          Submit
        </button>
      </div>
      {loading && <LoadingWidget />}
    </>
  );
};
// type: string;
// hsn_sac: string;
// description: string;
// integreted: number;
// central: number;
// state: number;
// cess: number;
export default ItemCreateScreen;
