import React from "react";
import Card from "./Card";

const DashboardCards: React.FC = () => {
  const cards = [
    {
      title: "Engineer wise Uptime Tracker Dashboard",
      description: "Analyze Device performance.",
      icon: "/images/UptimeTracker.png",
      link: "https://app.powerbi.com/view?r=eyJrIjoiZTNlMzQ0NjUtMTQ0MC00MDI0LTljMWQtNzc4MzcwOTZlZGRkIiwidCI6ImY3MDQ3OTY0LTk4ODctNGVjOC04NjUzLWJjNjAwZTc1MWFmZCJ9",
      isExternal: true,
    },
    {
      title: "Operations Dashboard",
      description: "Track operational metrics.",
      icon: "/images/Operations.png",
      link: "https://app.powerbi.com/view?r=eyJrIjoiMjU1MWFjNDMtZGJiNS00YTFkLWJhOTUtZWJjZTBhZDhhNDM0IiwidCI6ImY3MDQ3OTY0LTk4ODctNGVjOC04NjUzLWJjNjAwZTc1MWFmZCJ9",
      isExternal: true,
    },
    {
      title: "Support Dashboard",
      description: "Track complaints.",
      icon: "/images/Support.png",
      link: "https://app.powerbi.com/view?r=eyJrIjoiYzEwY2MwZTYtZWU1ZC00NWUxLTg1ZWYtNjg4Mzc1MzUyNjYzIiwidCI6ImY3MDQ3OTY0LTk4ODctNGVjOC04NjUzLWJjNjAwZTc1MWFmZCJ9",
      isExternal: true,
    },
    {
      title: "MIS - Fortnightly Dashboard",
      description: "MIS 15 Days Report",
      icon: "/images/management-information-system.png",
      link: "https://app.powerbi.com/view?r=eyJrIjoiMzdhOTA3YTktOTYzNy00YmZhLWJmNWMtNzI5MjFmMTMxZTAwIiwidCI6ImY3MDQ3OTY0LTk4ODctNGVjOC04NjUzLWJjNjAwZTc1MWFmZCJ9",
      isExternal: true,
    },
    // {
    //   title: "MIS - Fortnightly Dashboard",
    //   description: "MIS 15 Days Report",
    //   icon: "/images/management-information-system.png",
    //   link: "https://app.powerbi.com/view?r=eyJrIjoiMzdhOTA3YTktOTYzNy00YmZhLWJmNWMtNzI5MjFmMTMxZTAwIiwidCI6ImY3MDQ3OTY0LTk4ODctNGVjOC04NjUzLWJjNjAwZTc1MWFmZCJ9",
    //   isExternal: true,
    // },
  ];

  return (
    <div className="px-4 py-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      {cards.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          description={card.description}
          icon={card.icon}
          link={card.link}
          isExternal={card.isExternal}
        />
      ))}
    </div>
  );
};

export default DashboardCards;
