import React from "react";
import { useHistory } from "react-router-dom";

interface CardProps {
  title: string;
  description: string;
  icon: string;
  link: string;
  isExternal?: boolean; // Add a flag to handle external links
}

const Card: React.FC<CardProps> = ({ title, description, icon, link, isExternal = false }) => {
  const history = useHistory();

  const handleClick = () => {
    if (isExternal) {
      window.open(link, "_blank");
    } else {
      history.push(link);
    }
  };

  return (
    <div
      className="card py-6 px-4 cursor-pointer rounded-xl shadow-lg flex flex-col justify-between items-center"
      onClick={handleClick}
    >
      <img src={icon} alt={title} className="w-16 h-16 mb-4" />
      <div className="text-xl font-semibold text-center">{title}</div>
      <div className="text-center mt-2 text-gray-600">{description}</div>
    </div>
  );
};

export default Card;
