import { BaseModel } from "../../../service/models/base";
import {
  LedgerEntryModel,
  voucherType,
} from "../../accounts/models/common_model";
import { itemDefaultValue, ItemModel } from "./item_model";
import { warehouseDefaultValue, WarehouseModel } from "./warehouse_model";

export const StockMovementType = {
  inward: "Inward",
  outward: "Outward",
};

export interface ItemMovementModel extends BaseModel {
  date: Date;
  item?: ItemModel;
  item_id: string;
  bill_unit_no: number;
  unit_no: number;
  price: number;
  cost: number;
  movement_type: string;
  voucher_type: string;
  voucher_id: string;
  voucher_no: string;
  warehouse_id: string;
  warehouse: WarehouseModel;
}

export interface StockManufacturingJournalModel extends BaseModel {
  voucher_type: string;
  voucher_no: string;
  date: Date;
  product_item_entry: ItemMovementModel;
  component_entries?: ItemMovementModel[];
  // co_products?: ItemMovementModel[];
  additional_cost_entries?: LedgerEntryModel[];
  component_cost: number;
  additional_cost: number;
  total: number;
}
export interface StockJournalModel extends BaseModel {
  date: Date;
  voucher_type: string;
  voucher_no: string;
  source?: ItemMovementModel[];
  destination?: ItemMovementModel[];
  source_taxes: LedgerEntryModel[];
  source_tax_ids: string[];
  source_tax: number;
  destination_taxes: LedgerEntryModel[];
  destination_tax_ids: string[];
  destination_tax: number;
  source_sub_total: number;
  destination_sub_total: number;
  source_total: number;
  destination_total: number;
}
export interface StockItemModel {
  id: string;
  _id: string;
  date: Date;
  item_id: string;
  warehouse_id?: string;
  total_inward_qty: number;
  total_inward_value: number;
  closing_qty: number;
  item: ItemModel;
  warehouse?: WarehouseModel;
}

export const itemMovementDefaultValue: ItemMovementModel = {
  item: itemDefaultValue,
  date: new Date(),
  bill_unit_no: 0,
  unit_no: 0,
  price: 0,
  cost: 0,
  movement_type: "",
  voucher_type: "",
  voucher_no: "",
  warehouse_id: "",
  warehouse: warehouseDefaultValue,
  item_id: "",
  voucher_id: "",
};

export const stockManufacturingDefaultValue: StockManufacturingJournalModel = {
  date: new Date(),
  voucher_type: voucherType.manufacturing,
  voucher_no: "",
  product_item_entry: itemMovementDefaultValue,
  component_cost: 0,
  additional_cost: 0,
  total: 0,
};

export const stockJournalDefaultValue: StockJournalModel = {
  date: new Date(),
  voucher_type: "",
  voucher_no: "",
  source_total: 0,
  destination_total: 0,
  source: [],
  destination: [],
  source_taxes: [],
  destination_taxes: [],
  source_tax_ids: [],
  source_tax: 0,
  destination_tax_ids: [],
  destination_tax: 0,
  source_sub_total: 0,
  destination_sub_total: 0,
};
