import { RouteParam } from ".";
import CreateComplaintPage from "../ui/pages/complaint/create";
import ComplainantDetailPage from "../ui/pages/complaint/detail";
import ComplaintListingPage from "../ui/pages/complaint/listing";
import WordCloudPage from "../ui/pages/complaint/word_cloud";
import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const complaint_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.create_complaint,
    component: CreateComplaintPage,
    main_module: MODULE.complaint_panel,
    module: MODULE.complaint,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.complaints,
    component: ComplaintListingPage,
    main_module: MODULE.complaint_panel,
    module: MODULE.complaint,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.complaint_detail,
    component: ComplainantDetailPage,
    main_module: MODULE.complaint_panel,
    module: MODULE.complaint,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.complaint_word_cloud,
    component: WordCloudPage,
    main_module: MODULE.complaint_panel,
    module: MODULE.complaint,
    action: [ACTION.CREATE],
    operator: "or",
  },
];

export default complaint_routes;
