import {
  mdiDelete,
  mdiPencil,
  mdiSortAscending,
  mdiSortDescending,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import Pagination from "../../ui/new_components/common/pagination";
import { FieldType } from "./create_view/form_field";
import { currencyFormat } from "../../utils/orders_utils";

interface TableViewProps<T extends { id?: string }> {
  page?: number;
  total?: number;
  count?: number;
  itemCount?: number;
  onPageChange?: (p: number) => void;
  headers: {
    colspan?: number;
    type: FieldType;
    key: string;
    sort_order?: number;
    onClick?: (data: { sort: string; sort_order: number }) => void;
    title: string;
    sub_title?: string;
  }[];

  rows: {
    data: T;
    values: any[];
  }[];
  onClick?: (data: T) => void;
  onEdit?: (data: T) => void;
  onDelete?: (data: T) => void;
}

const TableView = <T extends { id?: string }>({
  headers,
  rows,
  onClick,
  onEdit,
  onDelete,
  page,
  count,
  itemCount,
  total,

  onPageChange,
}: TableViewProps<T>): JSX.Element => {
  const [offSet, setOffSet] = useState(0);
  // const TableView = <T extends { id?: string }>({
  //   headers,
  //   rows,
  // }: PropsWithChildren<TableViewProps<T>>) => {

  useEffect(() => {
    const container = document.getElementById("page_container");
    const top_section = document.getElementById("top_section");
    const table = document.getElementById("table-container");
    if (container && top_section)
      table!.style.height = `${
        container!.clientHeight - 16 - top_section!.clientHeight
      }px`;
    if (page && count) setOffSet(page > 0 ? (page - 1) * count : 0);
  }, []);

  return (
    <section
      id="table-container"
      className="flex flex-col justify-between items-center w-full  "
    >
      <div
        id="scroll_listener"
        className=" overflow-auto h-full border rounded-lg w-full"
        style={{ height: "95%" }}
      >
        <table className=" divide-y divide-gray-200 relative w-full">
          <thead className="  sticky  -top-1 z-10 ">
            {/* <table className=" w-full   relative ">
          <thead className="pb-1   z-10  "> */}
            <tr className=" sticky  -top-0.5 text-sm bg-white shadow whitespace-nowrap">
              <th className={`  pb-2 font-semibold     w-8 `}>
                <div className="  py-1      ">Sl</div>
              </th>
              {headers.map((header, i) => (
                <th
                  colSpan={header.colspan || 1}
                  className={`   pb-2 font-semibold  overflow-hidden  `}
                  key={i}
                >
                  <div className="  py-1 flex justify-center  items-center gap-1 ">
                    <div className="flex flex-col gap-1">
                      <h3>{header.title ?? "---"}</h3>
                      {header.sub_title && <p>{header.sub_title}</p>}
                    </div>
                    {header.onClick && (
                      <div
                        onClick={() => {
                          if (header.onClick)
                            header.onClick({
                              sort: header.key,
                              sort_order: header.sort_order == 1 ? -1 : 1,
                            });
                        }}
                        className=""
                        title={
                          header.sort_order == 1 ? "Ascending" : "Descending"
                        }
                      >
                        <Icon
                          path={
                            header.sort_order == 1
                              ? mdiSortAscending
                              : mdiSortDescending
                          }
                          className="h-4 w-5"
                        ></Icon>
                      </div>
                    )}
                  </div>
                </th>
              ))}
              {(onEdit || onDelete) && (
                <th className={`   pb-2 font-semibold  overflow-hidden  `}>
                  <div className="   py-1 flex justify-center  items-center gap-1 ">
                    Action
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 max-h-96 mx-2 text-xs">
            {rows.map((item, i) => (
              <tr
                title="Double click to open"
                onDoubleClick={
                  onClick
                    ? () => onClick!(item.data)
                    : onEdit
                    ? () => onEdit!(item.data)
                    : undefined
                }
                key={i}
                className={`sticky-row  ${
                  onClick || onEdit ? " cursor-pointer  " : ""
                }   bg-white shadow p-1  hover:bg-gray-100  `}
              >
                <td
                  className={`p-1    text-right w-min whitespace-nowrap border sticky-field py-1 `}
                >
                  <div className={``}>{offSet + (i + 1)}</div>
                </td>
                {item.values.map((val, j) => {
                  if (headers[j].type === FieldType.date)
                    return (
                      <td
                        // onClick={
                        //   onClick ? () => onClick!(item.data) : undefined
                        // }
                        colSpan={headers[j].colspan || 1}
                        key={j}
                        className={`p-1 text-center whitespace-nowrap border sticky-field ${
                          onClick ? " cursor-pointer  " : ""
                        }`}
                      >
                        <div className={``}>
                          {val ? moment(val).format("DD-MM-YYYY") : "--"}
                        </div>
                      </td>
                    );

                  // if (headers[j].type === FieldType.currency)
                  //   return (
                  //     <td
                  //       // onClick={onClick ? () => onClick!(item.data) : undefined}
                  //       colSpan={headers[j].colspan || 1}
                  //       key={j}
                  //       className={`p-1 whitespace-nowrap border sticky-field   ${
                  //         headers[j].type == FieldType.currency
                  //           ? " text-right "
                  //           : " text-center "
                  //       } overflow-hidden  ${
                  //         onClick ? " cursor-pointer  " : ""
                  //       }`}
                  //     >
                  //       <div className={``}>{currencyFormat(val) }</div>
                  //     </td>
                  //   );
                  return (
                    <td
                      // onClick={onClick ? () => onClick!(item.data) : undefined}
                      colSpan={headers[j].colspan || 1}
                      key={j}
                      className={`p-1 border sticky-field max-w-xs     ${
                        headers[j].type == FieldType.currency
                          ? " text-right "
                          : " text-center "
                      } overflow-hidden overflow-ellipsis  ${
                        onClick ? " cursor-pointer  " : ""
                      }`}
                    >
                      <div className="line-clamp-2 whitespace-pre">
                        {val ?? "--"}
                      </div>
                    </td>
                  );
                })}
                {(onEdit || onDelete) && (
                  <td>
                    <div className="flex justify-center gap-5">
                      <button
                        onClick={onEdit ? () => onEdit!(item.data) : undefined}
                        className="text-sm font-semibold text-blue-300 flex items-center gap-1 hover:text-blue-500"
                      >
                        <Icon path={mdiPencil} className="w-4 h-4"></Icon>
                        Edit
                      </button>
                      <button
                        onClick={
                          onClick ? () => onDelete!(item.data) : undefined
                        }
                        className="text-sm font-semibold text-red-300 flex items-center gap-1 hover:text-red-500"
                      >
                        {" "}
                        <Icon path={mdiDelete} className="w-4 h-4"></Icon>
                        Delete
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {page && count && (
        <Pagination
          page={page}
          total={total || 1}
          count={count}
          itemCount={itemCount || 1}
          onChange={onPageChange ? onPageChange : (v) => {}}
        />
      )}
    </section>
  );
};

export default TableView;
